import {useMemo} from "../../../../../_snowpack/pkg/react.js";
import {useLocation} from "../../../../../_snowpack/pkg/react-router-dom.js";
import Haikunator from "../../../../../_snowpack/pkg/haikunator.js";
const haikunator = new Haikunator();
const getDate = () => new Date().getTime();
const getRun = () => [haikunator.haikunate(), getDate()].join("-");
export default getRun;
export const useParams = () => {
  const location = useLocation();
  const params = useMemo(() => {
    const search = location.search.slice(1);
    if (!search) {
      return {};
    }
    return search.split("&").map((pair) => pair.split("=")).reduce((obj, [key, val]) => ({
      ...obj,
      [key]: decodeURIComponent(val)
    }), {}) || {};
  }, [location.search]);
  return params;
};
const getURLForRoute = (gizmo, route) => {
  return [...gizmo.url.split("/").slice(0, -1), route].join("/");
};
export const getPathForRoute = (gizmo, route, queryParams) => {
  const path = getURLForRoute(gizmo, route);
  if (!path) {
    throw new Error(`Could not get path for ${gizmo.title} and route ${route}`);
  }
  if (queryParams && Object.values(queryParams).length) {
    return {
      ...queryParams,
      path
    };
  }
  return {
    path
  };
};
