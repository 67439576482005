import {buildQueryString} from "./buildQueryString.js";
import {prepareFile} from "./prepareFile.js";
export const uploadOrEditFile = async (request, file, {
  key,
  bucket,
  getContent
}, {attributes} = {}, requestParams = {}) => {
  const formData = new FormData();
  if (file) {
    formData.append("file", file);
  }
  const url = `files/${key}?${buildQueryString({
    bucket,
    attributes: attributes ? JSON.stringify(attributes) : void 0
  })}`;
  const resp = await request({
    url,
    params: {
      method: "POST",
      body: formData
    }
  }, requestParams);
  try {
    const response = await resp.text();
    const data = JSON.parse(response);
    return prepareFile(data, getContent);
  } catch (err) {
    console.error("Error parsing JSON response from server", err);
    throw err;
  }
};
