import React from "../../../../_snowpack/pkg/react.js";
import styled from "../../../../_snowpack/pkg/@emotion/styled.js";
const TOOLTIP_BG_COLOR = "rgb(69, 73, 75)";
const TOOLTIP_ARROW_SIZE = 12;
const Container = styled.div`
  label: Tooltip;
  position: absolute;
  color: ${({color}) => color};
  background: ${({bgColor}) => bgColor};
  padding: 15px;
  min-width: 120px;
  z-index: 2;
  border-radius: 8px;
  box-shadow: 0 2px 2px rgba(0,0,0,0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  // left: -50%;
  ${({xDir}) => {
  if (xDir === "right") {
    return `
        right: 0;
      `;
  }
  return ``;
}}
  ${({yDir}) => {
  if (yDir === "top") {
    return `
        top: 0;
        margin-top: calc(-100% + 20px);
      `;
  }
  return `
      top: 100%;
    `;
}}

  &:before {
    content: "";
    position: absolute;
    width: 0; 
    height: 0; 
    border-left:   ${TOOLTIP_ARROW_SIZE}px solid transparent;
    border-right:  ${TOOLTIP_ARROW_SIZE}px solid transparent;
    ${({xDir}) => {
  if (xDir === "left") {
    return `
          left: ${1 * TOOLTIP_ARROW_SIZE}px;
        `;
  }
  return `
        right: ${1 * TOOLTIP_ARROW_SIZE}px;
      `;
}}
    ${({yDir, bgColor}) => {
  if (yDir === "bottom") {
    return `
        top: ${-1 * TOOLTIP_ARROW_SIZE}px;
        border-bottom: ${TOOLTIP_ARROW_SIZE}px solid ${bgColor};
      `;
  }
  return `
        bottom: ${-1 * TOOLTIP_ARROW_SIZE}px;
        border-top: ${TOOLTIP_ARROW_SIZE}px solid ${bgColor};
      `;
}}
  }
`;
export const Tooltip = ({
  children,
  xDir = "left",
  yDir = "bottom",
  color = "white",
  bgColor = TOOLTIP_BG_COLOR
}) => /* @__PURE__ */ React.createElement(Container, {
  xDir,
  yDir,
  color,
  bgColor
}, children);
