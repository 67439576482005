import React, {useMemo} from "../../../../../../../_snowpack/pkg/react.js";
import styled from "../../../../../../../_snowpack/pkg/@emotion/styled.js";
import {Loading} from "../../../../../../../dist/lib/index.js";
import {MLTable} from "./MLTable.js";
import {getFnsForTab, useMLSelector, useTableData} from "./useMLData.js";
import {MLModal} from "./MLModal.js";
const MLTableContainer = styled.div`
  label: MLTableContainer;
  display: flex;
  flex: 1;
  height: 100%;
  overflow: scroll;
`;
export const MLCell = ({tab, grouping, fetchMLData, mlData}) => {
  const fns = useMemo(() => getFnsForTab(tab), [tab]);
  const keys = useMemo(() => {
    if (grouping) {
      return fns.reduce((arr, {fnKey, args}) => {
        const _args = args(grouping);
        return arr.concat(_args.map((args2) => ({
          fnKey,
          args: args2
        })));
      }, []);
    }
    ;
    return [];
  }, [fns, grouping]);
  const data = useMLSelector(fetchMLData, mlData, keys);
  const tableData = useTableData(data);
  if (!tableData) {
    return /* @__PURE__ */ React.createElement(Loading, {
      flex: true,
      label: "Loading ML Suggestion",
      size: 16
    });
  }
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(MLTableContainer, null, /* @__PURE__ */ React.createElement(MLTable, {
    data: tableData?.data
  })), /* @__PURE__ */ React.createElement(MLModal, {
    tab,
    id: grouping.index,
    data,
    properties: grouping.properties
  }));
};
