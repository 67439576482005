import React, {useCallback, useMemo} from "../../../../_snowpack/pkg/react.js";
import styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import {VirtualizedGrid, Measure} from "../../../../dist/lib/index.js";
const Container = styled.div`
  label: InvocationPropertiesTable;
  flex: 1;
  display: flex;
  flex-direction: column;
  transition-duration: .5s;
  padding: 10px 0 0px 0;
  color: #333;
`;
const getColumnWidth = ({width}, rect) => {
  if (typeof width === "function") {
    return width(rect);
  }
  return width || 240;
};
export function InvocationPropertiesTable({
  stretch,
  data,
  isCompleted,
  columns,
  rowHeight = 150,
  children,
  itemData
}) {
  return /* @__PURE__ */ React.createElement(Container, null, /* @__PURE__ */ React.createElement(Measure, null, ({rect}) => {
    return /* @__PURE__ */ React.createElement(GridContainer, {
      itemData,
      data,
      isCompleted,
      columns,
      rowHeight,
      children,
      rect,
      stretch
    });
  }));
}
;
function GridContainer({
  data,
  isCompleted,
  columns,
  rowHeight,
  children,
  rect,
  stretch,
  itemData
}) {
  const width = rect.width;
  const totalColumnWidth = useMemo(() => {
    return columns.reduce((sum, column) => {
      return sum + getColumnWidth(column, {width: 1});
    }, 0);
  }, [columns]);
  const stretchColumnsBy = useMemo(() => {
    if (stretch && width > totalColumnWidth) {
      return width / totalColumnWidth;
    }
    return 1;
  }, [stretch, width, totalColumnWidth]);
  const columnWidth = useCallback((index) => {
    return getColumnWidth(columns[index], rect) * stretchColumnsBy;
  }, [getColumnWidth, columns, stretchColumnsBy]);
  return /* @__PURE__ */ React.createElement(VirtualizedGrid, {
    columns: columns.map((col) => col.label),
    columnWidth,
    rowHeight,
    columnCount: columns.length,
    rowCount: data.length,
    width: rect.width,
    height: rect.height,
    itemData: {
      data,
      columns,
      isCompleted,
      itemData
    }
  }, children);
}
;
