import React, {useCallback, useMemo} from "../../../../../_snowpack/pkg/react.js";
import styled from "../../../../../_snowpack/pkg/@emotion/styled.js";
import {DEFAULT_PEBBLE_OPTIONS} from "../availablePebbleHazards.js";
const Container = styled.details`
  label: UploadHazardsOptionsContainer;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  summary {
    color: rgba(0,0,0,0.6);
  }
`;
const OptionsContainer = styled.div`
  label: OptionsContainer;
  display: flex;
  flex-direction: column;
  padding-top: 20px;

`;
const CheckboxLabel = styled.label`
  label: CheckboxLabel;
  color: ${({light}) => light ? "rgba(0,0,0,0.4)" : "rgba(0,0,0,0.8)"};
  highlight: none;
  &:hover {
    color: rgba(0,0,0,0.9);
  }
  cursor: pointer;
  padding-left: 6px;
  font-size: 14px;
   user-select: none;
`;
const CheckboxInput = styled.div`
  label: CheckboxInput;
  display: flex;
  margin-bottom: 6px;
  align-items: center;

  input[type=checkbox] {
    margin: 0;
  }
`;
const FloatedCheckboxes = styled.div`
  label: FloatedCheckboxes;
  border-top: 1px solid rgba(0,0,0,0.1);
  margin-top: 5px;
  padding-top: 5px;

  > * {
    float: left;
    margin-right: 20px;
  }
`;
const sortedPebbles = Object.entries(DEFAULT_PEBBLE_OPTIONS).sort(([a], [b]) => {
  return a.localeCompare(b);
}).map(([key, val]) => ({
  ...val,
  key
}));
const UploadHazardsOptions = ({
  pebbles,
  setPebble
}) => {
  const allSelected = useMemo(() => {
    return sortedPebbles.filter((p) => pebbles.includes(p.key)).length === sortedPebbles.length;
  }, [pebbles]);
  const handleAllSelected = useCallback(() => {
    sortedPebbles.forEach((pebble) => {
      setPebble(Object.keys(DEFAULT_PEBBLE_OPTIONS), !allSelected);
    });
  }, [pebbles, setPebble]);
  return /* @__PURE__ */ React.createElement(Container, null, /* @__PURE__ */ React.createElement("summary", null, "Choose Hazard Automations to run"), /* @__PURE__ */ React.createElement(OptionsContainer, null, /* @__PURE__ */ React.createElement(CheckboxInput, null, /* @__PURE__ */ React.createElement("input", {
    type: "checkbox",
    id: "select-all",
    checked: allSelected,
    onChange: handleAllSelected
  }), /* @__PURE__ */ React.createElement(CheckboxLabel, {
    light: true,
    htmlFor: "select-all"
  }, "Select All")), /* @__PURE__ */ React.createElement(FloatedCheckboxes, null, sortedPebbles.map(({key, label}) => {
    const checked = pebbles.includes(key);
    const id = `hazard-${key}`;
    return /* @__PURE__ */ React.createElement(CheckboxInput, {
      key
    }, /* @__PURE__ */ React.createElement("input", {
      type: "checkbox",
      id,
      checked,
      onChange: () => setPebble(key, !checked)
    }), /* @__PURE__ */ React.createElement(CheckboxLabel, {
      light: true,
      htmlFor: id
    }, label, " "));
  }))));
};
export default UploadHazardsOptions;
