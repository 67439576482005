import React from "../../../../_snowpack/pkg/react.js";
import styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import {
  BLUE
} from "../../constants.js";
import {useMemo} from "../../../../_snowpack/pkg/react.js";
const TableRowContainer = styled.div`
  label: TableRow;
  position: relative;
  display: flex;
  border-bottom: ${({last}) => last ? 1 : 1}px solid rgba(0,0,0,0.12);
  height: 100%;

  ${({index}) => index % 2 !== 0 ? `
    background-color: rgba(0,0,0,0.03);
  ` : ""}

  &:hover {
    background-color: ${BLUE.alpha(0.1).toString()};
  }

`;
export const Cell = styled.div`
  label: Cell;
  ${({width}) => width ? `width: ${width}px;` : "flex: 1;"}
  padding: 0 ${({applyPadding}) => applyPadding ? 5 : 0}px;
  display: flex;
  align-items: center;
  ${({applyPadding}) => applyPadding ? `
    &:first-of-type {
      padding-left: 20px;
    }
  ` : ""}
`;
const isRowFn = (row) => {
  return typeof row === "function";
};
const getRow = (data, index) => {
  const row = data[index];
  if (isRowFn(row)) {
    return row();
  }
  return row;
};
function TableRow({
  index,
  data: {
    widths,
    data,
    children,
    applyPadding,
    trStyle
  }
}) {
  const row = useMemo(() => getRow(data, index), [data, index]);
  return /* @__PURE__ */ React.createElement(TableRowContainer, {
    style: trStyle,
    index,
    last: index === data.length - 1
  }, row.map((datum, index2) => {
    const width = widths?.[index2];
    if (children) {
      return children({
        datum,
        index: index2,
        width: width || null
      });
    }
    return /* @__PURE__ */ React.createElement(Cell, {
      key: index2,
      width,
      applyPadding
    }, datum);
  }));
}
;
export default TableRow;
