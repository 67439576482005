import React from "../../../../../../_snowpack/pkg/react.js";
import {useStory, Modal} from "../../../../../../dist/lib/index.js";
import styled from "../../../../../../_snowpack/pkg/@emotion/styled.js";
import {AiFillEye} from "../../../../../../_snowpack/pkg/react-icons/ai.js";
import {BLUE} from "../../../../../../dist/lib/constants.js";
const Clicker = styled.div`
  label: Clicker;
  display: flex;
  flex: 1;
  cursor: pointer;
  position: absolute;
  justify-content: center;
  align-items: center;
  z-index: 1;
  bottom: 0;
  right: 0;
  opacity: 0.3;

  &:hover {
    opacity: 1;
  }
`;
const ReviewButton = styled.div`
  label: ReviewButton;
  padding: 10px;
  cursor: pointer;
  display: flex;
  border-radius: 5px;

  &:hover {
    background-color: ${BLUE.alpha(0.4).toString()};
  }
`;
export const MappingReviewModal = ({
  id,
  children
}) => {
  const {params} = useStory();
  return /* @__PURE__ */ React.createElement(Modal, {
    modalKey: `mapping-review-${id}`,
    params,
    modalLabel: ({openModal}) => /* @__PURE__ */ React.createElement(Clicker, null, /* @__PURE__ */ React.createElement(ReviewButton, {
      onClick: openModal
    }, /* @__PURE__ */ React.createElement(AiFillEye, null)))
  }, children);
};
