import {useCallback} from "../../../_snowpack/pkg/react.js";
import {useBackend} from "./internals/useBackend.js";
import {prepareInvocation} from "./internals/prepareInvocation.js";
import {useUpdateAttributes} from "./internals/updateAttributes.js";
import {useMemo} from "../../../_snowpack/pkg/react.js";
function getNamespace(gizmo) {
  if (typeof gizmo === "string") {
    return gizmo;
  }
  if (gizmo) {
    return gizmo.namespace;
  }
  return void 0;
}
function getInvocationName(name, gizmo) {
  if (!name && typeof gizmo !== "string" && gizmo) {
    return gizmo.key;
  }
  return name;
}
export function useInvocation(gizmo, name) {
  const [request] = useBackend();
  const namespace = useMemo(() => getNamespace(gizmo), [gizmo]);
  const [getUpdateAttributes] = useUpdateAttributes(namespace, request);
  const getInvocation = useCallback(async (_name, requestParams = {}) => {
    if (!namespace) {
      throw new Error("gizmo namespace cannot be undefined.");
    }
    const resp = await request({
      url: `gizmos/${namespace}/invocations/${getInvocationName(_name || name, gizmo)}`
    }, requestParams);
    const data = await resp.json();
    try {
      return prepareInvocation(data, getUpdateAttributes);
    } catch (err) {
      console.error("Error preparing invocation", err);
      throw err;
    }
  }, [gizmo, name]);
  return [getInvocation];
}
