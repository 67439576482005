import styled from "../../../../../../_snowpack/pkg/@emotion/styled.js";
import {constants} from "../../../../../../dist/lib/index.js";
const {
  BLUE
} = constants;
export const LinkDownload = styled.a`
  label: LinkDownload;
  color: ${BLUE.toString()};
  text-decoration: underline;
  height: 24px;

  &:hover {
    color: black;
  }
`;
