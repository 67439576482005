import loadable from "../../../../_snowpack/pkg/@loadable/component.js";
const UploadVerifySOV = loadable(() => import("./UploadVerifySOV/UploadVerifySOV.js"), {
  resolveComponent: (components) => components.UploadVerifySOV
});
const VerifySOVs = loadable(() => import("./VerifySOVs/VerifySOVs.js"), {
  resolveComponent: (components) => components.VerifySOVs
});
const ExportDataFiles = loadable(() => import("./ExportDataFiles/ExportDataFiles.js"), {
  resolveComponent: (components) => components.ExportDataFiles
});
export default {
  title: "Verify",
  stories: [
    {
      title: "Upload Job",
      content: UploadVerifySOV
    },
    {
      title: "Mapping Review",
      content: VerifySOVs
    },
    {
      title: "Export Data Files",
      content: ExportDataFiles
    }
  ]
};
