import React, {useCallback, useState} from "../../../../_snowpack/pkg/react.js";
import {Cell} from "./DistanceToNearestBuildingCell.js";
import SOVPropertiesTable from "../../../components/SOVPropertiesTable/SOVPropertiesTable.js";
import useData from "./useData.js";
const STATIC_WIDTHS = {
  index: 50
};
const DYNAMIC_WIDTHS = {
  id: 1,
  streetAddress: 1,
  city: 1,
  state: 1,
  zip: 1,
  country: 1,
  distanceToNearestBuilding: 0.5,
  map: 3
};
const totalStaticWidth = Object.values(STATIC_WIDTHS).reduce((sum, width) => sum + width, 0);
const totalDynamicWidth = Object.values(DYNAMIC_WIDTHS).reduce((sum, width) => sum + width, 0);
const MAP_EXPANSION_SCALE = 2.2;
const ROW_HEIGHT = 150;
const columns = [{
  label: "#",
  key: "index"
}, {
  key: "id",
  label: "ID"
}, {
  key: "streetAddress",
  label: "Street"
}, {
  key: "city",
  label: "City"
}, {
  key: "state",
  label: "State"
}, {
  key: "zip",
  label: "Zip"
}, {
  key: "country",
  label: "Country"
}, {
  label: "Distance to nearest building",
  key: "distanceToNearestBuilding"
}, {
  label: "Centroid",
  key: "map"
}].map((col) => {
  if (col.key in STATIC_WIDTHS) {
    return {
      ...col,
      width: STATIC_WIDTHS[col.key]
    };
  }
  if (col.key in DYNAMIC_WIDTHS) {
    return {
      ...col,
      width: (rect) => (rect.width - totalStaticWidth) * DYNAMIC_WIDTHS[col.key] / totalDynamicWidth
    };
  }
  throw new Error(`No width defined for column ${col.key}. Define an explicit width in STATIC_WIDTHS or DYNAMIC_WIDTHS.`);
});
export const DistanceToNearestBuildingPage = ({invocation}) => {
  const {
    propertiesJSONURL
  } = invocation.attributes;
  const folder = propertiesJSONURL.split("/").slice(0, -1).join("/");
  const editableKey = `${folder}/editable.json`;
  const {data, setData} = useData(propertiesJSONURL, editableKey);
  const handleChangedPin = (rowIndex, start, end, distance) => {
    let dtnb = {
      latitudeRulerStart: start.lat,
      longitudeRulerStart: start.lng,
      latitudeRulerEnd: end.lat,
      longitudeRulerEnd: end.lng,
      distanceToNearestBuilding: distance
    };
    setData(rowIndex, dtnb);
  };
  const getDownloadData = useCallback((data2) => {
    const total = data2?.length || 0;
    const downloadCols = columns.filter(({key}) => key !== "map");
    return [
      downloadCols.map(({label}) => label),
      ...Array(total).fill("").map((_, i) => {
        const row = data2[i];
        if (!row) {
          return downloadCols.map(() => "---still processing---");
        }
        return downloadCols.map(({key}) => {
          if (key === "id") {
            return row.archipelagoId || row.locationId;
          }
          if (["latitude", "longitude"].includes(key)) {
            return parseFloat(row[key]);
          }
          if (key === "distanceToNearestBuilding") {
            return row.userEdited?.distanceToNearestBuilding;
          }
          return row[key];
        });
      })
    ];
  }, [data, columns]);
  const [expandedRow, setExpandedRow] = useState();
  const getRowHeight = useCallback((index) => {
    if (index === expandedRow) {
      return ROW_HEIGHT * MAP_EXPANSION_SCALE;
    }
    return ROW_HEIGHT;
  }, [expandedRow]);
  return /* @__PURE__ */ React.createElement(SOVPropertiesTable, {
    invocation,
    data: data || null,
    columns,
    parseRows: (rows) => rows,
    getDownloadData,
    rowHeight: getRowHeight,
    itemData: {
      setExpandedRow,
      expandedRow,
      handleChangedPin
    }
  }, Cell);
};
