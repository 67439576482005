import {useInvocation, usePoll} from "../../../../dist/lib/index.js";
export function useSOVAppInvocation(namespace, id) {
  const [getInvocation] = useInvocation(namespace, id);
  const [invocation] = usePoll(function pollingForGetInvocation(props) {
    return getInvocation(id, props);
  }, [namespace, id, getInvocation], {
    until: (i) => i?.status && ["completed", "error", "aborted"].includes(i?.status)
  });
  return [invocation];
}
