import React, {useMemo} from "../../../../../_snowpack/pkg/react.js";
import styled from "../../../../../_snowpack/pkg/@emotion/styled.js";
import {Ellipsis, Measure, Table} from "../../../../../dist/lib/index.js";
import {UploadSOVButton} from "../UploadSOVButton.js";
const EmptyContainer = styled.div`
  label: EmptyContainer;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
export function SOVAppInvocationsTableWrapper({
  columns = [],
  rows,
  allRows,
  routes,
  useExactColumns,
  getWidth = () => 2,
  rowSize = 45
}) {
  if (allRows === 0) {
    return /* @__PURE__ */ React.createElement(EmptyContainer, null, /* @__PURE__ */ React.createElement("h2", null, "No runs found."), /* @__PURE__ */ React.createElement(UploadSOVButton, {
      routes
    }, "You can upload one!"));
  }
  if (rows.length === 0) {
    return /* @__PURE__ */ React.createElement(EmptyContainer, null, /* @__PURE__ */ React.createElement("h2", null, "All runs are filtered out."), /* @__PURE__ */ React.createElement("p", null, "Try relaxing your filters."));
  }
  const head = useMemo(() => (useExactColumns ? [
    ...columns.map((column) => column.label)
  ] : [
    "File",
    "Status",
    ...columns.map((column) => column.label),
    "Started",
    "Duration",
    "Creator"
  ].filter(Boolean)).map((col) => /* @__PURE__ */ React.createElement(Ellipsis, {
    title: col
  }, col)), [useExactColumns, columns]);
  return /* @__PURE__ */ React.createElement(Measure, null, ({rect}) => {
    const widths = getWidths(useExactColumns ? [
      ...columns.map((col, i) => {
        const width = getWidth(col, i, rect.width);
        if (width === void 0) {
          throw new Error(`Undefined width for column ${col.label}`);
        }
        return width;
      })
    ] : [
      3,
      2,
      ...columns.map(() => 2),
      2,
      2,
      2
    ].filter(Boolean), rect.width);
    return /* @__PURE__ */ React.createElement(Table, {
      rows: rows.filter(Boolean),
      rowSize,
      widths,
      head
    });
  });
}
;
const getWidths = (widths, width) => {
  const total = widths.reduce((sum, width2) => sum + width2, 0);
  return widths.map((w) => w / total * width);
};
