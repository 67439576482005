import {useCallback, useEffect, useMemo, useState} from "../../../../../../../_snowpack/pkg/react.js";
import {
  useUser,
  useDownloadFile,
  useS3
} from "../../../../../../../dist/lib/index.js";
import {useGroupedOutputs} from "./useGroupedOutputs.js";
const wait = (dur) => new Promise((resolve) => setTimeout(resolve, dur));
function useActionWithHelpers(action, deps) {
  const [loading, setLoading] = useState(false);
  const doAction = useCallback(() => {
    (async () => {
      const start = new Date().getTime();
      setLoading(true);
      action();
      const duration = new Date().getTime() - start;
      const waitTime = 500;
      if (duration < waitTime) {
        await wait(waitTime - duration);
      }
      setLoading(false);
    })();
  }, deps);
  return {
    loading,
    action: doAction
  };
}
function useKey(path, folder) {
  const key = useMemo(() => {
    if (folder) {
      return [
        ...folder.split("/").filter(Boolean),
        ...path.split("/").filter(Boolean)
      ].join("/");
    }
    return void 0;
  }, [path, folder]);
  return key;
}
const useProperties = (key) => {
  const [properties, setProperties] = useState();
  const [getContent] = useS3(key);
  useEffect(() => {
    getContent().then(setProperties);
  }, [getContent]);
  return properties?.map((p, index) => ({
    ...p,
    index
  }));
};
export const useActions = ({
  invocation,
  params
}) => {
  const [user] = useUser();
  const tab = params.tab || "construction";
  const {name, folder, bucket} = invocation.attributes;
  const originalSheetKey = useKey("uploads/sheet.xlsx", folder);
  const targetOutput = useKey(`reviewed/output.json`, folder);
  const [getContent] = useS3(originalSheetKey);
  const [downloadFile] = useDownloadFile({getContent});
  const [completedAt, setCompletedAt] = useState(invocation.attributes.completedAt);
  const {action: downloadOriginal} = useActionWithHelpers(async () => {
    if (originalSheetKey) {
      downloadFile({name});
    }
  }, [originalSheetKey, downloadFile]);
  const outputFolder = useMemo(() => folder ? `${folder}outputs` : void 0, [folder]);
  const {downloadDataFile, data, updateData, updating} = useGroupedOutputs(bucket, outputFolder);
  const properties = useProperties(`${outputFolder}/df.json`);
  const submitMappings = useCallback(async () => {
    const _completedAt = new Date().getTime();
    await invocation.updateAttributes({
      status: "completed",
      completedAt: _completedAt,
      completedBy: user.name
    });
    setCompletedAt(_completedAt);
  }, [invocation]);
  const handleChange = useCallback((key, column, value) => {
    updateData(tab, key, column, value);
  }, [tab]);
  return {
    downloadOriginal,
    canSubmitChanges: !!targetOutput && !!data,
    outputFolder,
    tab,
    data,
    handleChange,
    updating,
    downloadDataFile,
    submitMappings,
    completedAt,
    properties
  };
};
