import {usePoll, useS3, useFile} from "../../../../dist/lib/index.js";
import {useCallback, useEffect, useRef, useState} from "../../../../_snowpack/pkg/react.js";
function _useEditableFile(key) {
  const [dataFileError, setDataFileError] = useState();
  const [file, setFile] = useState();
  const [getFile, editFile] = useFile(key);
  useEffect(() => {
    getFile().then(setFile).catch(setDataFileError);
  }, [key]);
  return [file, editFile, dataFileError];
}
export function useEditableFileForArrays(sourceKey, targetKey, {
  until,
  transformOriginalData,
  transformEditableData
}) {
  const mounted = useRef(true);
  const [saving, setSaving] = useState(false);
  const mostRecentSave = useRef();
  const [data, _setData] = useState();
  const [getOriginalDataFile] = useS3(sourceKey);
  const [editableDataFile, editFile, editFileError] = _useEditableFile(targetKey);
  useEffect(() => {
    if (editFileError) {
      console.log("editable file does not (yet) exist");
      _setData([]);
    }
  }, [editFileError]);
  useEffect(() => {
    if (editableDataFile) {
      editableDataFile.getContent().then((rows) => {
        if (mounted.current) {
          _setData((prev) => {
            if (prev === void 0) {
              return rows || [];
            }
            const excess = prev.length - rows.length;
            return [
              ...rows.map((row, i) => ({
                ...prev[i] || {},
                ...row
              })),
              ...prev.slice(excess + 1)
            ];
          });
        }
      });
    }
  }, [editableDataFile]);
  const [originalData] = usePoll(async () => {
    if (data !== void 0) {
      return await getOriginalDataFile();
    }
  }, [!!data, getOriginalDataFile], {
    until
  });
  useEffect(() => {
    if (originalData) {
      _setData((prev) => {
        const newData = originalData.filter((row) => row.completed || row.complete).map((row, index) => {
          if (prev[index] !== void 0) {
            return {
              ...row,
              ...prev[index]
            };
          }
          return transformOriginalData(row);
        });
        return newData;
      });
    }
  }, [originalData]);
  const saveUpdatedFile = useCallback(async (_data) => {
    if (!mounted.current) {
      return;
    }
    await editFile(_data);
    setSaving(false);
  }, [editFile]);
  const setData = useCallback((rowIndex, change) => {
    setSaving(true);
    _setData((prev) => {
      if (prev) {
        mostRecentSave.current = new Date();
        const _data = [...prev];
        _data[rowIndex] = transformEditableData(_data[rowIndex], change);
        saveUpdatedFile(_data);
        return _data;
      }
    });
  }, [saveUpdatedFile]);
  useEffect(() => {
    if (saving) {
      const beforeUnload = (e) => {
        e.returnValue = "onbeforeunload";
        return "onbeforeunload";
      };
      window.addEventListener("beforeunload", beforeUnload);
      return () => window.removeEventListener("beforeunload", beforeUnload);
    }
  }, [saving]);
  return [data, setData];
}
