import React, {useMemo} from "../../../../../../_snowpack/pkg/react.js";
import {Toggle, useToggleFns, Loading} from "../../../../../../dist/lib/index.js";
import styled from "../../../../../../_snowpack/pkg/@emotion/styled.js";
import {MappingReviewTable} from "./MappingReviewTable.js";
import {useGetColumns} from "./getColumns.js";
import {useMLData} from "./VerifyML/useMLData.js";
const Center = styled.div`
  label: Center;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
const Middle = styled.div`
  label: Middle;
  display: flex;
  margin: 10px 0;
`;
const SearchForDescriptionContainer = styled.div`
  label: SearchForDescriptionContainer;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;
const SearchForDescription = styled.input`
  label: SearchForDescription;
  padding: 5px 10px;
  min-width: 300px;
  height: 40px;
`;
const Right = styled.div`
  label: Right;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  margin-left: 20px;

`;
const ProgressContainer = styled.div`
  label: ProgressContainer;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  min-width: 60px;
  align-items: flex-end;
`;
const ToggleContainer = styled.div`
  label: ToggleContainer;
  margin-left: 20px;
`;
const getGroupedGroupings = (data, showCritical, properties) => {
  const version = data.version || "no-version-defined";
  const groupingsWithIndices = Object.entries(data.groupings).map(([key, grouping], index) => {
    let groupingProperties;
    if (version === "properties-population") {
      groupingProperties = grouping.indexOfProperties?.map((_propertyIndex) => {
        const propertyIndex = Number(_propertyIndex);
        if (properties !== void 0 && !properties[propertyIndex]) {
          console.error("property index", propertyIndex, "properties", properties);
          throw new Error(`Property index ${propertyIndex} was not found in properties array`);
        }
        return properties?.[propertyIndex];
      });
    }
    return [
      key,
      {
        ...grouping,
        version,
        properties: groupingProperties,
        index
      }
    ];
  });
  if (showCritical) {
    return groupingsWithIndices.filter(([_, value]) => value.isCritical);
  }
  return groupingsWithIndices;
};
const getGroupings = (data, showCritical, search, properties) => {
  const entries = getGroupedGroupings(data, showCritical, properties);
  if (search) {
    return [entries.filter(([key]) => {
      const label = key.split(data.delimiter).shift().toLowerCase();
      return label.includes(search.toLowerCase());
    }), entries.length];
  }
  return [entries, entries.length];
};
export const MappingReviewBody = ({
  attributeConfig,
  tab,
  params,
  update,
  data,
  handleChange,
  properties
}) => {
  const {value: showCritical, fn: toggleSetCritical} = useToggleFns({params, update, label: "showCritical", defaultValue: "off"});
  const {value: showConstructionMLColumns, fn: toggleSetConstructionMLColumns} = useToggleFns({params, update, label: "showConstructionMLColumns", defaultValue: "on"});
  const {value: showOccupancyMLColumns, fn: toggleSetOccupancyMLColumns} = useToggleFns({params, update, label: "showOccupancyMLColumns", defaultValue: "off"});
  const showMLColumns = useMemo(() => {
    if (tab === "occupancy") {
      return showOccupancyMLColumns;
    }
    return showConstructionMLColumns;
  }, [showOccupancyMLColumns, showConstructionMLColumns, tab]);
  const schemaForData = attributeConfig[tab];
  const [groupings, numberOfGroupings] = useMemo(() => data ? getGroupings(data, showCritical, params.search, properties) : [void 0, void 0], [showMLColumns, data, showCritical, params.search, properties]);
  const getColumns = useGetColumns();
  const [fetchMLData, mlData] = useMLData();
  const columns = useMemo(() => {
    if (data) {
      const delimiter = data?.delimiter;
      return getColumns({tab, delimiter, schemaForData, handleChange, showMLColumns, showCritical, fetchMLData, mlData});
    }
  }, [data, tab, schemaForData, getColumns, showMLColumns, showCritical, getColumns, fetchMLData, mlData]);
  if (data === null) {
    return /* @__PURE__ */ React.createElement(Center, null, /* @__PURE__ */ React.createElement("p", null, "No data for ", tab, "."));
  }
  if (data === void 0) {
    return /* @__PURE__ */ React.createElement(Loading, {
      label: "Loading data",
      flex: true
    });
  }
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Middle, null, /* @__PURE__ */ React.createElement(SearchForDescriptionContainer, null, /* @__PURE__ */ React.createElement(SearchForDescription, {
    defaultValue: params.search,
    onChange: (e) => {
      update({search: e.target.value});
    },
    type: "text",
    placeholder: "Search for description"
  })), /* @__PURE__ */ React.createElement(Right, null, /* @__PURE__ */ React.createElement(ToggleContainer, null, tab === "occupancy" ? /* @__PURE__ */ React.createElement(Toggle, {
    name: "show-occupancy-ml-columns",
    label: "Show Occupancy ML Columns",
    checked: showMLColumns,
    handleChange: toggleSetOccupancyMLColumns
  }) : /* @__PURE__ */ React.createElement(Toggle, {
    name: "show-construction-ml-columns",
    label: "Show Construction ML Columns",
    checked: showMLColumns,
    handleChange: toggleSetConstructionMLColumns
  })), /* @__PURE__ */ React.createElement(ToggleContainer, null, /* @__PURE__ */ React.createElement(Toggle, {
    name: "toggle",
    label: "Show Critical Only",
    checked: showCritical,
    handleChange: toggleSetCritical
  })), /* @__PURE__ */ React.createElement(ProgressContainer, null, /* @__PURE__ */ React.createElement("label", null, numberOfGroupings, " rows")))), /* @__PURE__ */ React.createElement(MappingReviewTable, {
    mlData,
    columns,
    groupings,
    search: params.search
  }));
};
