import React, {useRef, useState, useEffect, useCallback} from "../../../_snowpack/pkg/react.js";
import styled from "../../../_snowpack/pkg/@emotion/styled.js";
import {useMemo} from "../../../_snowpack/pkg/react.js";
const MeasuringContainer = styled.div`
  label: MeasuringContainer;
  flex: 1;
  display: flex;
  max-width: 100%;
`;
const makeLog = (debug, label) => {
  if (debug && !label) {
    console.warn('Consider passing a "label" attribute to <Measure />. This will make it easier to identify which one is logging.');
  }
  return (...msg) => {
    if (debug) {
      console.log(label, ...msg);
    }
  };
};
export const Measure = ({
  label,
  children,
  debug = false
}) => {
  const ref = useRef(null);
  const [rect, setRect] = useState();
  const log = useMemo(() => makeLog(debug, label), [label, debug]);
  const resizeHandler = useCallback(() => {
    log("resize handler called");
    if (ref.current) {
      const rect2 = ref.current.getBoundingClientRect();
      log("resize handler! set rect", rect2, "width", rect2.width, "height", rect2.height);
      setRect(rect2);
    }
  }, [ref, log]);
  const resizeObserver = useRef();
  useEffect(() => {
    log("use effect", resizeObserver.current);
    if (ref.current && !resizeObserver.current) {
      log("ref current now exists, and resize observer does not", resizeObserver.current);
      resizeObserver.current = new window.ResizeObserver(() => {
        log("created a resize handler", resizeObserver.current);
        resizeHandler();
      });
      setRect(ref.current.getBoundingClientRect());
      log("observe the ref");
      resizeObserver.current.observe(ref.current);
      window.addEventListener("resize", resizeHandler);
      return () => {
        log("measure is going away");
        window.removeEventListener("resize", () => {
          log("remove event listerned");
          resizeHandler();
        });
        if (ref.current && resizeObserver.current) {
          resizeObserver.current.unobserve(ref.current);
          resizeObserver.current = void 0;
        }
      };
    }
  }, [ref, resizeObserver]);
  return /* @__PURE__ */ React.createElement(MeasuringContainer, {
    ref
  }, rect && children({rect}));
};
