import {usePebble, Button, Upload} from "../../../../../dist/lib/index.js";
import React, {useCallback, useState} from "../../../../../_snowpack/pkg/react.js";
import styled from "../../../../../_snowpack/pkg/@emotion/styled.js";
import {Spinner} from "../../../../../_snowpack/pkg/@chakra-ui/react.js";
import {MdCloudUpload} from "../../../../../_snowpack/pkg/react-icons/md.js";
import {useHistory, useRouteMatch} from "../../../../../_snowpack/pkg/react-router-dom.js";
import {NAMESPACE, ROUTES} from "../config.js";
import {useUploadFile} from "../../../../components/SOVAppInvocations/SOVAppInvocationUpload/useUploadFile.js";
const SubmitButtonContainer = styled.div`
  label: SubmitButtonContainer;
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
`;
const Container = styled.div`
  label: UploadContainer;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
`;
const UploadContainer = styled.div`
  label: UploadContainer;
  display: flex;
  flex: 1;
`;
const Header = styled.div`
  label: Header;
  margin-bottom: 20px;
`;
const FormInput = styled.div`
  label: FormInput;
  display: flex;
  margin-bottom: 10px;
  align-items: center;

  label {
    width: 120px;
  }

  input {
    padding: 5px 10px;
    min-width: 250px;
  }
`;
const useGoToEditor = () => {
  const history = useHistory();
  const {url} = useRouteMatch();
  const goToEditor = useCallback(({key}) => {
    const redirectURL = `/${url.split("/").filter(Boolean).slice(0, -1).join("/")}/${ROUTES.editor}/${key}`;
    history.push(redirectURL);
  }, [url, history]);
  return [goToEditor];
};
const useUploadIngestSOV = () => {
  const [
    uploadFile,
    uploadsFolder
  ] = useUploadFile(NAMESPACE);
  const [processSOV] = usePebble("do-verification/process", NAMESPACE);
  const [goToEditor] = useGoToEditor();
  const [submitting, setSubmitting] = useState(false);
  const uploadSpreadsheet = useCallback(async (file, industry, businessType, client) => {
    setSubmitting(true);
    const folder = uploadsFolder.split("uploads")[0];
    try {
      const {key, bucket} = await uploadFile(file, "sheet.xlsx");
      const result = await processSOV({
        bucket,
        input_key: key,
        output_folder: `${folder}outputs`,
        industry,
        business_type: businessType,
        client
      }, {
        persist: true,
        attributes: {
          bucket,
          name: file.name,
          folder,
          industry,
          business_type: businessType,
          client
        }
      });
      goToEditor(result);
    } catch (err) {
      console.error(err);
    }
    setSubmitting(false);
  }, [processSOV, goToEditor, uploadFile, uploadsFolder]);
  return {
    uploadSpreadsheet,
    submitting
  };
};
export const UploadVerifySOV = ({
  params,
  update
}) => {
  const [file, setFile] = useState();
  const {uploadSpreadsheet, submitting} = useUploadIngestSOV();
  return /* @__PURE__ */ React.createElement(Container, null, /* @__PURE__ */ React.createElement(Header, null, /* @__PURE__ */ React.createElement("h1", null, "I&T Mapping Review - v1"), /* @__PURE__ */ React.createElement("p", null, "Use this tool to do an extensive review of the Construction and Occupancy mappings."), [{key: "client", label: "Client"}, {key: "industry", label: "Industry"}, {key: "businessType", label: "Business Type"}].map(({label, key}) => /* @__PURE__ */ React.createElement(FormInput, {
    key
  }, /* @__PURE__ */ React.createElement("label", null, label), /* @__PURE__ */ React.createElement("input", {
    type: "text",
    onChange: (e) => update({[key]: e.target.value}),
    defaultValue: params[key] || ""
  })))), /* @__PURE__ */ React.createElement(UploadContainer, null, /* @__PURE__ */ React.createElement(Upload, {
    upload: (files) => setFile(files[0])
  }, file ? /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("h3", null, "Uploaded File:"), /* @__PURE__ */ React.createElement("h1", null, file.name), /* @__PURE__ */ React.createElement("p", null, "You can replace this file by dragging and dropping a new file")) : /* @__PURE__ */ React.createElement("h1", null, "Upload File"))), /* @__PURE__ */ React.createElement(SubmitButtonContainer, null, /* @__PURE__ */ React.createElement(Button, {
    isLoading: submitting,
    disabled: !file || !params.industry || !params.businessType || !params.client || submitting,
    onClick: () => uploadSpreadsheet(file, params.industry, params.businessType, params.client),
    leftIcon: /* @__PURE__ */ React.createElement(MdCloudUpload, null),
    spinner: /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Spinner, {
      mr: 4
    }), " Uploading")
  }, "Submit File")));
};
