class CoordMapType {
  constructor(tileSize) {
    this.maxZoom = 40;
    this.name = "Tile #s";
    this.alt = "Tile Coordinate Map Type";
    this.tileSize = tileSize;
  }
  getTile(coord, zoom, ownerDocument) {
    const div = ownerDocument.createElement("div");
    div.style.width = this.tileSize.width + "px";
    div.style.height = this.tileSize.height + "px";
    div.style.borderStyle = "solid";
    div.style.borderWidth = "0px";
    div.style.backgroundColor = "#FFFFFF";
    return div;
  }
  releaseTile(tile) {
  }
}
export default CoordMapType;
