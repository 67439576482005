import React, {useCallback, useMemo, useState} from "../../../../../_snowpack/pkg/react.js";
import {BiDownload} from "../../../../../_snowpack/pkg/react-icons/bi.js";
import styled from "../../../../../_snowpack/pkg/@emotion/styled.js";
import {useDownloadFile, usePebble, Button, useStory} from "../../../../../dist/lib/index.js";
import {Highlight} from "../../../../components/SOVAppInvocations/SOVAppInvocationsTable/SOVAppInvocationsTableWrapper/Highlight.js";
import {VerifyUploadsTable} from "../components/VerifyUploadsTable/VerifyUploadsTable.js";
import "../../../../../_snowpack/pkg/react-date-range/dist/styles.css";
import "../../../../../_snowpack/pkg/react-date-range/dist/theme/default.css";
import {NAMESPACE} from "../config.js";
const VerifyUploadsTableContainer = styled.div`
  label: VerifyUploadsTableContainer;
  display: flex;
  flex-direction: column;
  flex: 1;
`;
const CheckboxContainer = styled.label`
  label: CheckboxContainer;
  height: 54px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const Checkbox = styled.input`
  label: Checkbox;
  cursor: pointer;
  transform: scale(1.5);
`;
const Client = styled.label`
  label: Client;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const without = (obj, key) => Object.entries(obj).reduce((nextObj, [_key, val]) => {
  if (_key === key) {
    return nextObj;
  }
  return {
    ...nextObj,
    [_key]: val
  };
}, {});
const getNextSelected = (selected, keys, checked) => {
  return keys.reduce((nextSelected, key) => {
    if (checked === true) {
      return {
        ...nextSelected,
        [key]: true
      };
    } else if (checked === false) {
      return without(nextSelected, key);
    }
    if (nextSelected[key]) {
      return without(nextSelected, key);
    }
    return {
      ...nextSelected,
      [key]: true
    };
  }, selected);
};
export const ExportDataFiles = () => {
  const {params, update} = useStory();
  const [errors, setErrors] = useState();
  const [filteredInvocationKeys, setFilteredInvocationKeys] = useState();
  const [invocations, setInvocations] = useState();
  const selected = useMemo(() => {
    try {
      return params.selected.split(",").filter(Boolean).reduce((obj, key) => ({...obj, [key]: true}), {});
    } catch (err) {
      return {};
    }
  }, [params.selected]);
  const allAreSelected = useMemo(() => {
    if (filteredInvocationKeys) {
      const _allAreSelected = filteredInvocationKeys.reduce((_allAreSelected2, key) => {
        if (_allAreSelected2 === false) {
          return false;
        }
        return !!selected[key];
      }, true);
      return _allAreSelected;
    }
  }, [selected, filteredInvocationKeys]);
  const select = useCallback((invocations2, checked) => {
    const nextSelected = Object.keys(getNextSelected(selected, invocations2, checked)).join(",");
    update({
      selected: nextSelected
    });
  }, [selected, update]);
  const selectAll = useCallback(() => {
    if (filteredInvocationKeys) {
      select(filteredInvocationKeys, !allAreSelected);
    }
  }, [allAreSelected, select, filteredInvocationKeys]);
  const getFilteredInvocations = useCallback((invocations2) => setFilteredInvocationKeys(invocations2.map((i) => i.key)), []);
  const [generateZippedDataLink] = usePebble("do-verification/download_all");
  const [downloadDataFile] = useDownloadFile();
  const numberOfValidInvocations = useMemo(() => invocations?.filter((i) => selected[i.key]) || [], [selected, invocations]);
  const downloadAll = useCallback(async () => {
    setErrors(void 0);
    if (numberOfValidInvocations) {
      const output = `alpha/${NAMESPACE}/data-exports/combined-data-export-${new Date().getTime()}.xlsx`;
      const bucket = numberOfValidInvocations[0].attributes.bucket;
      const {result} = await generateZippedDataLink({
        bucket,
        output_folders: numberOfValidInvocations.map((invocation) => invocation.attributes.folder),
        clients: numberOfValidInvocations.map(({attributes: {client, name, folder}}) => client),
        output
      });
      console.log("result", result);
      const {
        errors: _errors
      } = result;
      if (_errors?.length) {
        console.log(_errors);
        setErrors(JSON.stringify(_errors));
      } else {
        downloadDataFile({key: output, name: output.split("/").pop()});
      }
    }
  }, [numberOfValidInvocations, generateZippedDataLink, downloadDataFile]);
  const getInvocations = useCallback(setInvocations, []);
  return /* @__PURE__ */ React.createElement(VerifyUploadsTableContainer, null, /* @__PURE__ */ React.createElement(VerifyUploadsTable, {
    getFilteredInvocations,
    getInvocations,
    data: JSON.stringify(selected),
    showUpload: false,
    pollingFrequency: 1e4,
    startColumns: [
      {
        label: /* @__PURE__ */ React.createElement(CheckboxContainer, {
          htmlFor: "select-all"
        }, /* @__PURE__ */ React.createElement(Checkbox, {
          type: "checkbox",
          id: "select-all",
          onChange: selectAll,
          checked: allAreSelected
        })),
        width: 50,
        content: ({invocation, search}) => {
          const id = `checkbox-${invocation.key}`;
          const checked = selected[invocation.key] || false;
          return /* @__PURE__ */ React.createElement(CheckboxContainer, {
            htmlFor: id
          }, /* @__PURE__ */ React.createElement(Checkbox, {
            type: "checkbox",
            id,
            name: id,
            onChange: () => select([invocation.key]),
            checked
          }));
        }
      },
      {
        label: /* @__PURE__ */ React.createElement(Client, {
          htmlFor: "select-all"
        }, "Client (", Object.keys(selected).length, " selected)"),
        width: 0.5,
        dynamic: true,
        content: ({invocation, search}) => {
          const id = `checkbox-${invocation.key}`;
          return /* @__PURE__ */ React.createElement(Client, {
            htmlFor: id
          }, /* @__PURE__ */ React.createElement(Highlight, {
            value: invocation.attributes.client,
            search
          }));
        }
      }
    ]
  }), errors && /* @__PURE__ */ React.createElement("div", {
    style: {color: "red"}
  }, errors), /* @__PURE__ */ React.createElement(DownloadAll, {
    numberOfValidInvocations,
    downloadAll
  }));
};
const DownloadAll = ({
  numberOfValidInvocations,
  downloadAll
}) => {
  return /* @__PURE__ */ React.createElement(Button, {
    disabled: numberOfValidInvocations === void 0 || numberOfValidInvocations.length === 0,
    leftIcon: /* @__PURE__ */ React.createElement(BiDownload, null),
    onClick: () => downloadAll()
  }, "Download ", numberOfValidInvocations.length, " data file", numberOfValidInvocations.length !== 1 && "s");
};
