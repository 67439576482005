import {useCallback} from "../../../_snowpack/pkg/react.js";
import {useBackend} from "./internals/useBackend.js";
import {prepareKeysForServer} from "./internals/prepareKeysForServer.js";
import {prepareInvocation} from "./internals/prepareInvocation.js";
import {useUpdateAttributes} from "./internals/updateAttributes.js";
export function usePebble(pebbleName, namespace, ...args) {
  const [request] = useBackend();
  if (args?.length) {
    console.warn([
      "You've passed additional arguments to usePebble, but only the first argument (namespace) is accepted.",
      "If you are trying to pass a configuration object, pass it as an argument to the returned function."
    ].join(" "));
  }
  const pebbleNamespace = namespace || pebbleName;
  const [getUpdateAttributes] = useUpdateAttributes(pebbleNamespace, request);
  const runPebble = useCallback(async (params, {attributes, persist = false} = {}, requestParams = {}) => {
    const resp = await request({
      url: `gizmos/${pebbleNamespace}`,
      params: {
        method: "POST",
        body: {
          pebble_name: pebbleName,
          persist,
          params,
          attributes
        }
      }
    }, requestParams);
    const jsonData = await resp.json();
    if (persist) {
      return prepareInvocation(jsonData, getUpdateAttributes);
    }
    return prepareInvocation(jsonData);
  }, [pebbleName, getUpdateAttributes]);
  const listGizmoRuns = useCallback(async (opts = {}, requestParams = {}) => {
    if (opts.perPage !== void 0 && opts.perPage > 100) {
      console.warn("Maximum perPage value is 100.");
    }
    const params = prepareKeysForServer(opts);
    const resp = await request({
      url: `gizmos/${pebbleNamespace}?${params}`
    }, requestParams);
    const data = await resp.json();
    return data.map((invocation) => prepareInvocation(invocation, getUpdateAttributes));
  }, [pebbleName, getUpdateAttributes]);
  return [runPebble, listGizmoRuns];
}
