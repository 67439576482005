import React, {useMemo} from "../../../../../_snowpack/pkg/react.js";
import styled from "../../../../../_snowpack/pkg/@emotion/styled.js";
import {Link} from "../../../components/Link.js";
import BookmarkIcon from "../../../../../_snowpack/pkg/@material-ui/icons/Bookmark.js";
import {useLocation} from "../../../../../_snowpack/pkg/react-router-dom.js";
export const ROW_HEIGHT = 24;
const StoriesContainer = styled.div`
  label: StoriesContainer;
  flex: 1;
  display: flex;
  flex-direction: column;
`;
const SidebarCollection = styled.div`
  label: SidebarCollection;
  margin-left: 20px;
`;
const StyledLink = styled(Link)`
  label: StyledLink;
  && {
  display: flex;
  color: inherit;
  text-decoration: none;
  font-size: 13px;
  padding-left: 30px;
  height: ${ROW_HEIGHT}px;
  align-items: center;
  cursor: pointer;
  font-family: "Nunito Sans";

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }

  ${({active}) => active ? `
    background-color: #1EA7FD;
    color: #FFFFFF;
    letter-spacing: 0.5;

    &:hover {
      background-color: #1EA7FD;
    }
  ` : ""}
  }
`;
const IconContainer = styled.div`
  label: IconContainer;
  width: 12px;
  margin-right: 9px;

  svg {
    max-width: 100%;

    color: ${({active}) => active ? "#FFF" : "#1EA7FD"};
    opacity: 0.7;
  }
`;
const CategoryTitle = styled.h2`
  label: SidebarSubheading;

  && {
    user-select: none;
    letter-spacing: 0.35em;
    text-transform: uppercase;
    font-size: 11px;
    line-height: 24px;
    color: rgba(51, 51, 51, 0.5);
    font-family: "Nunito Sans";
    font-weight: 700;
    padding-top: 0;
    display: inline-block;
  }
`;
const ARROW_SIZE = 4;
const CategoryContainer = styled.div`
  label: CategoryContainer;
  margin: 20px 0 0 0;
  padding-top: 14px;
  padding-left: 10px;
  margin-left: -10px;
  ${({placeAtBottom: end}) => end ? `
    flex: 1;
    align-items: flex-end;
    display: flex;
  ` : ""}
`;
const CategoryDetails = styled.details`
  label: CategoryDetails;
  transition: all 0.1s ease;
  overflow: hidden ;
  margin-left: -10px;
  width: calc(100% + 10px);

  &:not([open]) {
    max-height: ${ROW_HEIGHT}px;
  }

  &[open] {
    max-height: ${({items}) => ROW_HEIGHT * (1 + items)}px;
    > summary {
      &:before {
        transform: rotate(0deg);
      }
    }
  }

  > summary {
    list-style-type: none;
    position: relative;
    display: flex;
    align-items: center;
    font-size: 13px;
    height: 24px;
    font-family: "Nunito Sans";
    padding-left: 3px;
    cursor: pointer;
    display: flex;
    align-items: center;
    user-select: none;
    margin-left: 10px;

    &::-webkit-details-marker {
      display: none;
    }
    &::marker {
      display: none;
    }

    &:hover {
      background-color: rgba(0,0,0,0.05);
    }

    &:before {
      content: '';
      width: 0; 
      transition: all 0.1s ease;
      height: 0; 
      border-left:  ${ARROW_SIZE}px solid transparent;
      border-right: ${ARROW_SIZE}px solid transparent;
      border-top:   ${ARROW_SIZE}px solid rgb(153, 153, 153);
      margin: 0 5px;
      transform: rotate(-90deg);
   }
  }
`;
const DetailsContainer = styled.details`
  label: Details;
  transition: all 0.1s ease;
  overflow: hidden;
  margin-left: -20px;
  padding-left: 10px;

  &:not([open]) {
    max-height: ${ROW_HEIGHT}px;
  }

  &[open] {
    max-height: ${({items}) => ROW_HEIGHT * (1 + items)}px;

    > summary {
      &:before {
        transform: rotate(0deg);
      }
    }
  }

  > summary {
    list-style-type: none;
    position: relative;
    display: flex;
    align-items: center;
    font-size: 13px;
    height: 24px;
    font-family: "Nunito Sans";
    padding-left: 3px;
    cursor: pointer;
    display: flex;
    align-items: center;
    user-select: none;
    margin-left: 10px;

    &::-webkit-details-marker {
      display: none;
    }
    &::marker {
      display: none;
    }

    &:hover {
      background-color: rgba(0,0,0,0.05);
    }

    &:before {
      content: '';
      width: 0; 
      transition: all 0.1s ease;
      height: 0; 
      border-left:  ${ARROW_SIZE}px solid transparent;
      border-right: ${ARROW_SIZE}px solid transparent;
      border-top:   ${ARROW_SIZE}px solid rgb(153, 153, 153);
      margin: 0 5px;
      transform: rotate(-90deg);
   }
  }
`;
const isStoryCollection = (story) => {
  return "stories" in story;
};
const isCategory = (story) => {
  for (let i = 0; i < story.stories.length; i++) {
    if (isStoryCollection(story.stories[i])) {
      return true;
    }
  }
  return false;
};
const Details = ({
  active,
  items,
  children
}) => {
  const open = useMemo(() => active, []);
  return /* @__PURE__ */ React.createElement(DetailsContainer, {
    items,
    open
  }, children);
};
const getActive = (urlA, urlB) => {
  const urlAParts = urlA.split("/");
  const urlBParts = urlB.split("/");
  const min = urlAParts.length < urlBParts.length ? urlAParts.length : urlBParts.length;
  for (let i = 0; i < min; i++) {
    if (urlAParts[i] !== urlBParts[i]) {
      return false;
    }
  }
  return true;
};
export const Stories = ({
  stories
}) => {
  const location = useLocation();
  const url = location.pathname;
  return /* @__PURE__ */ React.createElement(StoriesContainer, null, stories.map((story, key) => {
    if (isStoryCollection(story)) {
      if (isCategory(story)) {
        const numberOfStories = story.stories.reduce((_sum, _story) => {
          if (isStoryCollection(_story)) {
            return _story.stories.reduce((sum) => {
              return sum + 1;
            }, _sum + 1);
          }
          return _sum + 1;
        }, 0);
        return /* @__PURE__ */ React.createElement(CategoryContainer, {
          key
        }, /* @__PURE__ */ React.createElement(CategoryDetails, {
          items: numberOfStories,
          open: true
        }, /* @__PURE__ */ React.createElement("summary", null, /* @__PURE__ */ React.createElement(CategoryTitle, null, story.title)), /* @__PURE__ */ React.createElement(Stories, {
          stories: story.stories
        })));
      }
      const active2 = story.stories.reduce((_active, story2) => {
        if (_active) {
          return _active;
        }
        return getActive(story2.url, url);
      }, false);
      return /* @__PURE__ */ React.createElement(SidebarCollection, {
        key
      }, /* @__PURE__ */ React.createElement(Details, {
        items: story.stories.length,
        active: active2
      }, /* @__PURE__ */ React.createElement("summary", null, story.title), /* @__PURE__ */ React.createElement(Stories, {
        stories: story.stories
      })));
    }
    const active = getActive(story.url, url);
    return /* @__PURE__ */ React.createElement(StyledLink, {
      active,
      key,
      href: story.url
    }, /* @__PURE__ */ React.createElement(IconContainer, {
      active
    }, /* @__PURE__ */ React.createElement(BookmarkIcon, null)), /* @__PURE__ */ React.createElement("span", null, story.title));
  }));
};
