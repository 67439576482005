import {useCallback, useEffect, useRef, useState} from "../../../_snowpack/pkg/react.js";
import {isDOMException} from "./internals/useBackend.js";
import {useCancellableTimer} from "./internals/useCancellableTimer.js";
const isAbortError = (err) => {
  if (isDOMException(err.response)) {
    return err.response.name === "AbortError";
  }
  return false;
};
export function usePoll(fn, dependencies = [], {
  debug = false,
  timeout = 5e3,
  duration = 5e3,
  shouldReset = false,
  onError,
  until
} = {}) {
  const [set, cancel, isCancelled, reset] = useCancellableTimer(dependencies, {
    debug
  });
  const [data, setData] = useState();
  const localDuration = useRef(duration);
  const localTimeout = useRef(timeout);
  useEffect(() => {
    localDuration.current = duration;
  }, [duration]);
  useEffect(() => {
    localTimeout.current = timeout;
  }, [timeout]);
  const pollForFn = useCallback(async (fn2) => {
    if (fn2) {
      try {
        let timer;
        const getAbortFn = (abort) => {
          timer = setTimeout(() => {
            if (debug) {
              console.log("usePoll: abort because of timeout, if not already cancelled", fn2, dependencies, timeout);
            }
            return abort();
          }, localTimeout.current);
        };
        const _data = await fn2({
          getAbortFn
        });
        clearTimeout(timer);
        if (_data instanceof Error) {
          throw _data;
        }
        if (!isCancelled) {
          setData(_data);
        }
      } catch (err) {
        if (!isAbortError(err)) {
          if (debug) {
            console.log("usePoll: error caught", fn2, err);
            console.log(err.response);
          }
          if (onError) {
            onError(err);
          }
        }
      }
      if (debug) {
        console.log("usePoll: got back data, set new polling fn", fn2, data);
      }
      set(() => {
        pollForFn(fn2);
      }, localDuration.current);
    }
  }, [debug]);
  useEffect(() => {
    if (until && until(data)) {
      if (debug) {
        console.log("usePoll: until has been deemed truthful", fn, dependencies);
      }
      cancel();
    } else if (debug) {
      console.log("usePoll: until has not been deemed truthful and will continue", fn, dependencies);
    }
  }, [cancel, debug, data]);
  useEffect(() => {
    if (fn) {
      if (debug) {
        console.log("usePoll: change, start pollForFn", fn, dependencies);
      }
      if (shouldReset) {
        reset();
      }
      pollForFn(fn);
      return () => {
        if (debug) {
          console.log("usePoll: clear polling function", fn, dependencies);
        }
        setData(void 0);
        cancel();
      };
    }
  }, [shouldReset, debug, cancel, ...dependencies]);
  return [data, cancel, reset];
}
