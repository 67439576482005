import React, {useContext, createContext, useCallback, useEffect, useState} from "../../../../../_snowpack/pkg/react.js";
import {Auth0Provider} from "../../../../../_snowpack/pkg/@auth0/auth0-react.js";
import {ConfigContext} from "../../../Config.js";
import {useAuth0} from "../../../../../_snowpack/pkg/@auth0/auth0-react.js";
import styled from "../../../../../_snowpack/pkg/@emotion/styled.js";
import {Loading} from "../../../components/Loading.js";
import {BLUE} from "../../../constants.js";
import {useHistory, useLocation} from "../../../../../_snowpack/pkg/react-router-dom.js";
const LogoutLink = styled.a`
  label: LogoutLink;
  font-size: 10px;
  color: rgba(0,0,0,0.6);

  &:hover {
    color: ${BLUE.toString()};
    text-decoration: underline;
  }
`;
export const AuthContext = createContext({
  setExpired: () => {
  }
});
export const LogoutButton = () => {
  const {logout} = useAuth0();
  return /* @__PURE__ */ React.createElement(LogoutLink, {
    onClick: () => logout({returnTo: window.location.origin})
  }, "Log Out");
};
const useTokenFn = (fn, deps) => {
  const [error, setError] = useState();
  const tokenFn = useCallback(async () => {
    try {
      fn();
    } catch (err) {
      setError(err);
    }
  }, deps);
  return [
    tokenFn,
    error
  ];
};
const useTokens = () => {
  const {AUTH0} = useContext(ConfigContext);
  const [accessToken, setAccessToken] = useState();
  const [idToken, setIdToken] = useState();
  const {getIdTokenClaims, getAccessTokenSilently, loginWithRedirect, user, isAuthenticated, isLoading} = useAuth0();
  const [getAccessToken, accessTokenError] = useTokenFn(async () => {
    const accessToken2 = await getAccessTokenSilently({
      audience: `https://${AUTH0.domain}/api/v2/`,
      scope: AUTH0.scope
    });
    setAccessToken(accessToken2);
  }, [getAccessTokenSilently]);
  const [getIdToken, idTokenError] = useTokenFn(async () => {
    const idTokenClaims = await getIdTokenClaims();
    if (idTokenClaims) {
      setIdToken(idTokenClaims["__raw"]);
    }
  }, [getIdTokenClaims]);
  const refreshTokens = useCallback(async () => await Promise.all([
    getIdToken(),
    getAccessToken()
  ]), [getIdToken, getAccessToken]);
  useEffect(() => {
    if (isAuthenticated) {
      refreshTokens();
    }
  }, [refreshTokens, isAuthenticated]);
  return {
    refreshTokens,
    accessToken,
    idToken,
    accessTokenError,
    idTokenError
  };
};
export const useTryToRefreshToken = () => {
  const [redirectToLoginScreen] = useLoginWithRedirect();
  const {refreshTokens} = useTokens();
  const [refreshing, setRefreshing] = useState(false);
  const {isAuthenticated, isLoading} = useAuth0();
  const refreshToken = useCallback(async () => {
    console.log("refreshing token");
    setRefreshing(true);
    await refreshTokens();
    console.log("at this point, token should be refreshed");
    setRefreshing(false);
  }, [refreshTokens]);
  useEffect(() => {
    if (!isLoading && !isAuthenticated && !refreshing) {
      redirectToLoginScreen();
    }
  }, [refreshing, isLoading, isAuthenticated]);
  return [refreshToken];
};
const useAuthenticatedCheck = () => {
  const [redirectToLoginScreen] = useLoginWithRedirect();
  const {loginWithRedirect, isAuthenticated, isLoading} = useAuth0();
  useEffect(() => {
    if (isLoading === false && isAuthenticated === false) {
      console.log("loading is false and authenticated is false");
      console.log(isLoading, isAuthenticated);
      redirectToLoginScreen();
    }
  }, [loginWithRedirect, isLoading, isAuthenticated]);
};
export const useLoginWithRedirect = () => {
  const {loginWithRedirect} = useAuth0();
  const {pathname} = useLocation();
  const redirectToLoginScreen = useCallback(() => {
    const options = {
      apiURL: "https://staging.onarchipelago.com/api",
      appState: {
        returnTo: pathname,
        foo: "foo"
      }
    };
    loginWithRedirect(options);
  }, [pathname, loginWithRedirect]);
  return [redirectToLoginScreen];
};
const AuthCheck = ({children}) => {
  const {user, isAuthenticated, isLoading} = useAuth0();
  useAuthenticatedCheck();
  const {
    accessToken,
    idToken,
    accessTokenError,
    idTokenError
  } = useTokens();
  const [expired, _setExpired] = useState(false);
  const setExpired = useCallback(() => {
    _setExpired(true);
  }, []);
  const errors = [accessTokenError, idTokenError];
  for (let i = 0; i < errors.length; i++) {
    const error = errors[i];
    if (error) {
      return /* @__PURE__ */ React.createElement("div", null, "Authentication Error: ", JSON.stringify(error));
    }
  }
  if (isLoading || !isAuthenticated || !accessToken) {
    return /* @__PURE__ */ React.createElement(Loading, {
      label: "authenticating",
      flex: true
    });
  }
  return /* @__PURE__ */ React.createElement(AuthContext.Provider, {
    value: {
      expired,
      setExpired,
      user: {
        picture: user.picture,
        name: user.name,
        email: user.email,
        id: user["https://onarchipelago.com/user"].user_id
      },
      accessToken,
      idToken
    }
  }, children);
};
const Auth = ({children}) => {
  const {AUTH0} = useContext(ConfigContext);
  const history = useHistory();
  return /* @__PURE__ */ React.createElement(Auth0Provider, {
    domain: AUTH0.domain,
    clientId: AUTH0.clientId,
    redirectUri: window.location.origin,
    audience: AUTH0.audience,
    useRefreshTokens: AUTH0.useRefreshTokens,
    scope: AUTH0.scope,
    onRedirectCallback: (appState) => {
      history.replace({
        pathname: appState?.returnTo || window.location.pathname,
        search: ""
      });
    }
  }, /* @__PURE__ */ React.createElement(AuthCheck, null, children));
};
export default Auth;
