import * as __SNOWPACK_ENV__ from '../../_snowpack/env.js';

import React, {useEffect} from "../../_snowpack/pkg/react.js";
import {createContext, useState} from "../../_snowpack/pkg/react.js";
const AUTH0 = {
  clientId: "jFo4BPrceduRLRU47o4lwR26pcQerZuZ",
  domain: "archipelago-staging.auth0.com",
  audience: "https://archipelago-staging.auth0.com/api/v2/",
  redirectUri: `${origin}/callback`,
  cacheLocation: "localstorage",
  useRefreshTokens: true,
  scope: "read:current_user"
};
class Setter {
  constructor() {
    this.configElements = [];
    this.setConfig = (config) => {
      this.configElements.push(config);
    };
    this.updateSetConfig = (setConfig2) => {
      this.setConfig = setConfig2;
      while (this.configElements.length) {
        const config = this.configElements.shift();
        if (config) {
          this.setConfig(config);
        }
      }
    };
  }
}
const setter = new Setter();
export const setConfig = (config) => {
  setter.setConfig(config);
};
const BUILT_AT = __SNOWPACK_ENV__.SNOWPACK_PUBLIC_BUILD_TIME;
const defaultConfigContext = {
  AUTH0,
  BACKEND_URL: __SNOWPACK_ENV__.SNOWPACK_PUBLIC_API_URL,
  ENV: __SNOWPACK_ENV__.SNOWPACK_PUBLIC_ENV,
  BUILT_AT: BUILT_AT ? new Date(parseInt(BUILT_AT, 10)) : null,
  VERSION: __SNOWPACK_ENV__.SNOWPACK_PUBLIC_PACKAGE_VERSION,
  STORIES: []
};
export const ConfigContext = createContext(defaultConfigContext);
const Config = ({children}) => {
  const [config, setConfig2] = useState(defaultConfigContext);
  useEffect(() => {
    const _setConfig = (config2) => {
      setConfig2((prev) => ({
        ...prev,
        config: config2
      }));
    };
    setter.updateSetConfig(_setConfig);
  }, []);
  return /* @__PURE__ */ React.createElement(ConfigContext.Provider, {
    value: config
  }, children);
};
export default Config;
