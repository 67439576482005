import React, {useEffect, useState} from "../../../../_snowpack/pkg/react.js";
import styled from "../../../../_snowpack/pkg/@emotion/styled.js";
const Container = styled.div`
  label: Background;
  transition-duration: 2s;
  color: rgb(51, 51, 51);
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: ${({loaded}) => loaded ? "#f6f9fc" : "white"};
  display: flex;
  flex-direction: row;
  box-sizing: border-box;

  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 24px;
  }
  h3 {
    font-size: 18px;
  }
  h4 {
    font-size: 16px;
  }
  h5 {
    font-size: 13px;
  }
  h6 {
    font-size: 10px;
  }
`;
const Background = ({children}) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 1);
    return () => clearTimeout(timer);
  }, []);
  return /* @__PURE__ */ React.createElement(Container, {
    loaded
  }, children);
};
export default Background;
