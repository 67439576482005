import React, {useRef, useState, useEffect} from "../../../../../../_snowpack/pkg/react.js";
import styled from "../../../../../../_snowpack/pkg/@emotion/styled.js";
import {constants} from "../../../../../../dist/lib/index.js";
import {FiSearch} from "../../../../../../_snowpack/pkg/react-icons/fi.js";
import {MapButtonLeft} from "./MapButton.js";
const {
  BLUE
} = constants;
const SearchContainer = styled.div`
  label: SearchContainer;
  display: flex;
  align-items: center;
  transition-duration: ${({visible}) => visible ? "0.2s" : "0.6s"};
  width: ${({visible}) => visible ? "100%" : "26px"};
  background: white;
  box-shadow: 0 1px 2px rgba(0,0,0,0.4);
  padding: 5px;
  border-radius: 2px;
  cursor: pointer;
  height: 26px;

  svg {
    color: rgba(0, 0, 0, 0.8);
    transition-duration: 0.2s;
    color: ${({visible}) => visible ? BLUE.toString() : ""};
    margin-left: ${({visible}) => visible ? "-21px" : "0"};
    opacity: ${({visible}) => visible ? "0" : "1"};

    &:hover {
      color: ${BLUE.toString()};
    }
  }
`;
const Input = styled.input`
  label: SearchInput;
  transition-duration: ${({visible}) => visible ? "0.2s" : "0.6s"};
  opacity: ${({visible}) => visible ? "1" : "0"};
  flex: 1;
  margin-left: ${({visible}) => visible ? "5px" : "0"};
  max-width: ${({visible}) => visible ? "999px" : "0"};
  padding: ${({visible}) => visible ? "0 5px" : "0"};
  cursor: ${({visible}) => visible ? "auto" : "pointer"};
  font-size: 12px;
`;
const Search = ({
  address,
  handleSubmit,
  maps,
  handleFocus
}) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(address);
  const ref = useRef(null);
  useEffect(() => {
    handleFocus(open);
    if (open) {
      if (ref.current) {
        ref.current.focus();
      }
    }
  }, [open]);
  useEffect(() => {
    const input = ref.current;
    if (maps && input) {
      const searchBox = new maps.places.SearchBox(input);
      searchBox.addListener("places_changed", () => {
        const places = searchBox.getPlaces();
        const place = places.shift();
        const geometry = place.geometry;
        setValue(place.formatted_address);
        handleSubmit({
          lat: geometry.location.lat(),
          lng: geometry.location.lng()
        });
      });
      return () => {
        maps.event.clearInstanceListeners(searchBox);
      };
    }
  }, [maps, ref]);
  return /* @__PURE__ */ React.createElement(MapButtonLeft, {
    full: open
  }, /* @__PURE__ */ React.createElement(SearchContainer, {
    visible: open,
    onClick: () => setOpen(true)
  }, /* @__PURE__ */ React.createElement(FiSearch, null), /* @__PURE__ */ React.createElement(Input, {
    type: "text",
    visible: open,
    ref,
    value,
    onChange: (e) => setValue(e.target.value),
    onBlur: () => setOpen(false)
  })));
};
export default Search;
