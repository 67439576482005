import React, {useCallback, useRef} from "../../../../../../_snowpack/pkg/react.js";
import styled from "../../../../../../_snowpack/pkg/@emotion/styled.js";
import {MappingReviewModal} from "./MappingReviewModal.js";
const Title = styled.h1`
  label: Title;
  font-size: 36px;
  color: rgba(0,0,0,0.8);
  margin-bottom: 40px;
`;
const TextareaContainer = styled.div`
  label: TextareaContainer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;
const StyledTextarea = styled.textarea`
  label: Textarea;
  height: 100%;
  padding: 20px;
  background: #EEE;
  text-shadow: 0 1px white;
  flex: 1;
`;
export const DescriptionModal = ({
  label,
  id,
  value
}) => {
  return /* @__PURE__ */ React.createElement(MappingReviewModal, {
    id: `view-description-${id}`
  }, () => /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Title, null, label), /* @__PURE__ */ React.createElement(Textarea, {
    value
  })));
};
const Textarea = ({value}) => {
  const ref = useRef();
  const handleFocus = useCallback(() => {
    ref.current.select();
  }, []);
  return /* @__PURE__ */ React.createElement(TextareaContainer, null, /* @__PURE__ */ React.createElement(StyledTextarea, {
    ref,
    readOnly: true,
    value,
    onFocus: handleFocus
  }));
};
