import {useCallback, useRef} from "../../../../_snowpack/pkg/react.js";
import {useTimer} from "./useTimer.js";
export const useCancellableTimer = (dependencies, {debug} = {}) => {
  const cancelled = useRef(false);
  const [setTimer, clear] = useTimer(dependencies);
  const reset = useCallback(() => {
    cancelled.current = false;
  }, []);
  const set = useCallback((fn, duration) => {
    if (cancelled.current === false) {
      if (debug) {
        console.log("useCancellableTimer: set was called and timer is still active");
      }
      setTimer(fn, duration);
    } else if (debug) {
      console.log("useCancellableTimer: set was called, but timer has been cancelled");
    }
  }, [debug]);
  const cancel = useCallback(() => {
    if (debug) {
      console.log("useCancellableTimer: cancel timer");
    }
    cancelled.current = true;
    clear();
  }, [debug, clear]);
  const isCancelled = cancelled.current;
  return [set, cancel, isCancelled, reset];
};
