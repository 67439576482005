import React, {useCallback} from "../../../_snowpack/pkg/react.js";
import styled from "../../../_snowpack/pkg/@emotion/styled.js";
import {GrFormClose} from "../../../_snowpack/pkg/react-icons/gr.js";
import ReactModal from "../../../_snowpack/pkg/react-modal.js";
import {useStory} from "../hooks/index.js";
const CloseButton = styled.div`
  label: CloseButton;
  position: fixed;
  top: -13px;
  right: -13px;
  cursor: pointer;
  padding: 5px;
  border-radius: 20px;
  z-index: 100;
  background-color: white;
  box-shadow: 0 1px 2px rgba(0,0,0,0.2);

  &:hover {
    background-color: #9ed5f7;
  }
`;
const ModalContainer = styled.div`
  label: ModalContainer;
  height: 100%;
  width: calc(100% + 40px);
  padding: 0 20px;
  margin-left: -20px;
  // margin-top: 20px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  position: relative;
`;
export const Modal = ({
  children,
  modalKey,
  modalLabel,
  style = {},
  allowClose = true
}) => {
  const {params, update} = useStory();
  const closeModal = useCallback(() => modalKey ? update({modal: 0}) : void 0, [update]);
  const openModal = useCallback(() => modalKey ? update({modal: modalKey}) : void 0, [update]);
  const visible = modalKey ? params.modal === modalKey : true;
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(ReactModal, {
    ariaHideApp: false,
    isOpen: allowClose ? visible : true,
    onRequestClose: allowClose ? closeModal : () => {
    },
    style: {
      overlay: {
        backgroundColor: "rgba(0,0,0,0.5)"
      },
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        width: "calc(100% - 80px)",
        height: "calc(100% - 80px)",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        padding: 40,
        overflow: "inherit"
      },
      ...style
    }
  }, allowClose && /* @__PURE__ */ React.createElement(CloseButton, null, /* @__PURE__ */ React.createElement(GrFormClose, {
    onClick: closeModal
  })), /* @__PURE__ */ React.createElement(ModalContainer, null, children({visible}))), modalLabel && modalLabel({openModal}));
};
