import loadable from "../../../../_snowpack/pkg/@loadable/component.js";
const HazardsSOVs = loadable(() => import("./HazardsSOVs/HazardsSOVs.js"), {
  resolveComponent: (components) => components.HazardsSOVs
});
const editorPath = "Editor";
export default {
  title: "Hazards",
  stories: [
    {
      title: "Upload",
      content: HazardsSOVs
    },
    {
      title: editorPath,
      content: HazardsSOVs
    }
  ]
};
