import React, {useMemo, useState} from "../../../../../_snowpack/pkg/react.js";
import styled from "../../../../../_snowpack/pkg/@emotion/styled.js";
import {Loading} from "../../../../../dist/lib/index.js";
import Provenance from "./Provenance.js";
import Alert from "./Alert.js";
import {getValueAndProvenanceFromSuccessfulResponse} from "./getValueAndProvenanceFromSuccessfulResponse.js";
import {rowKeyForColumnKey} from "../hazards_constants.js";
const LoadingContainer = styled.div`
  label: LoadingContainer;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const CellContainer = styled.div`
  label: CellContainer;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  border-right: 1px solid rgba(0,0,0,0.1);
  padding: 2px;
  flex: 1;
  height: 100%;
  position: relative;

  background-color: ${({hasError}) => hasError ? "rgba(255, 100, 100, 0.1)" : "transparent"};
`;
export const Cell = ({
  columnIndex,
  rowIndex,
  data: {
    data,
    columns
  }
}) => {
  const [over, setOver] = useState(false);
  const column = columns[columnIndex];
  const row = data[rowIndex];
  const key = column.key;
  const cellData = useMemo(() => {
    const pebbleName2 = rowKeyForColumnKey[key];
    if (typeof row[pebbleName2] === "number" || typeof row[pebbleName2] === "string") {
      return {
        value: row[pebbleName2],
        isCompleted: row.completed
      };
    }
    if (Array.isArray(pebbleName2)) {
      for (let i = 0; i < pebbleName2.length; i++) {
        const value2 = row[pebbleName2[i]];
        if (value2 !== void 0 && value2 !== null) {
          return {
            value: value2,
            isCompleted: true
          };
        }
      }
      return {
        value: "-"
      };
    }
    const data2 = row[pebbleName2];
    if (!data2) {
      return {
        value: data2,
        isCompleted: row.completed
      };
    }
    if (data2.errorMessage) {
      return {
        isCompleted: true,
        error: data2.errorMessage
      };
    }
    if (data2.errors?.length) {
      return {
        isCompleted: true,
        error: data2.errors.map((e) => e.message).join(",")
      };
    }
    const {value, provenance, unit} = getValueAndProvenanceFromSuccessfulResponse(key, data2);
    return {
      value: value || "-",
      provenance,
      unit,
      isCompleted: row.completed,
      error: void 0
    };
  }, [row, key]);
  const pebbleName = rowKeyForColumnKey[key];
  if (pebbleName === "geo/base-flood-elevation") {
    console.log("value", cellData, row);
  }
  return /* @__PURE__ */ React.createElement(CellContainer, {
    lastColumn: columnIndex === columns.length - 1,
    lastRow: rowIndex === data.length - 1,
    hasError: !!cellData.error,
    onMouseOver: () => setOver(true),
    onMouseLeave: () => setOver(false)
  }, /* @__PURE__ */ React.createElement(CellData, {
    data: cellData,
    over
  }));
};
const CellData = ({
  over,
  data: {
    value,
    error,
    provenance,
    isCompleted,
    unit
  }
}) => /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(CellValue, {
  value,
  isCompleted,
  unit
}), !!error && /* @__PURE__ */ React.createElement(Alert, {
  error,
  over
}), provenance && /* @__PURE__ */ React.createElement(Provenance, {
  provenance,
  over
}));
const CellValue = ({
  value,
  isCompleted,
  unit
}) => {
  if (value === void 0) {
    if (isCompleted) {
      return /* @__PURE__ */ React.createElement(React.Fragment, null, "-");
    }
    return /* @__PURE__ */ React.createElement(LoadingContainer, null, /* @__PURE__ */ React.createElement(Loading, {
      label: "cell",
      size: 20,
      fadeIn: 0
    }));
  }
  if (value === null) {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, "-");
  }
  if (typeof value === "string" || typeof value === "number") {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, value, unit);
  }
  return value;
};
