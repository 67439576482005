import React, {Component} from "../../../_snowpack/pkg/react.js";
import styled from "../../../_snowpack/pkg/@emotion/styled.js";
const Container = styled.div`
  label: Container;
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(255, 0, 0, 0.1);
  padding: 40px;
`;
const ErrorContainer = styled.div`
  label: ErrorContainer;
  background: rgba(255, 0, 0, 0.2);
  word-break: all;
  color: black;
  width: 100%;
  flex: 1;
  overflow-y: scroll;
  padding: 20px;
  border-radius: 20px;
  word-break: break-all;
  white-space: pre-wrap;
  box-sizing: border-box;

  pre {
    margin-top: 20px;
  }
`;
export class ErrorBoundary extends Component {
  constructor() {
    super(...arguments);
    this.state = {error: void 0};
  }
  componentDidCatch(error) {
    this.setState({error});
  }
  render() {
    const {children} = this.props;
    const {error} = this.state;
    if (error) {
      return /* @__PURE__ */ React.createElement(Container, null, /* @__PURE__ */ React.createElement(ErrorContainer, null, error.message, /* @__PURE__ */ React.createElement("pre", null, error.stack)));
    }
    return children;
  }
}
