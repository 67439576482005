import React from "../../../../../../_snowpack/pkg/react.js";
import {Loading} from "../../../../../../dist/lib/index.js";
import styled from "../../../../../../_snowpack/pkg/@emotion/styled.js";
import {MappingReviewModal} from "./MappingReviewModal.js";
const Title = styled.h1`
  label: Title;
  font-size: 36px;
  color: rgba(0,0,0,0.8);
  margin-bottom: 40px;
`;
export const PropertiesModal = ({
  grouping
}) => {
  if (!grouping) {
    return null;
  }
  return /* @__PURE__ */ React.createElement(MappingReviewModal, {
    id: `view-properties-${grouping.index}`
  }, () => {
    if (!grouping.properties?.[0]) {
      return /* @__PURE__ */ React.createElement(Loading, {
        flex: true,
        label: "Loading properties"
      });
    }
    const keys = Object.keys(grouping.properties[0]);
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Title, null, "Properties"), /* @__PURE__ */ React.createElement("table", null, /* @__PURE__ */ React.createElement("thead", null, /* @__PURE__ */ React.createElement("tr", null, keys.map((key, i) => /* @__PURE__ */ React.createElement("th", {
      key: i
    }, key)))), /* @__PURE__ */ React.createElement("tbody", null, grouping.properties.map((property, i) => /* @__PURE__ */ React.createElement("tr", {
      key: i
    }, keys.map((key, i2) => /* @__PURE__ */ React.createElement("td", {
      key: i2
    }, property[key])))))));
  });
};
