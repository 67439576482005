export const getValueAndProvenanceFromSuccessfulResponse = (key, payload) => {
  const {data} = payload || {};
  if (!data) {
    return {};
  }
  if (key === "seismicHazardClass") {
    return {
      value: data.seismicHazardClass
    };
  }
  if (key === "quakeZone") {
    return {
      value: data.quakeZone
    };
  }
  if (key === "liquefactionSusceptibility") {
    const _data = data;
    return {
      value: _data.liquefactionSusceptibility,
      provenance: _data.provenance
    };
  }
  if (key === "landslideSusceptibility") {
    const _data = data;
    return {
      value: _data.landslideSusceptibility,
      provenance: _data.provenance
    };
  }
  if (key === "wildFireHazard") {
    return {
      value: data.wildFireHazard
    };
  }
  if (key === "tornadoHazard") {
    return {
      value: data.tornadoHazard
    };
  }
  if (key === "hailHazardClass") {
    return {
      value: data.hailHazardClass
    };
  }
  if (key === "lightningHazard") {
    return {
      value: data.lightningHazard
    };
  }
  if (key === "tsunamiHazardClass") {
    return {
      value: data.tsunamiHazardClass
    };
  }
  if (key === "siteSoilClassification") {
    const _data = data;
    return {
      value: _data.siteSoilClassification,
      provenance: _data.provenance
    };
  }
  if (key === "distanceToFault") {
    const _data = data;
    return {
      value: _data.milesToFault,
      provenance: _data.provenance,
      unit: "mi"
    };
  }
  if (key === "nearestFaultName") {
    const _data = data;
    return {
      value: _data.faultName,
      provenance: _data.provenance
    };
  }
  if (key === "windHazard") {
    return {
      value: data.windHazard
    };
  }
  if (key === "windZone") {
    return {
      value: data.windZone
    };
  }
  if (key === "distanceToCoast") {
    return {
      value: data.milesToCoast,
      unit: "mi"
    };
  }
  if (key === "floodZone") {
    return {
      value: data.floodZone
    };
  }
  if (key === "baseFloodElevation") {
    const value = data.baseFloodElevation;
    return {
      value,
      unit: value ? '"' : void 0
    };
  }
  if (key === "adjacentGradeElevation") {
    return {
      value: data.adjacentGradeElevation
    };
  }
  if (key === "rainfallIntensity") {
    return {
      value: data.rainfallIntensity
    };
  }
  throw new Error(`No mapping found for key ${key}`);
};
