import {useEditableFileForArrays} from "./useEditableFile.js";
const useData = (key, editableKey) => {
  const props = {
    transformOriginalData: (row) => ({
      ...row,
      userEdited: row.distanceToNearestBuilding?.data
    }),
    transformEditableData: (row, userEdited) => ({
      ...row,
      userEdited
    })
  };
  const [data, setData] = useEditableFileForArrays(key, editableKey, props);
  return {data, setData};
};
export default useData;
