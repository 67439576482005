import {buildQueryString} from "./buildQueryString.js";
export const prepareFile = (data, getContent) => {
  const attributes = data.attributes;
  return {
    ...data,
    created: new Date(data.created * 1e3),
    modified: new Date(data.modified * 1e3),
    attributes,
    getContent,
    src: `file_content/${data.key}?${buildQueryString({
      bucket: data.bucket
    })}`,
    preview: `file_content/${data.key}?${buildQueryString({
      bucket: data.bucket,
      preview: true
    })}`
  };
};
