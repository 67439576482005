import {useEffect, useState} from "../../../../../../../_snowpack/pkg/react.js";
import {usePebble} from "../../../../../../../dist/lib/index.js";
export const useAttributeConfig = () => {
  const [getAttributeConfig] = usePebble("do-verification/attribute-configs");
  const [attributeConfig, setAttributeConfig] = useState({
    construction: [],
    occupancy: []
  });
  const [_version, setVersion] = useState();
  useEffect(() => {
    (async () => {
      const {result} = await getAttributeConfig({}, {
        method: "GET"
      });
      setVersion(result.data.version);
      const parsedAttributeConfig = result.data.data.reduce((obj, datum) => {
        if (["constructionType", "specificOccupancy"].includes(datum.apiName)) {
          return {
            ...obj,
            [datum.apiName]: datum.enumValues
          };
        }
        return obj;
      }, {});
      setAttributeConfig({
        construction: parsedAttributeConfig["constructionType"],
        occupancy: parsedAttributeConfig["specificOccupancy"]
      });
    })();
  }, [getAttributeConfig]);
  return [attributeConfig, _version];
};
