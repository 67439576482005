import React from "../../../../_snowpack/pkg/react.js";
import {Loading} from "../../../../dist/lib/index.js";
import {SOVAppInvocations} from "../../../components/SOVAppInvocations/SOVAppInvocations.js";
import {DistanceToNearestBuildingPage} from "./DistanceToNearestBuildingPage.js";
const NAMESPACE = "distance-to-nearest-building";
export const DistanceToNearestBuildingSOVs = ({params, update}) => {
  return /* @__PURE__ */ React.createElement(SOVAppInvocations, {
    pebble: "alpha-dtnb/run",
    gizmoName: NAMESPACE,
    params,
    update,
    getName: (invocation) => invocation.attributes.name,
    uploadChildrenHeader: /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("h1", null, "Distance to nearest building"), /* @__PURE__ */ React.createElement("h2", null, "Upload a Spreadsheet"))
  }, ({invocation}) => {
    if (invocation) {
      return /* @__PURE__ */ React.createElement(DistanceToNearestBuildingPage, {
        invocation
      });
    }
    return /* @__PURE__ */ React.createElement(Loading, {
      label: "loading invocation for distance-to-nearest-building",
      flex: true
    });
  });
};
