import React, {useMemo} from "../../../../../../_snowpack/pkg/react.js";
import styled from "../../../../../../_snowpack/pkg/@emotion/styled.js";
import {getIsChanged} from "./getColumns.js";
import {YELLOW} from "../../../../../../dist/lib/constants.js";
import {Table} from "../../../../../../dist/lib/index.js";
import {Highlight} from "../../../../../components/SOVAppInvocations/SOVAppInvocationsTable/SOVAppInvocationsTableWrapper/Highlight.js";
import {DescriptionModal} from "./DescriptionModal.js";
const TableContainer = styled.div`
  label: TableContainer;
  display: flex;
  flex: 1;
  width: calc(100% + 40px);
  margin-left: -20px;
  overflow-x: scroll;
`;
const TableScrollableContainer = styled.div`
  label: TableScrollableContainer;
  flex: 1;
  display: flex;
  width: ${({width}) => width}px;
`;
const DescriptionContainer = styled.div`
  label: DescriptionContainer;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical; 
  overflow: hidden;
  position: relative;
`;
const Td = styled.div`
  label: Td;
  max-width: 500px;
  white-space: break-spaces;
  border-right: 1px solid rgba(0,0,0,0.05);
  padding-right: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  flex: 1;

  ${({changed}) => changed ? `
    background-color: ${YELLOW.alpha(0.4).toString()};
  ` : ""}
`;
;
export const MappingReviewTable = ({
  columns,
  groupings,
  search = "",
  mlData
}) => {
  const head = useMemo(() => columns.map(({label}, key) => label), [columns]);
  const rows = useMemo(() => groupings.map(([key, grouping]) => {
    return columns.map(({id, label, content}, columnKey) => {
      const value = content({key, grouping});
      const {changed, originalValue} = getIsChanged(id, grouping);
      const title = changed ? `Original Value: ${originalValue}` : void 0;
      if (id === "description") {
        return /* @__PURE__ */ React.createElement(Td, {
          title,
          changed,
          key: columnKey
        }, /* @__PURE__ */ React.createElement(DescriptionContainer, null, search !== "" ? /* @__PURE__ */ React.createElement(Highlight, {
          value,
          search
        }) : value), /* @__PURE__ */ React.createElement(DescriptionModal, {
          label,
          value,
          id: `${grouping.index}`
        }));
      }
      return /* @__PURE__ */ React.createElement(Td, {
        title,
        changed,
        key: columnKey
      }, value);
    });
  }), [mlData, groupings]);
  const widths = useMemo(() => columns.map((col) => col.width || 500), [columns]);
  const totalWidth = useMemo(() => widths.reduce((sum, width) => sum + width, 0), [widths]);
  return /* @__PURE__ */ React.createElement(TableContainer, null, /* @__PURE__ */ React.createElement(TableScrollableContainer, {
    width: totalWidth
  }, /* @__PURE__ */ React.createElement(Table, {
    head,
    rowSize: 100,
    rows,
    widths
  })));
};
