import React from "../../../../_snowpack/pkg/react.js";
import styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import {Link, useRouteMatch} from "../../../../_snowpack/pkg/react-router-dom.js";
import {constants} from "../../../../dist/lib/index.js";
const {
  BLUE
} = constants;
const ButtonLink = styled(Link)`
  label: ButtonLink;
  padding: 6px 20px;
  background: ${BLUE.toString()};
  color: white;
  border-radius: 8px;
`;
export const UploadSOVButton = ({
  children = "Upload SOV",
  routes
}) => {
  const {url} = useRouteMatch();
  return /* @__PURE__ */ React.createElement(ButtonLink, {
    to: `/${url.split("/").filter(Boolean).slice(0, -1).join("/")}/${routes.upload}`
  }, children);
};
