import React from "../../../_snowpack/pkg/react.js";
import styled from "../../../_snowpack/pkg/@emotion/styled.js";
import {Link as ReactRouterLink} from "../../../_snowpack/pkg/react-router-dom.js";
const StyledLink = styled(ReactRouterLink)`
  label: StyledLink;

  ${({disabled}) => disabled ? `
    pointer-events: none;
  ` : ""}
`;
export const Link = ({
  href,
  className,
  children,
  disabled
}) => /* @__PURE__ */ React.createElement(StyledLink, {
  disabled,
  className,
  to: href
}, children);
