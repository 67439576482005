import React from "../../../../_snowpack/pkg/react.js";
import styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import {GrFormClose} from "../../../../_snowpack/pkg/react-icons/gr.js";
import {useState} from "../../../../_snowpack/pkg/react.js";
import {useHistory} from "../../../../_snowpack/pkg/react-router-dom.js";
import {useCallback} from "../../../../_snowpack/pkg/react.js";
const Container = styled.div`
  label: Container;
  z-index: 1;
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: rgba(0,0,0,0.2);
`;
const Inner = styled.div`
  label: Inner;
  background-color: #f9f4b8;
  position: relative;
  padding: 50px 60px;
  font-size: 20px;
  color: rgba(0,0,0,0.8);
  margin-top: -15%;
  width: 920px;
  box-shadow: 0 2px 2px rgba(0,0,0,0.2);
  border-radius: 5px;

  p {
    margin: 10px 0px 20px 0px;
  }

  a {
    color: blue;
    text-decoration: underline;
  }

  hr {
    margin: 40px 0;
    padding: 0;
    border: none;
    border-bottom: 1px solid rgba(0,0,0,0.4);
  }
`;
const CloseButton = styled.div`
  label: CloseButton;
  position: absolute;
  top: -13px;
  right: -13px;
  cursor: pointer;
  padding: 5px;
  border-radius: 20px;
  z-index: 100;
  background-color: #fffcd6;
  box-shadow: 0 1px 2px rgba(0,0,0,0.2);

  &:hover {
    background-color: #9ed5f7;
  }
`;
const EXPIRATION_TIME_IN_SECONDS = 60 * 60 * 24 * 7;
const MESSAGE_KEY = "alpha-closed-migration-message";
let defaultsToOpen = true;
try {
  const item = localStorage.getItem(MESSAGE_KEY);
  if (item) {
    const timeElapsed = (new Date().getTime() - parseInt(item, 10)) / 1e3;
    console.log("time elapsed since last message", timeElapsed);
    defaultsToOpen = timeElapsed > EXPIRATION_TIME_IN_SECONDS;
  }
} catch (err) {
}
export const MigrationMessage = ({env}) => {
  const [open, setOpen] = useState(defaultsToOpen);
  const setClosed = useCallback(() => {
    setOpen(false);
    localStorage[MESSAGE_KEY] = new Date().getTime();
  }, []);
  const {location} = useHistory();
  if (env === "testing" && open) {
    return /* @__PURE__ */ React.createElement(Container, null, /* @__PURE__ */ React.createElement(Inner, null, /* @__PURE__ */ React.createElement(CloseButton, null, /* @__PURE__ */ React.createElement(GrFormClose, {
      onClick: setClosed
    })), /* @__PURE__ */ React.createElement("p", null, "Hello,"), /* @__PURE__ */ React.createElement("p", null, "We've migrated these tools to ", /* @__PURE__ */ React.createElement("strong", null, "Alpha | Staging"), " at ", /* @__PURE__ */ React.createElement("a", {
      href: `https://alpha.staging.onarchipelago.com${location.pathname}`
    }, "https://alpha.staging.onarchipelago.com"), "."), /* @__PURE__ */ React.createElement("p", null, "We encourage everyone to start using the tools on ", /* @__PURE__ */ React.createElement("strong", null, "Alpha | Staging"), " going forward. The new site at will provide better stability and more robust infrastructure, and the tools should work exactly the same."), /* @__PURE__ */ React.createElement("hr", null), /* @__PURE__ */ React.createElement("p", null, "If you need to continue using ", /* @__PURE__ */ React.createElement("strong", null, "Alpha | Testing"), " you may close this message by clicking the button in the top right."), /* @__PURE__ */ React.createElement("p", null, "If you have any problems or concerns, please post them in the ", /* @__PURE__ */ React.createElement("a", {
      href: "https://archipelago-analytics.slack.com/archives/CQK0LGQDN",
      target: "_blank"
    }, "#ml channel on Slack"), ".")));
  }
  return null;
};
