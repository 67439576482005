import * as __SNOWPACK_ENV__ from '../../_snowpack/env.js';
import.meta.env = __SNOWPACK_ENV__;

console.log("♖");
import React from "../../_snowpack/pkg/react.js";
import ReactDOM from "../../_snowpack/pkg/react-dom.js";
import Config from "./Config.js";
import App from "./containers/App/App.js";
import stories from "../../stories/index.js";
ReactDOM.render(/* @__PURE__ */ React.createElement(Config, null, /* @__PURE__ */ React.createElement(App, {
  stories
})), document.getElementById("root"));
if (undefined /* [snowpack] import.meta.hot */ ) {
  undefined /* [snowpack] import.meta.hot */ .accept();
}
