import React, {useMemo} from "../../../../../../../_snowpack/pkg/react.js";
import styled from "../../../../../../../_snowpack/pkg/@emotion/styled.js";
import {constants} from "../../../../../../../dist/lib/index.js";
const {
  BLUE
} = constants;
const MLTableContainer = styled.div`
  label: MLTableContainer;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 0;
  overflow: scroll;
`;
const StyledMLTable = styled.table`
  label: StyledMLTable;
  font-size: 10px;
  width: 100%;

  thead {
    th {
      // background: ${BLUE.lighten(0.78).toString()};
      background-color: #EEE;
    }
  }

  th, td {
    padding: 2px;
  }

  td {
    max-width: 200px;
  }
`;
export const MLTable = ({data}) => {
  const sortedResults = useMemo(() => Object.entries(data).sort(([_1, a], [_2, b]) => {
    return b - a;
  }), [data]);
  return /* @__PURE__ */ React.createElement(MLTableContainer, null, /* @__PURE__ */ React.createElement(StyledMLTable, null, /* @__PURE__ */ React.createElement("thead", null, /* @__PURE__ */ React.createElement("tr", null, /* @__PURE__ */ React.createElement("th", null, "Suggestion"), /* @__PURE__ */ React.createElement("th", null, "Confidence"))), /* @__PURE__ */ React.createElement("tbody", null, sortedResults.map((row, key) => /* @__PURE__ */ React.createElement("tr", {
    key
  }, /* @__PURE__ */ React.createElement("td", null, row[0]), /* @__PURE__ */ React.createElement("td", {
    style: {width: "20%"}
  }, Math.round(Number(row[1]) * 100), "%"))))));
};
