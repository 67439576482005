import React, {useState, useEffect} from "../../../_snowpack/pkg/react.js";
import styled from "../../../_snowpack/pkg/@emotion/styled.js";
import {BLUE} from "../constants.js";
import _Pacman from "../../../_snowpack/pkg/react-spinners/PacmanLoader.js";
import BarLoader from "../../../_snowpack/pkg/react-spinners/BarLoader.js";
import CircularProgress from "../../../_snowpack/pkg/@material-ui/core/CircularProgress.js";
export const LoadingContainer = styled.div`
  label: LoadingContainer;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition-duration: 0.5s;
  opacity: ${({visible}) => visible ? 1 : 0};

  svg {
    color: ${({color}) => color};
  }
`;
const Pacman = styled(_Pacman)`
  label: Pacman;
  > div {
    display: block;
  }
`;
const Children = styled.div`
  label: Children;
  margin-top: 20px;
`;
const LoadingSymbol = ({
  size = 50,
  color = BLUE.toString(),
  type,
  fadeIn = 2e3,
  children,
  label
}) => {
  const [visible, setVisible] = useState(!fadeIn);
  useEffect(() => {
    if (!label) {
      console.warn([
        "Strongly consider supplying <Loading /> with a label element.",
        "The label is shown for long-loading content, which can be helpful for diagnosing the offending code."
      ].join("\n"));
    }
  }, [label]);
  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(true);
    }, fadeIn);
    return () => clearTimeout(timer);
  }, []);
  if (type === "pacman") {
    return /* @__PURE__ */ React.createElement(Container, {
      color,
      visible,
      title: label
    }, /* @__PURE__ */ React.createElement(Pacman, {
      size,
      color
    }), children && /* @__PURE__ */ React.createElement(Children, null, children));
  }
  if (type === "bar") {
    return /* @__PURE__ */ React.createElement(Container, {
      color,
      visible,
      title: label
    }, /* @__PURE__ */ React.createElement(BarLoader, {
      width: size,
      color
    }), children && /* @__PURE__ */ React.createElement(Children, null, children));
  }
  return /* @__PURE__ */ React.createElement(Container, {
    color,
    visible,
    title: label
  }, /* @__PURE__ */ React.createElement(CircularProgress, {
    size
  }), children && /* @__PURE__ */ React.createElement(Children, null, children));
};
export const Loading = ({flex, ...props}) => {
  if (flex) {
    return /* @__PURE__ */ React.createElement(LoadingContainer, null, /* @__PURE__ */ React.createElement(LoadingSymbol, {
      ...props
    }));
  }
  return /* @__PURE__ */ React.createElement(LoadingSymbol, {
    ...props
  });
};
