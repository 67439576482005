import React, {useState, useEffect, useMemo} from "../../../_snowpack/pkg/react.js";
import styled from "../../../_snowpack/pkg/@emotion/styled.js";
import {useDownloadFile, Loading, Button, useS3, constants} from "../../../dist/lib/index.js";
import {FiDownload} from "../../../_snowpack/pkg/react-icons/fi.js";
const Container = styled.div`
  label: Properties;
  flex: 1;
  display: flex;
  flex-direction: column;
  transition-duration: .5s;
  opacity: ${({visible}) => visible ? "1" : "0"};
  padding: 10px 0 0px 0;
  color: #333;

  h1 {
    font-size: 18px;
    margin: 0;
    padding: 0 10px;
    color: ${constants.BLUE.toString()};
  }
`;
const Header = styled.div`
  label: Header;
  display: flex;
`;
const Right = styled.div`
  label: Right;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
`;
const Name = styled.div`
  label: Name;
  padding: 0 10px;
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 18px;
    margin: 0;
    padding: 0;
  }
`;
export const InvocationPagePropertiesContainer = ({data, download, name, children, sheetURL}) => {
  const [getContent] = useS3(sheetURL);
  const [downloadFile] = useDownloadFile({getContent});
  const [visible, setVisible] = useState(false);
  const total = useMemo(() => data?.length || 0, [data]);
  useEffect(() => {
    if (data && total > 0 && visible === false) {
      const timer = setTimeout(() => {
        setVisible(true);
      }, 1);
      return () => clearTimeout(timer);
    }
  }, [data, total, visible]);
  if (!data || !total) {
    return /* @__PURE__ */ React.createElement(Loading, {
      label: "Loading invocations for page",
      flex: true
    });
  }
  const processed = data.filter((d) => d.completed);
  return /* @__PURE__ */ React.createElement(Container, {
    visible
  }, /* @__PURE__ */ React.createElement(Header, null, name && /* @__PURE__ */ React.createElement(Name, null, /* @__PURE__ */ React.createElement("h1", null, /* @__PURE__ */ React.createElement("a", {
    onClick: () => downloadFile({name}),
    target: "_blank"
  }, name))), processed.length, " / ", total, /* @__PURE__ */ React.createElement(Right, null, download && /* @__PURE__ */ React.createElement(Button, {
    leftIcon: /* @__PURE__ */ React.createElement(FiDownload, null),
    onClick: download,
    disabled: !data
  }, "Export Spreadsheet"))), children);
};
