import {useCallback} from "../../../_snowpack/pkg/react.js";
import {buildQueryString} from "./internals/buildQueryString.js";
import {prepareFile} from "./internals/prepareFile.js";
import {prepareFileForPut} from "./internals/prepareFileForPut.js";
import {prepareGetContent} from "./internals/prepareGetContent.js";
import {uploadOrEditFile} from "./internals/uploadOrEditFile.js";
import {
  RequestError,
  useBackend
} from "./internals/useBackend.js";
export const useFile = (key, {bucket} = {}) => {
  const [request] = useBackend();
  if (!key) {
    throw new Error("You must provide a key to useFile");
  }
  if (typeof key !== "string") {
    throw new Error("You must provide a valid string key to useFile");
  }
  const getContent = useCallback(prepareGetContent(request, key, bucket), [
    key,
    bucket
  ]);
  const getFile = useCallback(async ({defaultValue, name} = {}, params = {}) => {
    try {
      const resp = await request({
        url: `files/${key}?${buildQueryString({
          bucket
        })}`
      }, params);
      const data = await resp.json();
      return prepareFile(data, getContent);
    } catch (err) {
      if (err instanceof RequestError && defaultValue !== void 0 && err.status === 404) {
        const contents = prepareFileForPut(defaultValue, {
          name: name || key
        });
        return await editFile(contents);
      } else {
        throw err;
      }
    }
  }, [request, key, bucket]);
  const defaultName = key.split("/").pop();
  if (!defaultName) {
    throw new Error("Invalid key provided. It appears empty.");
  }
  const editFile = useCallback(async (file, uploadOpts) => {
    return await uploadOrEditFile(request, prepareFileForPut(file, uploadOpts), {
      key,
      bucket,
      getContent
    }, uploadOpts);
  }, [request, getContent, key, bucket]);
  return [getFile, editFile];
};
