import intro from "./intro/intro.js";
import distanceToNearestBuilding from "./onboarding/distanceToNearestBuilding/index.js";
import hazards from "./onboarding/hazards/index.js";
import ingest from "./onboarding/verify/index.js";
const stories = [
  {
    content: intro,
    title: "Intro"
  },
  {
    title: "Onboarding",
    stories: [
      hazards,
      ingest,
      distanceToNearestBuilding
    ]
  }
];
export default stories;
