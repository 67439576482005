import {useSOVAppInvocation} from "./useSOVAppInvocation.js";
export function SOVAppInvocationPage({
  children,
  gizmoName,
  match: {
    params: {
      id
    }
  }
}) {
  const [invocation] = useSOVAppInvocation(gizmoName, id);
  return children({
    invocation
  });
}
