import React, {useMemo} from "../../../../_snowpack/pkg/react.js";
import {format} from "../../../../_snowpack/pkg/date-fns.js";
import styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import {Loading} from "../../../../dist/lib/index.js";
import {UploadSOVButton} from "./UploadSOVButton.js";
const Container = styled.div`
  label: Container;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;
const Header = styled.div`
  label: Header;
  display: flex;
  padding: 20px 20px 0 20px;
  margin-bottom: 10px;

  h1 {
    margin: 0;
    padding: 0;
  }
`;
const Controls = styled.div`
  label: Controls;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  input {
    padding: 5px 10px;
    font-size: 16px;
    min-width: 250px;
  }
`;
const SearchContainer = styled.div`
  label: SearchContainer;
  position: relative;
  margin-right: 10px;
`;
const SearchLoading = styled.div`
  label: SearchLoading;
  position: absolute;
  right: 5px;
  top: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TableWrapper = styled.div`
  label: TableWrapper;
  flex: 1;
  display: flex;
`;
const Title = styled.div`
  label: Title;
  display: flex;
  flex-direction: column;

  h1 {
    margin-bottom: 0;
  }
`;
const Refreshed = styled.span`
  label: Refreshed;
  font-size: 10px;
  color: rgba(0,0,0,0.4);
  margin-top: -6px;
`;
const SEARCH_TIME = 400;
export function SOVAppInvocationsTableContainer({
  search,
  setSearch,
  waitingToSearch,
  children,
  showSearch,
  routes,
  filters,
  refreshed,
  showUpload = true
}) {
  const refreshedAtTime = useMemo(() => refreshed ? format(refreshed, "MMM do h:mm:ss a") : void 0, [refreshed]);
  return /* @__PURE__ */ React.createElement(Container, null, /* @__PURE__ */ React.createElement(Header, null, /* @__PURE__ */ React.createElement(Title, null, /* @__PURE__ */ React.createElement("h1", null, "SOV Uploads"), /* @__PURE__ */ React.createElement(Refreshed, null, refreshedAtTime ? `List updated ${refreshedAtTime}` : null)), /* @__PURE__ */ React.createElement(Controls, null, filters, showSearch && /* @__PURE__ */ React.createElement(SearchContainer, null, /* @__PURE__ */ React.createElement("input", {
    defaultValue: search || "",
    type: "text",
    placeholder: "Search for a run",
    onChange: (e) => setSearch(e.target.value)
  }), waitingToSearch && /* @__PURE__ */ React.createElement(SearchLoading, null, /* @__PURE__ */ React.createElement(Loading, {
    label: "Searching",
    size: 14,
    fadeIn: SEARCH_TIME
  }))), showUpload && /* @__PURE__ */ React.createElement(UploadSOVButton, {
    routes
  }))), /* @__PURE__ */ React.createElement(TableWrapper, null, children));
}
;
