import {useCallback} from "../../../../_snowpack/pkg/react.js";
export function useUpdateAttributes(gizmo, request) {
  const getUpdateAttributesFn = useCallback((name) => {
    const updateAttributes = async (attbs, requestParams = {}) => {
      if (!gizmo) {
        throw new Error("gizmo namespace cannot be undefined.");
      }
      const resp = await request({
        url: `gizmos/${gizmo}/invocations/${name}`,
        params: {
          method: "POST",
          body: {
            attributes: attbs
          }
        }
      }, requestParams);
      await resp.json();
    };
    return updateAttributes;
  }, [gizmo, request]);
  return [getUpdateAttributesFn];
}
