import React from "../../../../_snowpack/pkg/react.js";
import styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import {Button, Upload} from "../../../../dist/lib/index.js";
import {Spinner} from "../../../../_snowpack/pkg/@chakra-ui/react.js";
import {MdCloudUpload} from "../../../../_snowpack/pkg/react-icons/md.js";
import {useUploadSpreadsheet} from "./useUploadSpreadsheet.js";
const Container = styled.div`
  label: UploadContainer;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
`;
const UploadContainer = styled.div`
  label: UploadContainer;
  display: flex;
  flex: 1;
`;
const SpreadsheetName = styled.div`
  label: SpreadsheetName;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
`;
const SubmitButtonContainer = styled.div`
  label: SubmitButtonContainer;
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
`;
export function UploadSpreadsheet({
  children,
  uploadButtonText,
  pebble,
  params,
  disabled = false,
  gizmoName,
  routes
}) {
  const {submitting, file, uploadSpreadsheet, runAutomation} = useUploadSpreadsheet(pebble, params, routes, gizmoName);
  return /* @__PURE__ */ React.createElement(Container, null, /* @__PURE__ */ React.createElement(UploadContainer, null, file ? /* @__PURE__ */ React.createElement(SpreadsheetName, null, file.name) : /* @__PURE__ */ React.createElement(Upload, {
    upload: uploadSpreadsheet
  }, children)), /* @__PURE__ */ React.createElement(SubmitButtonContainer, null, /* @__PURE__ */ React.createElement(Button, {
    isLoading: submitting,
    disabled: !file || disabled || submitting,
    onClick: runAutomation,
    leftIcon: /* @__PURE__ */ React.createElement(MdCloudUpload, null),
    spinner: /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Spinner, {
      mr: 4
    }), " Uploading")
  }, uploadButtonText)));
}
;
