import {useCallback, useEffect, useRef, useState} from "../../../_snowpack/pkg/react.js";
import {useS3} from "./useS3.js";
const useAnchorTag = () => {
  const anchor = useRef(document.createElement("a"));
  useEffect(() => {
    const c = anchor.current;
    if (c) {
      document.body.appendChild(c);
      c.setAttribute("style", "display: none;");
      return () => {
        document.body.removeChild(c);
      };
    }
  }, [anchor]);
  return [anchor];
};
const download = (a, contents, name) => {
  const url = window.URL.createObjectURL(contents);
  a.href = url;
  if (name) {
    a.download = name;
  }
  a.click();
  window.URL.revokeObjectURL(url);
};
export const useDownloadFile = ({
  getContent,
  key,
  bucket
} = {}) => {
  const [loading, setLoading] = useState(false);
  const [_genericGetContent] = useS3(key || "");
  const localGetContent = getContent || _genericGetContent;
  const [anchor] = useAnchorTag();
  const downloadFile = useCallback(async ({name, key: key2, bucket: bucket2} = {}) => {
    const a = anchor.current;
    if (a) {
      setLoading(true);
      const contents = await localGetContent({format: "raw", key: key2, bucket: bucket2});
      download(a, contents, name);
      setLoading(false);
    } else {
      throw new Error("No anchor element found");
    }
  }, [anchor, localGetContent]);
  return [downloadFile, loading];
};
