import {BLUE} from "../../constants.js";
import {extendTheme} from "../../../../_snowpack/pkg/@chakra-ui/react.js";
export const theme = extendTheme({
  colors: {
    blue: {
      ...[900, 800, 700, 600, 500, 400, 300, 200, 100, 50].reduce((obj, weight) => ({
        ...obj,
        [weight]: BLUE.toString()
      }), {})
    }
  },
  components: {
    Button: {
      variants: {
        outline: {
          border: "2px solid"
        }
      }
    }
  }
});
