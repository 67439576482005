import {useCallback} from "../../../../_snowpack/pkg/react.js";
import {useBackend} from "./useBackend.js";
import {prepareKeysForServer} from "./prepareKeysForServer.js";
import {prepareUser} from "./prepareUser.js";
export const useActiveUsers = () => {
  const [request] = useBackend();
  const listActiveUsers = useCallback(async ({lastActiveMinutesAgo = 15, ...opts} = {}, requestParams = {}) => {
    if (opts.perPage !== void 0 && opts.perPage > 100) {
      console.warn("Maximum perPage value is 100.");
    }
    const params = prepareKeysForServer({
      ...opts,
      last_activity: lastActiveMinutesAgo
    });
    const resp = await request({
      url: `users?${params}`
    }, requestParams);
    const data = await resp.json();
    const users = data.map((d) => prepareUser(d));
    return users.sort(({lastActivity: a}, {lastActivity: b}) => b.getTime() - a.getTime());
  }, [request]);
  return [listActiveUsers];
};
