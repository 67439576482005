import React, {createContext, useState, useRef, useContext} from "../../../../../_snowpack/pkg/react.js";
import {ErrorBoundary} from "../../../components/ErrorBoundary.js";
import styled from "../../../../../_snowpack/pkg/@emotion/styled.js";
import clipboardCopy from "../../../../../_snowpack/pkg/clipboard-copy.js";
import PreviewMenu from "./PreviewMenu.js";
import {useLocation} from "../../../../../_snowpack/pkg/react-router-dom.js";
import ManagerContext from "../ManagerContext.js";
import {
  useParams
} from "./utils.js";
import Routes from "./Routes.js";
import {getURLFromTitle} from "../../App/utils.js";
export const MLAlphaContext = createContext({});
const PreviewContainer = styled.div`
  label: PreviewContainer;
  background: white;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
  position: relative;

`;
const PreviewInnerContainer = styled.div`
  label: PreviewInnerContainer;
  flex: 1;
  background: white;
  border-radius: 4px;
  box-shadow: 0 1px 5px 0 rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
const Container = styled.div`
  label: Container;
  display: flex;
  flex-direction: column;
  transition-duration: 0.2s;
  height: 100%;

  ${({isFullscreen}) => {
  if (isFullscreen) {
    return `
        left: 0px;
        width: calc(100%);
        padding: 0;
      `;
  }
  return `
      left: 210px;
      width: calc(100% - 210px);
      padding: 10px 10px 10px 0;
    `;
}}
  position: absolute;
`;
export const getMatchingStoryTree = (slugs, stories) => {
  const slug = slugs.shift();
  for (let i = 0; i < stories.length; i++) {
    const story = stories[i];
    const url = getURLFromTitle(story.title);
    if (slugs.length > 0) {
      if ("stories" in story && url === slug) {
        return [
          story,
          ...getMatchingStoryTree(slugs, story.stories)
        ];
      }
    } else if ("content" in story && url === slug) {
      return [story];
    }
  }
  return [];
};
export const getMatchingStory = (slugs, stories) => {
  const slug = slugs.shift();
  for (let i = 0; i < stories.length; i++) {
    const story = stories[i];
    const url = getURLFromTitle(story.title);
    if (slugs.length > 0) {
      if ("stories" in story && url === slug) {
        return getMatchingStory(slugs, story.stories);
      }
    } else if ("content" in story && url === slug) {
      return story;
    }
  }
  return void 0;
};
export const Preview = ({
  stories
}) => {
  const location = useLocation();
  const url = location.pathname;
  const storyConfiguration = getMatchingStory(url.split("/").filter(Boolean), stories);
  const gizmoPage = useRef(null);
  const {update, navigate} = useContext(ManagerContext);
  const params = useParams();
  const [isFullscreen, setIsFullscreen] = useState(false);
  const makeFullscreen = () => setIsFullscreen(!isFullscreen);
  const src = `/gizmos/${url}#${location.search.split("?").pop()}`;
  const copyURL = () => clipboardCopy(`${window.location.origin}${src}`);
  const scrollPage = (y, x = 0) => {
    if (gizmoPage.current) {
      gizmoPage.current.scrollTo(x, y);
    } else {
      console.warn("A reference to the container page has not been set up yet.");
    }
  };
  return /* @__PURE__ */ React.createElement(MLAlphaContext.Provider, {
    value: {
      params,
      update,
      navigate,
      gizmoConfiguration: storyConfiguration,
      scrollPage
    }
  }, /* @__PURE__ */ React.createElement(Container, {
    isFullscreen
  }, /* @__PURE__ */ React.createElement(Routes, {
    stories,
    render: (props, story) => /* @__PURE__ */ React.createElement(PreviewInnerContainer, null, /* @__PURE__ */ React.createElement(PreviewMenu, {
      story,
      copyURL,
      makeFullscreen,
      isFullscreen
    }), /* @__PURE__ */ React.createElement(PreviewContainer, {
      "data-testid": "content",
      ref: gizmoPage
    }, /* @__PURE__ */ React.createElement(ErrorBoundary, null, /* @__PURE__ */ React.createElement(story.content, {
      ...props,
      scrollPage,
      update,
      navigate,
      params
    }))))
  })));
};
