import {buildQueryString} from "./buildQueryString.js";
import {AlphaError} from "./AlphaError.js";
export function prepareGetContent(request, key, bucket) {
  const getContent = async ({
    format,
    key: _key,
    bucket: _bucket,
    ...requestParams
  } = {}) => {
    const localKey = _key || key;
    const localBucket = _bucket || bucket;
    if (!localKey) {
      throw new Error('You have failed to provide a valid key for download. Please provide one either when instantiating "getContent" or when calling the function.');
    }
    const parsedFormat = format || inferFormat(localKey);
    try {
      const resp = await request({
        url: `file_content/${localKey}?${buildQueryString({
          bucket: localBucket
        })}`
      }, requestParams);
      if (resp.status >= 400) {
        const text = await resp.text();
        throw new AlphaError(text, resp.status);
      }
      if (parsedFormat === "json") {
        return await resp.json();
      }
      if (parsedFormat === "text") {
        return await resp.text();
      }
      if (parsedFormat === "sqlite") {
        const buff = await resp.arrayBuffer();
        return new Uint8Array(buff);
      }
      return await resp.blob();
    } catch (err) {
      if (err.status !== 401 && err.status !== 404) {
        debugger;
      }
      throw err;
    }
  };
  return getContent;
}
const inferFormat = (name) => {
  const ext = name.split(".").pop() || "";
  if (ext === "sqlite") {
    return "sqlite";
  }
  if (ext === "json") {
    return "json";
  }
  if (ext === "txt") {
    return "text";
  }
  if (["gif", "bmp", "jpg", "jpeg", "png"].includes(ext)) {
    return "image";
  }
  return "raw";
};
