import React, {useMemo, useEffect, useState, useContext, useCallback} from "../../../../_snowpack/pkg/react.js";
import styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import {Sidebar} from "./Sidebar/Sidebar.js";
import {Preview} from "./Preview/Preview.js";
import {useHistory} from "../../../../_snowpack/pkg/react-router-dom.js";
import {Helmet, HelmetProvider} from "../../../../_snowpack/pkg/react-helmet-async.js";
import ManagerContext from "./ManagerContext.js";
import {getMatchingStoryTree} from "./Preview/Preview.js";
import {AuthContext} from "../App/Auth/Auth.js";
import {Modal} from "../../components/Modal.js";
import {MigrationMessage} from "./MigrationMessage.js";
import {ConfigContext} from "../../Config.js";
const Title = styled.h1`
  label: Title;
  font-size: 48px;
`;
const TokenExpired = styled.div`
  label: TokenExpired;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const convertQueryParams = (queryParams = {}) => {
  const qs = Object.entries(queryParams).map(([key, val]) => {
    if (typeof val !== "string" && typeof val !== "number") {
      let stringifiedObject;
      try {
        stringifiedObject = JSON.stringify(val);
      } catch (err) {
        stringifiedObject = "[object Object]";
      }
      console.warn([
        "You're passing a non-string/number to navigate:",
        "",
        stringifiedObject,
        "",
        "This will be serialized to [Object object], which is probably not want you want. Consider serializing the object before passing it to navigate()."
      ].join("\n"));
    }
    return `${key}=${val}`;
  }).join("&");
  return qs;
};
const getURLSearchParamsAsObj = (urlLocation) => {
  const location2 = urlLocation.split(/^\?/).pop() || "";
  if (location2 === "") {
    return {};
  }
  const splitLocation = location2.split("&").map((pair) => pair.split("="));
  return splitLocation.reduce((obj, [key, val]) => ({
    ...obj,
    [key]: val
  }), {});
};
const getSearch = (params) => Object.entries(params).reduce((arr, [key, val]) => {
  return arr.concat(`${key}=${val}`);
}, []).join("&");
const Manager = ({stories}) => {
  const history = useHistory();
  const urlParams = useMemo(() => getURLSearchParamsAsObj(location.search), [location.search]);
  const {
    ENV
  } = useContext(ConfigContext);
  const [toUpdate, nextToUpdate] = useState();
  const update = useCallback((data, options) => {
    const _toUpdate = {
      data: Object.entries(data).reduce((obj, [key, val]) => {
        if (!key) {
          return obj;
        }
        return {
          ...obj,
          [key]: val ? encodeURIComponent(val) : val
        };
      }, {}),
      options
    };
    nextToUpdate(_toUpdate);
  }, []);
  useEffect(() => {
    if (toUpdate) {
      const {data, options} = toUpdate;
      if (data.path) {
        updateURL(data, options);
      } else {
        updateURL({
          ...urlParams,
          ...data
        }, options);
      }
    }
  }, [toUpdate]);
  const {expired} = useContext(AuthContext);
  const updateURL = useCallback((params, {
    replace
  } = {
    replace: false
  }) => {
    const search = `?${getSearch(params)}`;
    if (replace) {
      history.replace({
        pathname: location.pathname,
        search
      });
    } else {
      history.push({
        pathname: location.pathname,
        search
      });
    }
  }, [history]);
  const slugs = window.location.pathname.split("/");
  const matchingStories = getMatchingStoryTree(slugs, stories);
  const title = useMemo(() => [
    ...matchingStories.reverse().map((story) => story.title),
    "Alpha",
    "Archipelago"
  ].join(" | "), [matchingStories]);
  const navigate = useCallback(async (pathname, options) => {
    const search = convertQueryParams(options);
    history.push({
      pathname,
      search: search ? `?${search}` : void 0
    });
  }, [history]);
  return /* @__PURE__ */ React.createElement(HelmetProvider, null, /* @__PURE__ */ React.createElement(ManagerContext.Provider, {
    value: {
      navigate,
      update
    }
  }, /* @__PURE__ */ React.createElement(Helmet, null, /* @__PURE__ */ React.createElement("title", null, title)), expired && /* @__PURE__ */ React.createElement(ExpiredModal, null), /* @__PURE__ */ React.createElement(MigrationMessage, {
    env: ENV
  }), /* @__PURE__ */ React.createElement(Sidebar, {
    stories
  }), /* @__PURE__ */ React.createElement(Preview, {
    stories
  })));
};
export default Manager;
const ExpiredModal = () => /* @__PURE__ */ React.createElement(Modal, {
  allowClose: false,
  style: {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: 40,
      overflow: "inherit"
    }
  }
}, () => /* @__PURE__ */ React.createElement(TokenExpired, null, /* @__PURE__ */ React.createElement(Title, null, "Token Expired"), /* @__PURE__ */ React.createElement("div", null, "Your token is expired. Reload the page to refresh it. (You may have to re-login.)")));
