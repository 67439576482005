import React, {useMemo} from "../../../../../../../_snowpack/pkg/react.js";
import {Loading} from "../../../../../../../dist/lib/index.js";
import styled from "../../../../../../../_snowpack/pkg/@emotion/styled.js";
import {MappingReviewModal} from "../MappingReviewModal.js";
import {useOrganizeMLData} from "./useMLData.js";
import {MLTable} from "./MLTable.js";
const MappingReviewModalBody = styled.div`
  label: MappingReviewModalBody;
  display: flex;
  flex: 1;
  flex-direction: column;
`;
const Title = styled.h1`
  label: Title;
  font-size: 36px;
  color: rgba(0,0,0,0.8);
  margin-bottom: 40px;
`;
const SubTitle = styled.h2`
  label: SubTitle;
  font-size: 20px;
  color: rgba(0,0,0,0.6);
  margin: 20px 0;
`;
export const MLModal = ({
  tab,
  id,
  data,
  properties
}) => {
  const {cellValue, sovAttbs} = useOrganizeMLData(data);
  const propertiesObj = useMemo(() => properties.filter(Boolean).reduce((obj, property) => ({
    ...obj,
    [property.index]: property
  }), {}), [properties]);
  return /* @__PURE__ */ React.createElement(MappingReviewModal, {
    id: `view-ml-${tab}-${id}`
  }, ({visible}) => visible ? /* @__PURE__ */ React.createElement(MappingReviewModalBody, null, /* @__PURE__ */ React.createElement(Title, null, "ML Results"), /* @__PURE__ */ React.createElement(SubTitle, null, "Cell Value"), /* @__PURE__ */ React.createElement(MLModalTable, {
    data: cellValue
  }), /* @__PURE__ */ React.createElement(SubTitle, null, "SOV Attributes"), Object.entries(sovAttbs).map(([sovAttbKey, value]) => {
    const {fn, index} = JSON.parse(sovAttbKey);
    const property = propertiesObj[index];
    if (!property) {
      return /* @__PURE__ */ React.createElement(Loading, {
        flex: true,
        fadeIn: 0,
        label: `Loading SOV Attributes for property index ${index}`
      });
    }
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(PropertyOverview, {
      attbs: property
    }), /* @__PURE__ */ React.createElement(MLModalTable, {
      data: value
    }));
  })) : void 0);
};
const MLModalTable = ({
  data
}) => {
  if (!data) {
    return /* @__PURE__ */ React.createElement(Loading, {
      flex: true,
      label: "Loading ML Suggestion",
      size: 16
    });
  }
  if (data?.errors?.length) {
    return /* @__PURE__ */ React.createElement("p", null, JSON.stringify(data.errors));
  }
  return /* @__PURE__ */ React.createElement(MLTable, {
    data: data?.data
  });
};
const PropertyOverview = ({attbs}) => {
  return /* @__PURE__ */ React.createElement("details", null, /* @__PURE__ */ React.createElement("summary", null, attbs["Client ID.Source"]), /* @__PURE__ */ React.createElement("table", null, /* @__PURE__ */ React.createElement("tbody", null, Object.entries(attbs).map(([key, val]) => /* @__PURE__ */ React.createElement("tr", {
    key
  }, /* @__PURE__ */ React.createElement("td", null, key), /* @__PURE__ */ React.createElement("td", null, val))))));
};
