import React, {useCallback} from "../../../../../../_snowpack/pkg/react.js";
import {constants, Loading} from "../../../../../../dist/lib/index.js";
import styled from "../../../../../../_snowpack/pkg/@emotion/styled.js";
import {MappingReviewBody} from "./MappingReviewBody.js";
import {MappingReviewHeader} from "./MappingReviewHeader.js";
import {useAttributeConfig} from "./hooks/useAttributeConfig.js";
import {useActions} from "./hooks/useActions.js";
import {MappingReviewFooter} from "./MappingReviewFooter.js";
const {
  BLUE
} = constants;
const Container = styled.div`
  label: Container;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 20px;
  max-height: 100%;
`;
const Tabs = styled.ul`
  label: Tabs;
  display: flex;
  list-style: none;
  margin-bottom: 10px;
`;
const Tab = styled.li`
  label: Tab;
  margin-right: 20px;
  font-size: 20px;
  cursor: pointer;
  ${({active}) => active ? `
    border-bottom: 4px solid ${BLUE.toString()};
  ` : ""}

  &:hover {
    color: ${BLUE.toString()};
  }
`;
const getDataForTab = (data, tab) => {
  return data?.[tab] === -1 ? null : data?.[tab];
};
export const MappingReview = ({
  invocation,
  params,
  update
}) => {
  const [attributeConfig, version] = useAttributeConfig();
  const setCurrentTab = useCallback((tab2) => () => {
    update({
      tab: tab2
    });
  }, [update]);
  const {
    canSubmitChanges,
    downloadOriginal,
    outputFolder,
    data,
    tab,
    handleChange,
    updating,
    downloadDataFile,
    submitMappings,
    completedAt,
    properties
  } = useActions({invocation, params});
  if (!outputFolder) {
    return /* @__PURE__ */ React.createElement(Container, null, /* @__PURE__ */ React.createElement(Loading, {
      flex: true,
      label: "Waiting for invocation attributes"
    }));
  }
  const {
    client,
    industry,
    business_type
  } = invocation.attributes;
  const dataForTab = getDataForTab(data, tab);
  console.log(version);
  return /* @__PURE__ */ React.createElement(Container, null, /* @__PURE__ */ React.createElement(MappingReviewHeader, {
    version,
    downloadOriginal,
    client,
    industry,
    businessType: business_type,
    updating
  }), /* @__PURE__ */ React.createElement(Tabs, null, /* @__PURE__ */ React.createElement(Tab, {
    active: tab === "construction",
    onClick: setCurrentTab("construction")
  }, "Construction"), /* @__PURE__ */ React.createElement(Tab, {
    active: tab === "occupancy",
    onClick: setCurrentTab("occupancy")
  }, "Occupancy")), /* @__PURE__ */ React.createElement(MappingReviewBody, {
    handleChange,
    params,
    update,
    tab,
    attributeConfig,
    data: dataForTab,
    properties
  }), /* @__PURE__ */ React.createElement(MappingReviewFooter, {
    canSubmitChanges,
    downloadDataFile,
    submitMappings,
    completedAt
  }));
};
