export const prepareKeysForServer = (opts) => Object.entries(opts).map(([key, val]) => [prepareKeyForServer(key), val].join("=")).join("&");
export const prepareKeyForServer = (key) => {
  if (key === "perPage") {
    return "per_page";
  }
  if (key === "sortDir") {
    return "sort_dir";
  }
  return key;
};
