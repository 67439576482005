import React, {useState} from "../../../../../../_snowpack/pkg/react.js";
import {Tooltip} from "../../../../../../dist/lib/index.js";
import styled from "../../../../../../_snowpack/pkg/@emotion/styled.js";
const Container = styled.div`
  label: Container;
  position: relative;
`;
export const MatchTypeTooltip = ({label, description}) => {
  const [over, setOver] = useState(false);
  return /* @__PURE__ */ React.createElement(Container, {
    onMouseOver: () => setOver(true),
    onMouseOut: () => setOver(false)
  }, label, over && /* @__PURE__ */ React.createElement(Tooltip, null, description));
};
