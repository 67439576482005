import React from "../../../../../../_snowpack/pkg/react.js";
import styled from "../../../../../../_snowpack/pkg/@emotion/styled.js";
import {constants} from "../../../../../../dist/lib/index.js";
const {
  BLUE
} = constants;
const MapButtonContainer = styled.div`
  label: MapButton;
  z-index: 1;
  position: relative;
  background: white;
  box-shadow: 0 1px 2px rgba(0,0,0,0.4);
  padding: 5px;
  border-radius: 2px;
  cursor: pointer;
  height: 26px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 5px;

  svg {
    color: rgba(0,0,0,0.6);
    cursor: pointer;
  }

  &:hover {
    svg {
      color: ${BLUE.toString()};
    }
  }
`;
const MapButton = ({
  children,
  handleClick
}) => {
  return /* @__PURE__ */ React.createElement(MapButtonContainer, {
    onClick: handleClick
  }, children);
};
export default MapButton;
export const MapButtons = styled.div`
  z-index: 1;
  label: MapButtons;
  position: absolute;
  width: calc(100% - 10px);
  margin: 5px;
  display: flex;
`;
const NUMBER_OF_RIGHT_BUTTONS = 2;
export const MapButtonLeft = styled.div`
  label: Left;
  display: flex;
  position: absolute;
  left: 0px;
  width: ${({full}) => full ? `calc(100% - ${31 * NUMBER_OF_RIGHT_BUTTONS}px)` : "auto"};
`;
export const MapButtonRight = styled.div`
  label: Right;
  display: flex;
  position: absolute;
  right: 0px;
`;
