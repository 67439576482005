import React, {useCallback} from "../../../../../../_snowpack/pkg/react.js";
import {MatchTypeTooltip} from "./MatchTypeTooltip.js";
import Select from "../../../../../../_snowpack/pkg/react-select.js";
import styled from "../../../../../../_snowpack/pkg/@emotion/styled.js";
import {constants} from "../../../../../../dist/lib/index.js";
import {MLCell} from "./VerifyML/MLCell.js";
import {PropertiesModal} from "./PropertiesModal.js";
const {
  YELLOW
} = constants;
const SELECT_WIDTH = 360;
const SelectContainer = styled.div`
  label: SelectContainer;
  width: ${SELECT_WIDTH - 20}px;
`;
const Labelling = styled.input`
  label: Labelling;
  padding: 5px;
  flex: 1;
  background: #FFF;
`;
const LabellingContainer = styled.div`
  label: LabellingContainer;
  display: flex;
  width: 100%;
`;
const Textarea = styled.textarea`
  label: Textarea;
  height: calc(100% - 20px);
  width: 100%;
  padding: 5px;
  background: #FFF;
`;
const getKey = (key, delimiter) => {
  return key.split(delimiter).shift();
};
const getLabelField = (handleChange) => ({
  id: "labelling",
  label: "Labelling",
  width: 300,
  content: ({key, grouping}) => {
    return /* @__PURE__ */ React.createElement(LabellingContainer, null, /* @__PURE__ */ React.createElement(Labelling, {
      type: "text",
      value: grouping.changedLabel || "",
      onChange: (e) => handleChange(key, "changedLabel", e.target.value)
    }));
  }
});
const getValueField = (id, label, schemaForData, handleChange) => ({
  id,
  label,
  width: SELECT_WIDTH,
  content: ({key, grouping}) => {
    const options = schemaForData.map((value) => ({
      value,
      label: value
    }));
    const valueToCheck = grouping.changedValue || grouping.suggestedValue;
    const defaultValue = options.reduce((found, option) => {
      if (found) {
        return found;
      }
      if (option.value.toLowerCase() === valueToCheck.toLowerCase()) {
        return option;
      }
    }, null);
    return /* @__PURE__ */ React.createElement(SelectContainer, null, /* @__PURE__ */ React.createElement(Select, {
      options,
      value: defaultValue,
      onChange: (e) => handleChange(key, "changedValue", e.value)
    }));
  }
});
const getComments = (handleChange) => ({
  id: "comments",
  label: "Comments",
  width: 250,
  content: ({grouping, key}) => {
    return /* @__PURE__ */ React.createElement(Textarea, {
      defaultValue: grouping.comment || "",
      onChange: (e) => handleChange(key, "comment", e.target.value)
    });
  }
});
const getNumberOfProps = () => ({
  id: "numberProp",
  label: "# Prop.",
  width: 80,
  content: ({grouping}) => {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, grouping.numberOfProperties, grouping.version === "properties-population" && /* @__PURE__ */ React.createElement(PropertiesModal, {
      grouping
    }));
  }
});
const getMLColumn = (tab, fetchMLData, mlData) => ({
  id: "mlSuggestion",
  width: 400,
  label: "ML Suggestion",
  content: ({grouping, key}) => {
    return /* @__PURE__ */ React.createElement(MLCell, {
      tab,
      grouping,
      fetchMLData,
      mlData
    });
  }
});
export const getColumn = (id, label, key, width) => ({
  id,
  label,
  width,
  content: ({grouping}) => grouping[key]
});
const getNumericIndex = () => ({
  id: "numericIndex",
  content: ({grouping}) => grouping.index + 1,
  width: 60,
  label: ""
});
const getDescription = (delimiter, label) => ({
  id: "description",
  width: 300,
  content: ({key}) => {
    const value = getKey(key, delimiter);
    return value;
  },
  label
});
const getStandardColumns = ({
  handleChange,
  showMLColumns,
  tab,
  fetchMLData,
  mlData
}, cols) => [
  getNumericIndex(),
  ...cols,
  getComments(handleChange),
  getNumberOfProps(),
  showMLColumns ? getMLColumn(tab, fetchMLData, mlData) : void 0
].filter(Boolean);
export const useGetColumns = () => {
  const getColumns = useCallback((props) => {
    const {
      tab,
      delimiter,
      schemaForData,
      handleChange
    } = props;
    if (tab === "occupancy") {
      return getStandardColumns(props, [
        getDescription(delimiter, "Raw Specific Occupancy"),
        getLabelField(handleChange),
        getValueField("suggested", "Suggested Occupancy", schemaForData, handleChange),
        {
          id: "dictionary",
          label: "Dictionary",
          width: 200,
          content: ({grouping}) => {
            const matchType = grouping.matchType;
            return matchType?.value?.dictionary;
          }
        },
        {
          id: "validationRule",
          label: "Validation Rule",
          width: 200,
          content: ({grouping}) => {
            const matchType = grouping.matchType;
            return /* @__PURE__ */ React.createElement(MatchTypeTooltip, {
              label: matchType?.value?.key,
              description: matchType?.value?.description
            });
          }
        },
        {
          id: "keyword",
          label: "Keyword",
          width: 200,
          content: ({grouping}) => {
            const matchType = grouping.matchType;
            return matchType?.value?.keyword;
          }
        }
      ]);
    }
    return getStandardColumns(props, [
      getDescription(delimiter, "Raw Specific Construction"),
      getColumn("numberStories", "# Stories", "numberOfStories", 80),
      getLabelField(handleChange),
      getValueField("suggested", "Suggested Construction", schemaForData, handleChange),
      {
        id: "matchType",
        label: "Match Type",
        width: 200,
        content: ({grouping}) => {
          const matchType = grouping.matchType;
          return matchType?.value?.label;
        }
      }
    ]);
  }, [getStandardColumns]);
  return getColumns;
};
export const getIsChanged = (col, grouping) => {
  if (col === "suggested") {
    const changed = grouping.changedValue !== void 0 && grouping.changedValue !== grouping.suggestedValue;
    return {
      changed,
      originalValue: grouping.suggestedValue
    };
  }
  return {
    changed: false
  };
};
