import React, {useMemo} from "../../../_snowpack/pkg/react.js";
import styled from "../../../_snowpack/pkg/@emotion/styled.js";
import {constants, useDownloadFile, Loading, usePoll, useS3} from "../../../dist/lib/index.js";
import {downloadExcel} from "./downloadExcel.js";
import {InvocationPropertiesTable} from "./InvocationPropertiesTable/InvocationPropertiesTable.js";
import {InvocationPagePropertiesContainer} from "./InvocationPagePropertiesContainer.js";
import LoadingChecklist from "../LoadingChecklist.js";
const {
  BLUE
} = constants;
const useIsCompleted = (status2) => {
  return useMemo(() => status2 === "completed" || status2 === "error" || status2 === "aborted", [status2]);
};
const ErrorContainer = styled.div`
  label: ErrorContainer;
  color: red;
  display: flex;
  width: 100%;
  padding: 20px;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  label {
    color: black;
    text-align: left;
  }

  pre {
    margin: 20px;
    white-space: break-spaces;
    line-height: 120%;
  }
`;
const DownloadOriginalSheet = styled.div`
  label: DownloadOriginalSheet;
  text-align: center;
  display: flex;
  flex-direction: column;

  a {
    color: ${BLUE.toString()};

    &:hover {
      text-decoration: underline;
    }
  }
`;
const getStatus = (status2, progress) => {
  if (status2 === "completed" || status2 === "error") {
    return status2;
  }
  return progress;
};
const useAttributes = (invocation) => {
  const isNewInvocation = invocation.started.getTime() > 1635403404095;
  if (isNewInvocation) {
    const key = invocation.attributes.attributesKey;
    const [getContent] = useS3(key);
    const [data] = usePoll(async function pollForGetContent() {
      const d = await getContent();
      return d;
    }, [getContent, key], {
      debug: false,
      until: (d) => {
        const shouldStopPolling = !!d && d.progress === "complete";
        return shouldStopPolling;
      },
      duration: 1e3,
      timeout: 2e3
    });
    return [data];
  }
  return [invocation.attributes];
};
export function SOVPropertiesTable({
  invocation,
  data,
  columns,
  getDownloadData,
  parseRows,
  rowHeight,
  children,
  itemData,
  stretch
}) {
  if (!invocation) {
    throw new Error("Must have an invocation");
  }
  const [attributes] = useAttributes(invocation);
  if (!attributes?.propertiesJSONURL) {
    return /* @__PURE__ */ React.createElement(Loading, {
      label: "Loading attributes",
      flex: true
    });
  }
  return /* @__PURE__ */ React.createElement(SOVPropertiesTableInner, {
    invocation,
    data,
    columns,
    getDownloadData,
    parseRows,
    rowHeight,
    children,
    itemData,
    stretch,
    attributes
  });
}
function SOVPropertiesTableInner({
  invocation,
  data: _data,
  columns,
  getDownloadData,
  parseRows,
  rowHeight,
  children,
  itemData,
  stretch,
  attributes
}) {
  const data = typeof _data === "function" ? _data(attributes) : _data;
  const isCompleted = useIsCompleted(invocation?.status);
  const rows = useMemo(() => {
    if (data) {
      return parseRows(data);
    }
  }, [columns, data, parseRows]);
  const download = async () => {
    if (data) {
      downloadExcel(getDownloadData(data), "export.xlsx");
    }
  };
  const [downloadFile] = useDownloadFile({key: attributes.sheetURL});
  if (!columns.length) {
    return /* @__PURE__ */ React.createElement("div", null, "No columns supplied to SOVPropertiesTable");
  }
  const isError = invocation?.status === "error" || invocation?.result?.errors?.length > 0;
  return /* @__PURE__ */ React.createElement(React.Fragment, null, isError ? /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(ErrorContainer, null, /* @__PURE__ */ React.createElement("label", null, "There was an error with the run: "), /* @__PURE__ */ React.createElement("pre", null, typeof invocation.result === "string" ? invocation.result : JSON.stringify(invocation.result))), attributes.name && attributes.name && /* @__PURE__ */ React.createElement(DownloadOriginalSheet, null, /* @__PURE__ */ React.createElement("label", null, "You can download the original sheet here:"), /* @__PURE__ */ React.createElement("a", {
    onClick: () => downloadFile({name: attributes.name}),
    target: "_blank"
  }, attributes.name))) : invocation?.status === "created" ? /* @__PURE__ */ React.createElement(Loading, {
    label: "Loading checklist",
    flex: true
  }) : /* @__PURE__ */ React.createElement(LoadingChecklist, {
    status: getStatus(status, attributes.progress),
    isVisible: !isCompleted
  }), /* @__PURE__ */ React.createElement(InvocationPagePropertiesContainer, {
    data: rows,
    download,
    name: attributes.name,
    sheetURL: attributes.sheetURL
  }, data ? /* @__PURE__ */ React.createElement(InvocationPropertiesTable, {
    stretch,
    columns,
    data: rows,
    isCompleted,
    rowHeight,
    itemData
  }, children) : /* @__PURE__ */ React.createElement(Loading, {
    label: "loading table data",
    flex: true
  })));
}
;
export default SOVPropertiesTable;
