import {usePebble} from "../../../../dist/lib/index.js";
import {useCallback, useState} from "../../../../_snowpack/pkg/react.js";
import {useHistory, useRouteMatch} from "../../../../_snowpack/pkg/react-router-dom.js";
import {useUploadFile} from "./useUploadFile.js";
export function useUploadSpreadsheet(pebble, params, routes, gizmoName) {
  const [submitting, setSubmitting] = useState(false);
  const history = useHistory();
  const {url} = useRouteMatch();
  const [
    uploadFile,
    uploadsFolder,
    propertiesJSONURL,
    rootFolder
  ] = useUploadFile(pebble);
  console.log("use pebble", pebble, gizmoName);
  const [executeInvocation] = usePebble(pebble, gizmoName);
  const [file, setFile] = useState();
  const uploadSpreadsheet = useCallback((files) => {
    setFile(files[0]);
  }, []);
  const goToEditor = useCallback((runKey) => {
    const redirectURL = `/${url.split("/").filter(Boolean).slice(0, -1).join("/")}/${routes.editor}/${runKey}`;
    history.push(redirectURL);
  }, [url, history]);
  const kickOffTheProcess = useCallback(async ({fileName, key, bucket}) => {
    const transformedKey = `${uploadsFolder}/transformed.json`;
    const attributesKey = `${uploadsFolder}/attributes.json`;
    const pebbleParams = {
      ...params,
      key,
      bucket,
      transformedKey,
      attributesKey,
      propertiesJSONURL,
      name: fileName,
      sheetURL: `${uploadsFolder}/sheet.xlsx`
    };
    try {
      const response = await executeInvocation(pebbleParams, {
        persist: true,
        attributes: {
          ...params,
          attributesKey,
          propertiesJSONURL,
          name: fileName,
          sheetURL: `${uploadsFolder}/sheet.xlsx`
        }
      });
      const {key: runKey} = response;
      goToEditor(runKey);
    } catch (err) {
      console.error(err);
    }
  }, [
    params,
    executeInvocation,
    pebble,
    goToEditor,
    propertiesJSONURL,
    uploadsFolder,
    rootFolder,
    routes
  ]);
  const runAutomation = useCallback(async () => {
    if (!file) {
      throw new Error("No file set");
    }
    setSubmitting(true);
    try {
      const {key, bucket} = await uploadFile(file, "sheet.xlsx");
      await kickOffTheProcess({key, bucket, fileName: file.name});
    } catch (err) {
      setSubmitting(false);
    }
  }, [file, kickOffTheProcess]);
  return {submitting, file, uploadSpreadsheet, runAutomation};
}
