import React, {useState} from "../../../../_snowpack/pkg/react.js";
import styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import {FaMapMarkerAlt, FaMapMarker} from "../../../../_snowpack/pkg/react-icons/fa.js";
import {FiXSquare, FiMapPin} from "../../../../_snowpack/pkg/react-icons/fi.js";
const ICON_SIZE = 30;
const MARKER_CONTAINER_SIZE = 0;
const MARKER_ARROW = 10;
const MarkerContainer = styled.div`
  label: Marker;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transform-origin: bottom;
  transition-duration: 0.1s;
  ${({active}) => active ? `
    transform: scale(1.2);
  ` : ``}
  // z-index: ${({lat}) => Math.round((100 - lat) * 1e8)};
`;
const MarkerIcon = styled.div`
  label: MarkerIcon;
  z-index: 2;
  ${({icon}) => {
  if (icon === "cross") {
    return `
        margin-top: -${(MARKER_CONTAINER_SIZE + ICON_SIZE) / 2}px;
      `;
  }
  return `
      margin-top: -${MARKER_CONTAINER_SIZE + ICON_SIZE}px;
    `;
}}
  ${({draggable, dragging}) => draggable ? `
    cursor: ${dragging ? "grabbing" : "pointer"};
  ` : ""}
`;
const TooltipContainer = styled.div`
  label: Tooltip;

  ${({hovering}) => hovering ? `
    opacity: 1;
    display: block;
  ` : `
    opacity: 0;
    display: none;
  `}

  z-index: 2;
  transition-duration: 0.2s;
  position: absolute;
  background: black;
  width: 320px;
  padding: 20px 40px;
  bottom: calc(200% + 20px);
  font-size: 16px;
  text-align: center;
  border-radius: 20px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.4);
  color: white;
  
  &:after {
    content: "";

    position: absolute;
    bottom: -${MARKER_ARROW}px;
    left: calc(50% - ${MARKER_ARROW}px);
    width: 0; 
    height: 0; 
    border-left: ${MARKER_ARROW}px solid transparent;
    border-right: ${MARKER_ARROW}px solid transparent;
    border-top: ${MARKER_ARROW}px solid black;
  }
`;
const getIcon = (icon) => {
  if (icon === "cross") {
    return FiXSquare;
  }
  if (icon === "feather-pin") {
    return FiMapPin;
  }
  if (icon === "alt") {
    return FaMapMarkerAlt;
  }
  return FaMapMarker;
};
const Marker = ({
  content,
  icon,
  color = "rgb(40,40,40)",
  handleMouseOver,
  handleMouseOut,
  handleClick,
  active,
  lat,
  draggable,
  dragging
}) => {
  const [hovering, setHovering] = useState(false);
  const onMouseOver = () => {
    setHovering(true);
    if (handleMouseOver) {
      handleMouseOver();
    }
  };
  const onMouseOut = () => {
    setHovering(false);
    if (handleMouseOut) {
      handleMouseOut();
    }
  };
  const Icon = getIcon(icon);
  const isActive = dragging === false && (hovering || active);
  return /* @__PURE__ */ React.createElement(MarkerContainer, {
    active: isActive,
    lat
  }, content && /* @__PURE__ */ React.createElement(TooltipContainer, {
    hovering: isActive
  }, content), /* @__PURE__ */ React.createElement(MarkerIcon, {
    dragging,
    icon,
    draggable,
    onClick: handleClick,
    onMouseOver,
    onMouseOut
  }, /* @__PURE__ */ React.createElement(Icon, {
    size: ICON_SIZE,
    color
  })));
};
export default Marker;
