import {useEffect, useMemo, useRef} from "../../../../_snowpack/pkg/react.js";
import {useCallback, useContext} from "../../../../_snowpack/pkg/react.js";
import {ConfigContext} from "../../Config.js";
import {
  AuthContext,
  useTryToRefreshToken
} from "../../containers/App/Auth/Auth.js";
import {request} from "./request.js";
const BACKEND_VERSION = "v1";
const isResponse = (resp) => {
  return resp instanceof Response;
};
export const isDOMException = (resp) => {
  return resp instanceof DOMException;
};
const isTypeError = (resp) => {
  return resp instanceof TypeError;
};
const getTypeOfResponse = (resp) => {
  if (isResponse(resp)) {
    return "Response";
  }
  if (isDOMException(resp)) {
    return "DOMException";
  }
  if (isTypeError(resp)) {
    return "TypeError";
  }
  console.error(resp);
  throw new Error(`Unrecognized error: ${resp}`);
};
export class RequestError extends Error {
  constructor(resp) {
    super();
    this.response = resp;
    this.typeOfResponse = getTypeOfResponse(resp);
    if (isResponse(resp)) {
      this.status = resp.status;
    }
    this.parsedMessage = new Promise(async (resolve) => {
      if (isResponse(resp)) {
        try {
          const text = await resp.text();
          try {
            this.message = JSON.parse(text);
          } catch (err) {
            this.message = text;
          }
        } catch (err) {
          debugger;
          this.message = String(resp);
        }
      } else if (isDOMException(resp)) {
        this.message = resp.message;
      }
      resolve(this.message);
    });
  }
}
export const useTokenRef = (key) => {
  const resp = useContext(AuthContext);
  const ref = useRef(resp[key]);
  useEffect(() => {
    ref.current = resp[key];
  }, [resp]);
  return ref;
};
export const useBackend = () => {
  const [refreshAuthToken] = useTryToRefreshToken();
  const {BACKEND_URL} = useContext(ConfigContext);
  const idToken = useTokenRef("idToken");
  const accessToken = useTokenRef("accessToken");
  const {setExpired} = useContext(AuthContext);
  const endpoint = useMemo(() => `${BACKEND_URL}/api/${BACKEND_VERSION}`, [
    BACKEND_URL,
    BACKEND_VERSION
  ]);
  const _request = useCallback(async ({url, params}, {getAbortFn = () => {
  }}) => {
    if (!BACKEND_URL) {
      throw new Error("BACKEND_URL is not defined in config.");
    }
    if (!idToken.current) {
      console.log("no idToken found, try to refresh auth token");
      await refreshAuthToken();
      throw new Error("No idToken was found, try again.");
    }
    try {
      return await request({
        url,
        params
      }, {
        endpoint,
        idToken: idToken.current,
        getAbortFn
      });
    } catch (resp) {
      if (resp.status === 404) {
        throw new RequestError(resp);
      } else if (resp.status === 401) {
        setExpired();
        console.log("401, try to refresh auth token");
        console.log("current id token", idToken.current, accessToken.current);
        const text = await resp.text();
        console.log("refreshing auth token");
        await refreshAuthToken();
        console.log("token should be refreshed");
        console.log("done refreshing auth token, now throw parsed text", text);
        throw JSON.parse(text);
      } else if (resp.status === 422) {
        throw new RequestError(resp);
      } else {
        if (resp instanceof DOMException) {
          throw new RequestError(resp);
        } else {
          console.log("useBackend: unexpected error", resp);
          throw new RequestError(resp);
        }
      }
    }
  }, [endpoint, idToken, refreshAuthToken]);
  return [_request, endpoint];
};
