import React from "../../../_snowpack/pkg/react.js";
import {Route, Switch, useRouteMatch} from "../../../_snowpack/pkg/react-router-dom.js";
import {SOVAppInvocationsTable} from "./SOVAppInvocationsTable/SOVAppInvocationsTable.js";
import {SOVAppInvocationPage} from "./SOVAppInvocationPage/SOVAppInvocationPage.js";
import {SOVAppInvocationUpload} from "./SOVAppInvocationUpload/SOVAppInvocationUpload.js";
export function SOVAppInvocations({
  params,
  update,
  children,
  getName,
  pebble,
  gizmoName,
  uploadChildrenHeader,
  showSearch,
  uploadParams,
  uploadDisabled,
  overviewColumns,
  routes: {
    editor = "editor",
    upload = "upload"
  } = {},
  searchTime
}) {
  if (!gizmoName) {
    throw new Error("No gizmo name provided");
  }
  const {url} = useRouteMatch();
  const rootURL = `/${url.split("/").filter(Boolean).slice(0, -1).join("/")}`;
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Switch, null, /* @__PURE__ */ React.createElement(Route, {
    exact: true,
    path: `${rootURL}/${editor}`
  }, /* @__PURE__ */ React.createElement(SOVAppInvocationsTable, {
    searchTime,
    routes: {
      editor,
      upload
    },
    params,
    update,
    gizmoName,
    getName,
    columns: overviewColumns,
    showSearch
  })), /* @__PURE__ */ React.createElement(Route, {
    path: `${rootURL}/${upload}`,
    render: (props) => /* @__PURE__ */ React.createElement(SOVAppInvocationUpload, {
      pebble,
      gizmoName,
      params: uploadParams,
      disabled: uploadDisabled,
      routes: {
        editor,
        upload
      }
    }, uploadChildrenHeader)
  }), /* @__PURE__ */ React.createElement(Route, {
    path: `${rootURL}/${editor}/:id`,
    render: (props) => /* @__PURE__ */ React.createElement(SOVAppInvocationPage, {
      ...props,
      gizmoName
    }, children)
  })));
}
;
