import {useCallback, useEffect, useRef, useState} from "../../../../../../../_snowpack/pkg/react.js";
import {useDownloadFile, useUser, usePebble, useS3} from "../../../../../../../dist/lib/index.js";
function useDebouncedCallback(asyncCallback, callback, timeout, deps) {
  const timer = useRef();
  const fn = useCallback((...args) => {
    callback(...args);
    if (timer.current) {
      window.clearTimeout(timer.current);
    }
    timer.current = window.setTimeout(() => {
      asyncCallback(...args);
    }, timeout);
  }, deps);
  useEffect(() => {
    if (timer.current) {
      window.clearTimeout(timer.current);
    }
  }, deps);
  return fn;
}
export const useGroupedOutputs = (bucket, outputFolder) => {
  const [user] = useUser();
  const filePath = outputFolder ? `${outputFolder}/data.json` : void 0;
  const [makeUpdates] = usePebble("do-verification/update");
  const [getContent] = useS3(filePath);
  const [data, setData] = useState();
  const [updating, setUpdating] = useState(false);
  const [downloadDataFile] = useDownloadFile({getContent});
  useEffect(() => {
    (async () => {
      try {
        const contents = await getContent();
        setData(contents);
      } catch (err) {
        console.error("error getting file contents");
        console.error(err);
      }
    })();
  }, [getContent]);
  const updateData = useDebouncedCallback(async (type, index, key, value) => {
    await makeUpdates({
      bucket,
      output_folder: outputFolder,
      type,
      index,
      key,
      value,
      user: user.name
    });
    setUpdating(false);
  }, (type, index, key, value) => {
    setUpdating(true);
    setData((prev) => {
      return {
        ...prev,
        [type]: {
          ...prev[type],
          groupings: {
            ...prev[type].groupings,
            [index]: {
              ...prev[type].groupings[index],
              [key]: value
            }
          }
        }
      };
    });
  }, 250, []);
  return {
    downloadDataFile: () => downloadDataFile({name: "data.json"}),
    data,
    updateData,
    updating
  };
};
