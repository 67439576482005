import {useCallback} from "../../../_snowpack/pkg/react.js";
import {buildQueryString} from "./internals/buildQueryString.js";
import {prepareGetContent} from "./internals/prepareGetContent.js";
import {useBackend} from "./internals/useBackend.js";
export function useS3(key, bucket) {
  const [request] = useBackend();
  const getContent = useCallback(prepareGetContent(request, key, bucket), [
    key,
    request,
    bucket
  ]);
  const putContent = useCallback(async (file, {key: _key, name} = {}, requestParams = {}) => {
    let parsedFile = file;
    if (typeof parsedFile === "string") {
      parsedFile = new File([parsedFile], name || "");
    }
    const localKey = _key || key;
    if (!localKey) {
      throw new Error("No key provided to put content");
    }
    const formData = new FormData();
    if (file) {
      formData.append("file", parsedFile);
    }
    await request({
      url: `file_content/${key}?${buildQueryString({
        bucket
      })}`,
      params: {
        method: "POST",
        body: formData
      }
    }, requestParams);
  }, []);
  return [getContent, putContent];
}
