import React, {useEffect, useState, useRef, useCallback, forwardRef} from "../../../../_snowpack/pkg/react.js";
import GoogleMapReact from "../../../../_snowpack/pkg/google-map-react.js";
import styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import Marker from "./Marker.js";
import CoordMapType from "./CoordMapType.js";
const GOOGLE_KEY = "AIzaSyBLcbAhkCusTYrrezO0XogoEAYS3w9zx2g";
const Container = styled.div`
  label: MapContainer;
  overflow: hidden;
  display: flex;
  width: 100%;
`;
export const VALID_MAP_TYPES = ["roadmap", "satellite", "hybrid", "terrain", "coordinate"];
export const isValidMapTypeId = (mapTypeId) => {
  return mapTypeId !== void 0 && VALID_MAP_TYPES.includes(mapTypeId);
};
const parseMapOptions = ({mapTypeId, tilt, ...mapOptions}) => {
  if (isValidMapTypeId(mapTypeId)) {
    return {
      ...mapOptions,
      mapTypeId,
      tilt: 0
    };
  }
  return {
    ...mapOptions,
    tilt: 0
  };
};
const DEFAULT_CENTER = {
  lat: 37.7937911,
  lng: -122.398981
};
export const GeographicMap = forwardRef(({
  center: _center,
  handleGoogleApiLoaded,
  mapOptions = {},
  handleMapLoad,
  handleMapClick,
  handleMarkerClick,
  handleMarkerDrag,
  handleMarkerDragStart,
  handleMarkerDragStop,
  handleMapTypeChange,
  handleMouseOver,
  handleMouseOut,
  handleChange,
  defaultCenter = DEFAULT_CENTER,
  defaultZoom = 15,
  zoom,
  markers: _markers,
  tileDimension = 256
}, mapRef) => {
  const [maps, setMaps] = useState();
  const [draggable, setDraggable] = useState(true);
  const [currentlyDragging, setCurrentlyDragging] = useState();
  const [dragStart, setDragStart] = useState();
  const [markers, setMarkers] = useState(_markers);
  useEffect(() => {
    setMarkers(_markers);
  }, [_markers]);
  const [map, setMap] = useState();
  const mapInitialized = useRef(false);
  const [center, setCenter] = useState(_center || defaultCenter);
  const [listeners, setListeners] = useState([]);
  const refObject = useRef({
    recenter: () => {
    }
  });
  useEffect(() => {
    if (map) {
      refObject.current.recenter = (point) => {
        setCenter(point);
        map.setCenter(point);
      };
    }
  }, [map]);
  if (mapRef) {
    mapRef.current = refObject.current;
  }
  useEffect(() => {
    if (map && maps) {
      listeners.forEach((listener2) => maps.event.removeListener(listener2));
      const listener = maps.event.addListener(map, "maptypeid_changed", () => {
        if (handleMapTypeChange) {
          handleMapTypeChange(map.getMapTypeId());
        }
      });
      setListeners((prev) => prev.concat(listener));
    }
  }, [maps, handleMapTypeChange]);
  useEffect(() => {
    setCenter(_center || defaultCenter);
    if (map && maps) {
      const latlng = new maps.LatLng(_center?.lat || defaultCenter?.lat, _center?.lng || defaultCenter?.lng);
      map.setCenter(latlng);
    }
  }, [map, _center]);
  useEffect(() => {
    if (map && handleMapClick && mapInitialized.current === false) {
      mapInitialized.current = true;
      map.addListener("click", ({latLng}) => {
        handleMapClick(latLng.lat(), latLng.lng());
      });
    }
  }, [map, handleMapClick]);
  useEffect(() => {
    if (map && maps) {
      const coord = new CoordMapType(new maps.Size(tileDimension, tileDimension));
      map.mapTypes.set("coordinate", coord);
      if (handleMapLoad) {
        handleMapLoad(map);
      }
    }
  }, [map, maps]);
  useEffect(() => {
    if (map) {
      const parsedMapOptions = parseMapOptions(mapOptions);
      map.setOptions(parsedMapOptions);
    }
  }, [map, JSON.stringify(mapOptions)]);
  const handleChildMouseDown = useCallback((childKey, childProps, mouse) => {
    if (markers && markers[childKey].draggable && mouse.lat !== void 0 && mouse.lng !== void 0) {
      setDraggable(false);
      setCurrentlyDragging(Number(childKey));
      const deltaLat = mouse.lat - markers[childKey].lat;
      const deltaLng = mouse.lng - markers[childKey].lng;
      setDragStart({
        lat: deltaLat,
        lng: deltaLng
      });
      if (handleMarkerDragStart) {
        handleMarkerDragStart();
      }
    }
  }, []);
  const handleNewDragPosition = useCallback((childKey, mouse, fn) => {
    if (mouse.lat === void 0 || mouse.lng === void 0) {
      setDraggable(true);
      if (handleMarkerDragStop && ref.current) {
        handleMarkerDragStop(ref.current, childKey);
      }
    } else if (markers && markers[childKey].draggable) {
      const dragStartLat = dragStart?.lat || 0;
      const dragStartLng = dragStart?.lng || 0;
      const lat = mouse.lat - dragStartLat;
      const lng = mouse.lng - dragStartLng;
      ref.current = {
        lat,
        lng
      };
      fn({lat, lng});
    }
  }, []);
  const handleChildMouseUp = useCallback((childKey, childProps, mouse) => {
    setCurrentlyDragging(void 0);
    handleNewDragPosition(childKey, mouse, () => {
      setDraggable(true);
      if (handleMarkerDragStop && ref.current) {
        handleMarkerDragStop(ref.current, childKey);
      }
    });
  }, []);
  const ref = useRef();
  const handleChildMouseMove = useCallback((childKey, childProps, mouse) => {
    handleNewDragPosition(childKey, mouse, ({lat, lng}) => {
      if (handleMarkerDrag) {
        handleMarkerDrag({lat, lng}, childKey);
      }
      setMarkers((prev) => {
        if (prev) {
          const cloned = Array.from(prev);
          cloned[childKey].lat = lat;
          cloned[childKey].lng = lng;
          if (handleMarkerDrag) {
            handleMarkerDrag(cloned[childKey], childKey);
          }
          return cloned;
        }
      });
    });
  }, []);
  const options = parseMapOptions(mapOptions);
  return /* @__PURE__ */ React.createElement(Container, null, /* @__PURE__ */ React.createElement(GoogleMapReact, {
    draggable,
    bootstrapURLKeys: {
      libraries: "places,drawing,visualization,geometry",
      key: GOOGLE_KEY
    },
    defaultCenter,
    center,
    defaultZoom,
    zoom: zoom || defaultZoom,
    yesIWantToUseGoogleMapApiInternals: true,
    onGoogleApiLoaded: ({map: map2, maps: maps2}) => {
      setMap(map2);
      setMaps(maps2);
      if (handleGoogleApiLoaded) {
        handleGoogleApiLoaded({map: map2, maps: maps2});
      }
    },
    options,
    onChildMouseDown: handleChildMouseDown,
    onChildMouseUp: handleChildMouseUp,
    onChildMouseMove: handleChildMouseMove,
    onChange: handleChange
  }, markers?.map((marker, key) => /* @__PURE__ */ React.createElement(Marker, {
    ...marker,
    dragging: currentlyDragging === key,
    key,
    lat: marker.lat,
    lng: marker.lng,
    handleMouseOut: () => {
      if (handleMouseOut) {
        handleMouseOut(marker, key);
      }
    },
    handleMouseOver: () => {
      if (handleMouseOver) {
        handleMouseOver(marker, key);
      }
    },
    handleClick: () => {
      if (handleMarkerClick) {
        handleMarkerClick(marker, key);
      }
    }
  }))));
});
export const getBoundingRect = (bounds) => {
  return Object.values(bounds).reduce((bounds2, point) => {
    let {
      minLat,
      minLng,
      maxLat,
      maxLng
    } = bounds2;
    if (minLat === void 0 || minLat > point.lat) {
      minLat = point.lat;
    }
    if (minLng === void 0 || minLng > point.lng) {
      minLng = point.lng;
    }
    if (maxLat === void 0 || maxLat < point.lat) {
      maxLat = point.lat;
    }
    if (maxLng === void 0 || maxLng < point.lng) {
      maxLng = point.lng;
    }
    return {
      minLat,
      minLng,
      maxLat,
      maxLng
    };
  }, {});
};
