import {formatDistanceStrict, formatDuration} from "../../../../../_snowpack/pkg/date-fns.js";
export const getFormattedDate = (created) => {
  if (created) {
    try {
      return formatDistanceStrict(created, new Date(), {addSuffix: true});
    } catch (err) {
    }
  }
  return void 0;
};
export const getDurationParts = (duration) => {
  if (duration < 10) {
    return {
      seconds: Number(duration.toFixed(2))
    };
  }
  if (duration < 60) {
    return {
      seconds: Math.round(duration)
    };
  }
  if (duration < 60 * 60) {
    const minutes2 = Math.floor(duration / 60);
    return {
      minutes: minutes2,
      seconds: Math.round(duration - minutes2 * 60)
    };
  }
  const hours = Math.floor(duration / 60 / 60);
  const remainder = duration - hours * 60 * 60;
  const minutes = Math.floor(remainder / 60);
  return {
    hours,
    minutes,
    seconds: Math.round(remainder - minutes * 60)
  };
};
export function getDuration(run) {
  const started = run.started;
  const ended = run.ended;
  if (!ended) {
    return "-";
  }
  let time = (ended.getTime() - started.getTime()) / 1e3;
  return formatDuration({
    ...getDurationParts(time)
  }, {
    format: ["hours", "minutes", "seconds"],
    delimiter: ", "
  });
}
