import React from "../../../../_snowpack/pkg/react.js";
import styled from "../../../../_snowpack/pkg/@emotion/styled.js";
const isMarkdownContent = (content) => typeof content === "object" && "markdown" in content;
const isCollection = (story) => {
  return "stories" in story && Array.isArray(story.stories);
};
export const MarkdownContainer = styled.div`
  label: MarkdownContainer;
  margin: 20px 0 0 40px;
  max-width: 800px;

  p {
    line-height: 1.2rem;
  }

  img {
    max-width: 100%;
  }
`;
const getContent = ({content}) => {
  if (isMarkdownContent(content)) {
    return () => /* @__PURE__ */ React.createElement(MarkdownContainer, null, /* @__PURE__ */ React.createElement("div", {
      dangerouslySetInnerHTML: {
        __html: content.body
      }
    }));
  }
  return content;
};
const parseStory = (story, parentURLs) => {
  const content = getContent(story);
  return {
    title: story.title,
    content,
    url: getURLFromTitles(story.title, parentURLs),
    slug: getURLFromTitle(story.title)
  };
};
export const getURLFromTitle = (title) => title.toLowerCase().split(" ").join("-");
const getURLFromTitles = (title, parentURLs) => {
  const url = ["", ...parentURLs, title].map(getURLFromTitle).join("/");
  return url;
};
const parseStoriesConfiguration = (stories, parentURLs = []) => {
  let parsedStories = stories.map((story) => {
    if (isCollection(story)) {
      return {
        title: story.title,
        stories: parseStoriesConfiguration(story.stories, parentURLs.concat(story.title)),
        slug: getURLFromTitle(story.title)
      };
    }
    return parseStory(story, parentURLs);
  });
  return parsedStories;
};
const validateStories = (stories, indices = []) => {
  if (!Array.isArray(stories)) {
    throw new Error("Story configuration is not a valid array.");
  }
  for (let i = 0; i < stories.length; i++) {
    const story = stories[i];
    if (!story.title) {
      throw new Error([
        `Story in configuration at index ${[...indices, i].join(".")}`,
        `does not have a valid title.`
      ].join(" "));
    }
    if (isCollection(story)) {
      validateStories(story.stories, [...indices, i]);
    } else {
      if (!story.content) {
        throw new Error([
          `Story ${story.title} in configuration at index ${[
            ...indices,
            i
          ].join(", ")}`,
          `does not have a valid content. It should be a string or a function.`
        ].join(" "));
      }
      const typeOfContent = typeof story.content;
      if (!["object", "function"].includes(typeOfContent)) {
        throw new Error([
          `Story ${story.title} in configuration at index ${[
            ...indices,
            i
          ].join(", ")}`,
          `does not have a valid content. Its type is "${typeOfContent}"`
        ].join(" "));
      }
    }
    const keys = Object.keys(story).filter((key) => {
      return !["title", "content", "stories"].includes(key);
    });
    if (keys.length) {
      throw new Error([
        `Story ${story.title} in configuration at index ${[
          ...indices,
          i
        ].join(".")}`,
        `has unexpected keys: ${keys.join("|")}`
      ].join(" "));
    }
  }
  return stories;
};
export const parseStories = (stories) => {
  return parseStoriesConfiguration(validateStories(stories));
};
