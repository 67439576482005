import React, {useCallback, useMemo, useState} from "../../../../../../_snowpack/pkg/react.js";
import styled from "../../../../../../_snowpack/pkg/@emotion/styled.js";
import {AiFillCloseCircle} from "../../../../../../_snowpack/pkg/react-icons/ai.js";
import {format} from "../../../../../../_snowpack/pkg/date-fns.js";
import {Loading, useStory, Toggle, useToggleFns} from "../../../../../../dist/lib/index.js";
import {NAMESPACE, ROUTES} from "../../config.js";
import {SOVAppInvocationsTable} from "../../../../../components/SOVAppInvocations/SOVAppInvocationsTable/SOVAppInvocationsTable.js";
import {Highlight} from "../../../../../components/SOVAppInvocations/SOVAppInvocationsTable/SOVAppInvocationsTableWrapper/Highlight.js";
import {Calendar} from "../../../../../../_snowpack/pkg/react-date-range.js";
const TdWrapper = styled.div`
  label: TdWrapper;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({deleted}) => deleted ? `
    text-decoration: line-through;
    background: rgba(0,0,0,0.3);
  ` : ""}
`;
const ToggleContainer = styled.div`
  label: ToggleContainer;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  label {
    cursor: pointer;
  }

  input {
    display: none;
  }
`;
const FormInput = styled.div`
  label: FormInput;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 40px;

  label {
    cursor: pointer;
    width: 100%;
    user-select: none;
  }

  input[type=checkbox] {
    width: 24px;
    height: 24px;
  }
`;
const STATUS_LABEL = "Status";
const DEFAULT_DYNAMIC_WIDTHS = {
  Client: 0.5,
  [STATUS_LABEL]: 0.5
};
const DEFAULT_STATIC_WIDTHS = {
  Uploader: 80,
  Remove: 70,
  Archive: 70,
  Uploaded: 160,
  Download: 280
};
const getWidthFn = (staticWidths, dynamicWidths, startColumns, endColumns) => {
  const staticWidth = Object.values(DEFAULT_STATIC_WIDTHS).reduce((sum, width) => sum + width, 0) + [...startColumns, ...endColumns].filter((col) => !col.dynamic && col.width).reduce((sum, {width}) => sum + width, 0);
  return (col, _, width) => {
    if (col.width) {
      if (col.dynamic) {
        return (width - staticWidth) * col.width;
      }
      return col.width;
    }
    if (!staticWidths[col.label] && !dynamicWidths[col.label]) {
      throw new Error(`No width defined for column ${col.label}`);
    }
    if (dynamicWidths[col.label]) {
      return (width - staticWidth) * dynamicWidths[col.label];
    }
    return staticWidths[col.label];
  };
};
const getDate = (d, i) => {
  try {
    const date = new Date(Number(d));
    if (isNaN(date.getTime())) {
      throw new Error();
    }
    return date;
  } catch (err) {
    return i?.started;
  }
};
const useDate = (key, invocations, index) => {
  const {params} = useStory();
  return useMemo(() => {
    const defaultInvocation = invocations?.[index];
    return getDate(params[key], defaultInvocation);
  }, [params[key], invocations?.map((i) => i.key).join(",")]);
};
export const VerifyUploadsTable = ({
  startColumns = [],
  endColumns = [],
  staticWidths = {},
  dynamicWidths = {},
  getFilteredInvocations,
  getInvocations,
  data,
  showUpload,
  pollingFrequency = 1e3
}) => {
  const [invocations, setInvocations] = useState();
  const {params, update} = useStory();
  const {value: showHidden, fn: toggleShowHidden} = useToggleFns({params, update, label: "showHidden", defaultValue: "off"});
  const earliest = useDate("from", invocations, invocations ? invocations.length - 1 : 0);
  const latest = useDate("to", invocations, 0);
  const filters = useMemo(() => [
    /* @__PURE__ */ React.createElement(FormInput, null, /* @__PURE__ */ React.createElement("label", {
      htmlFor: "from"
    }, "From"), /* @__PURE__ */ React.createElement(DatePicker, {
      name: "from",
      value: earliest,
      handleChange: (date) => update({
        from: date.getTime()
      })
    })),
    /* @__PURE__ */ React.createElement(FormInput, null, /* @__PURE__ */ React.createElement("label", {
      htmlFor: "to"
    }, "To"), /* @__PURE__ */ React.createElement(DatePicker, {
      name: "to",
      value: latest,
      handleChange: (date) => update({
        to: date.getTime()
      })
    })),
    /* @__PURE__ */ React.createElement(ToggleContainer, null, /* @__PURE__ */ React.createElement(Toggle, {
      key: "show-hidden",
      name: "show-hidden",
      label: "Show Hidden",
      checked: showHidden,
      handleChange: toggleShowHidden
    }))
  ], [toggleShowHidden, showHidden, earliest, latest]);
  const from = useMemo(() => new Date(Number(params.from)), [params.from]);
  const to = useMemo(() => new Date(Number(params.to) + 1e3 * 60 * 60 * 24), [params.to]);
  const filterInvocations = useCallback((i) => {
    return (showHidden || i.attributes.archived !== true) && (isNaN(from.getTime()) || i.started > from) && (isNaN(to.getTime()) || i.started < to);
  }, [showHidden, from, to]);
  const getWidth = useMemo(() => getWidthFn({
    ...DEFAULT_STATIC_WIDTHS,
    ...staticWidths
  }, {
    ...DEFAULT_DYNAMIC_WIDTHS,
    ...dynamicWidths
  }, startColumns, endColumns), [startColumns, endColumns, staticWidths, dynamicWidths]);
  return /* @__PURE__ */ React.createElement(SOVAppInvocationsTable, {
    filterInvocations,
    showSearch: true,
    pollingFrequency,
    searchTime: 0,
    routes: ROUTES,
    data,
    params,
    update,
    gizmoName: NAMESPACE,
    getName: (invocation) => invocation.attributes.name,
    filters,
    getWidth,
    rowSize: 55,
    getFilteredInvocations,
    getInvocations: (_invocations) => {
      setInvocations(_invocations);
      if (getInvocations) {
        getInvocations(_invocations);
      }
    },
    showUpload,
    columns: [
      ...startColumns,
      {
        label: "Uploaded",
        content: ({invocation}) => {
          let time = "-";
          try {
            time = format(new Date(invocation.started), "MMM do h:mm:ss a");
          } catch (err) {
          }
          return /* @__PURE__ */ React.createElement(TdWrapper, {
            deleted: invocation.attributes.deleted
          }, time);
        }
      },
      {
        label: "Uploader",
        content: ({
          invocation,
          search
        }) => invocation.user && /* @__PURE__ */ React.createElement(TdWrapper, {
          deleted: invocation.attributes.deleted
        }, /* @__PURE__ */ React.createElement(Highlight, {
          value: invocation.user,
          search
        }))
      },
      {
        label: STATUS_LABEL,
        content: ({invocation, search}) => {
          const {status, completedAt, downloadedAt, completedBy} = invocation.attributes;
          const statusText = getStatus(status, completedAt, completedBy, search);
          return /* @__PURE__ */ React.createElement(TdWrapper, {
            deleted: invocation.attributes.deleted
          }, statusText);
        }
      },
      ...endColumns
    ],
    useExactColumns: true
  });
};
const getStatus = (status, completedAt, completedBy, search) => {
  if (status === "completed" || status === "downloaded") {
    try {
      const time = format(new Date(completedAt), "MMM do h:mm:ss a");
      return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("span", {
        title: time
      }, "Submitted ", time), /* @__PURE__ */ React.createElement("br", null), /* @__PURE__ */ React.createElement("span", {
        style: {marginRight: 5}
      }, "by"), /* @__PURE__ */ React.createElement(Highlight, {
        value: completedBy,
        search
      }));
    } catch (err) {
    }
    return "Submitted";
  }
  return "Not yet submitted";
};
const DatePickerContainer = styled.div`
  label: DatePickerContainer;
  position: relative;
`;
const DatePickerInput = styled.input`
  label: DatePickerInput;
  padding: 0 5px;
`;
const CalendarWindow = styled.div`
  label: CalendarWindow;
  position: absolute;
  margin-top: 5px;
  box-shadow: 0 0 2px rgba(0,0,0,0.2);
  background: white;
  z-index: ${({visible}) => visible ? 10 : 0};
  opacity: ${({visible}) => visible ? 1 : 0};
`;
const CloseContainer = styled.div`
  label: CloseContainer;
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
`;
const DatePicker = ({
  value,
  handleChange,
  name
}) => {
  const [focused, setFocused] = useState(false);
  if (!value) {
    return /* @__PURE__ */ React.createElement(Loading, {
      flex: true,
      label: "Getting date value"
    });
  }
  return /* @__PURE__ */ React.createElement(DatePickerContainer, null, /* @__PURE__ */ React.createElement(DatePickerInput, {
    id: name,
    name,
    type: "text",
    onFocus: () => setFocused(true),
    onChange: (e) => e.preventDefault(),
    value: format(value, "MMM do")
  }), /* @__PURE__ */ React.createElement(CalendarWindow, {
    visible: focused
  }, /* @__PURE__ */ React.createElement(CloseContainer, null, /* @__PURE__ */ React.createElement(AiFillCloseCircle, {
    onClick: () => setFocused(false)
  })), /* @__PURE__ */ React.createElement(Calendar, {
    date: value,
    onChange: (date) => {
      handleChange(date);
      setFocused(false);
    }
  })));
};
