import React from "../../../../../_snowpack/pkg/react.js";
import styled from "../../../../../_snowpack/pkg/@emotion/styled.js";
import {Tooltip} from "../../../../../dist/lib/index.js";
import {BiNotepad} from "../../../../../_snowpack/pkg/react-icons/bi.js";
const ProvenanceContainer = styled.div`
  label: ProvenanceContainer;
  position: absolute;
  left: 5px;
  bottom: 5px;
  color: rgba(0,0,0,0.8);

  svg {
    color: rgba(0, 0, 0, 0.5);
    transition-duration: 0.2s;

    &:hover {
      color: rgba(0, 0, 0, 0.8);
    }
  }

  label {
    display: block;
    color: rgba(0,0,0,0.4);
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
  }
`;
const TooltipInner = styled.div`
  label: TooltipInner;
  min-width: 120px;
`;
const Provenance = ({provenance, over}) => {
  return /* @__PURE__ */ React.createElement(ProvenanceContainer, null, /* @__PURE__ */ React.createElement(BiNotepad, null), over && /* @__PURE__ */ React.createElement(Tooltip, {
    bgColor: `rgb(240, 240, 245)`,
    color: "rgba(0,0,0,0.8)"
  }, /* @__PURE__ */ React.createElement(TooltipInner, null, /* @__PURE__ */ React.createElement("label", null, "Provenance:"), /* @__PURE__ */ React.createElement("span", null, provenance))));
};
export default Provenance;
