export const parseBody = (body) => {
  if (body === void 0) {
    return void 0;
  }
  if (body instanceof FormData) {
    return body;
  }
  if (typeof body === "string") {
    return body;
  }
  return JSON.stringify(body);
};
