import React from "../../_snowpack/pkg/react.js";
import styled from "../../_snowpack/pkg/@emotion/styled.js";
import {constants, Loading} from "../../dist/lib/index.js";
import {FiCheckCircle} from "../../_snowpack/pkg/react-icons/fi.js";
const {
  BLUE
} = constants;
const LoadingContainer = styled.div`
  label: LoadingContainer;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: 140%;

  ul {
    text-align: left;
  }
`;
const Li = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  transition-duration: 1s;
  height: 30px;
  overflow: hidden;

  ${({complete, showCompletedItems}) => {
  if (complete && !showCompletedItems) {
    return `
        text-decoration: line-through;
        height: 0;
        margin-bottom: 0;
      `;
  }
  if (complete) {
    return `
        text-decoration: line-through;
      `;
  }
  return `
      color: rgba(0,0,0,0.3);
    `;
}}
`;
const PacmanContainer = styled.div`
  label: PacmanContainer;
  transition: height 1s, margin-bottom 1s, opacity: 0.5s ;
  overflow: hidden;
  height: ${({showCompletedItems}) => showCompletedItems ? 100 : 0}px;
  opacity: ${({showCompletedItems}) => showCompletedItems ? 1 : 0};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${({showCompletedItems}) => showCompletedItems ? 5 : 0}px;
  width: 132px;
  justify-content: flex-start;
`;
const Icon = styled.div`
  margin-right: 10px;
  svg {
    transition-duration: 1s;
    color: ${({complete}) => complete ? "green" : "rgba(0,0,0,0.3)"};
  }
`;
const defaultTranslateStatus = (status) => {
  if (status === "handling-excel") {
    return 0;
  }
  if (status === "transforming-properties") {
    return 1;
  }
  if (status === "completed") {
    return 2;
  }
  return -1;
};
const CHECKLIST = [
  "Parsing Excel File",
  "Processing Properties"
];
const LoadingChecklist = ({
  status,
  checklist = CHECKLIST,
  translateStatus = defaultTranslateStatus,
  isVisible
}) => {
  if (!status) {
    return null;
  }
  const statusIndex = translateStatus(status);
  return /* @__PURE__ */ React.createElement(LoadingContainer, {
    showCompletedItems: isVisible
  }, /* @__PURE__ */ React.createElement(PacmanContainer, {
    showCompletedItems: isVisible
  }, /* @__PURE__ */ React.createElement(Loading, {
    size: 20,
    type: "pacman",
    color: BLUE.toString(),
    fadeIn: 0,
    label: "Pacman"
  })), /* @__PURE__ */ React.createElement("ul", null, checklist.map((label, i) => {
    const complete = i < statusIndex || !isVisible;
    return /* @__PURE__ */ React.createElement(Li, {
      key: i,
      complete,
      showCompletedItems: isVisible
    }, /* @__PURE__ */ React.createElement(Icon, {
      complete
    }, /* @__PURE__ */ React.createElement(FiCheckCircle, null)), label);
  })));
};
export default LoadingChecklist;
