import {useCallback, useEffect, useMemo, useReducer, useRef} from "../../../../../../../_snowpack/pkg/react.js";
import {usePebble} from "../../../../../../../dist/lib/index.js";
const initialState = {};
export const getMLKey = (fn, args) => JSON.stringify({
  fn,
  ...args
});
function reducer(state = initialState, action) {
  const key = getMLKey(action.fnKey, action.args);
  switch (action.type) {
    case "fetchedData":
      return {
        ...state,
        [key]: {
          loading: false,
          data: action.data
        }
      };
    case "fetchData":
      return {
        ...state,
        [key]: {
          ...state[key],
          loading: true
        }
      };
  }
}
class QueueItem {
  constructor(id, fn, resolve) {
    this.id = id;
    this.fn = fn;
    this.resolve = resolve;
  }
}
class Queue {
  constructor() {
    this.items = [];
    this.add = (queueItem) => {
      this.items.push(queueItem);
    };
    this.take = () => {
      if (this.items.length === 0) {
        return null;
      }
      return this.items.shift();
    };
  }
}
class Pool {
  constructor() {
    this.queue = new Queue();
    this.max_processes = 1;
    this.current = 0;
    this.next = (fn, id) => new Promise(async (resolve) => {
      this.queue.add(new QueueItem(id, fn, resolve));
      this.checkForNextItem();
    });
    this.checkForNextItem = async () => {
      if (this.current < this.max_processes) {
        this.current += 1;
        const nextItem = this.queue.take();
        if (nextItem) {
          const result = await nextItem.fn();
          nextItem.resolve(result);
          this.current -= 1;
          this.checkForNextItem();
        }
      }
    };
  }
}
export const useMLData = () => {
  const mlReducer = useReducer(reducer, initialState);
  const [
    makeMLPredictionForConstructionCellValue
  ] = usePebble("transform-construction-type-from-cell-value");
  const [
    makeMLPredictionForConstructionSovAttbs
  ] = usePebble("transform-construction-type-from-sov-attributes");
  const [makeMLPredictionForOccupancyCellValue] = usePebble("transform-occupancy-type-from-cell-value");
  const fns = useMemo(() => ({
    "transform-construction-type-from-cell-value": makeMLPredictionForConstructionCellValue,
    "transform-occupancy-type-from-cell-value": makeMLPredictionForOccupancyCellValue,
    "transform-construction-type-from-sov-attributes": makeMLPredictionForConstructionSovAttbs
  }), [
    makeMLPredictionForOccupancyCellValue,
    makeMLPredictionForConstructionCellValue,
    makeMLPredictionForConstructionSovAttbs
  ]);
  const [state, dispatch] = mlReducer;
  const pool = useRef(new Pool());
  const fetchData = useCallback(async (fnKey, args) => {
    const key = getMLKey(fnKey, args);
    const slice = state[key];
    const fn = fns[fnKey];
    if (!slice?.loading && !slice?.data && fn) {
      dispatch({type: "fetchData", args, fnKey});
      const {result} = await pool.current.next(async () => {
        return await fn({
          ...args
        });
      }, JSON.stringify({fnKey, args}));
      dispatch({type: "fetchedData", args, fnKey, data: result});
    }
  }, [fns, state]);
  return [fetchData, state];
};
const getArgsForCellValue = (grouping) => [
  {
    cellValue: grouping.originalValues.join(" | ")
  }
];
const getSOVArgsFromProperty = (property) => Object.entries({
  index: property.index,
  streetAddress: property["Street Address.Suggested"],
  city: property["City.Suggested"],
  state: property["State.Suggested"],
  country: property["Country.Suggested"],
  geoCodeType: property["geoCodeType.Suggested"],
  totalInsuredValue: parseInt(property["Total Insured Value.Suggested"]),
  contentsReplacementCost: property["Content Replacement Cost.Suggested"],
  specificOccupancy: property["Specific Occupancy.Original"],
  latitude: property["latitude"],
  longitude: property["longitude"],
  locationName: property["locationName"]
}).reduce((obj, [key, val]) => {
  if (val === null || Number.isNaN(val)) {
    return obj;
  }
  return {
    ...obj,
    [key]: val
  };
}, {});
const getArgsForSOVAttbs = (grouping) => {
  return grouping.properties.filter(Boolean).map((property) => {
    return getSOVArgsFromProperty(property);
  });
};
export const getFnsForTab = (tab) => {
  if (tab === "construction") {
    return [
      {
        fnKey: "transform-construction-type-from-cell-value",
        args: getArgsForCellValue
      },
      {
        fnKey: "transform-construction-type-from-sov-attributes",
        args: getArgsForSOVAttbs
      }
    ];
  }
  return [
    {
      fnKey: "transform-occupancy-type-from-cell-value",
      args: getArgsForCellValue
    }
  ];
};
export const useMLSelector = (fetchMLData, state, keys) => {
  useEffect(() => {
    keys.forEach(({fnKey, args}) => {
      fetchMLData(fnKey, args);
    });
  }, [fetchMLData, keys]);
  const data = useMemo(() => {
    return keys.reduce((obj, {fnKey, args}) => {
      const key = getMLKey(fnKey, args);
      return {
        ...obj,
        [key]: state[key]?.data
      };
    }, {});
  }, [keys, JSON.stringify(state)]);
  return data;
};
export const useAverage = (data = []) => useMemo(() => data.reduce((_obj, datum) => {
  if (datum.errors.length) {
    return {
      ..._obj,
      errors: _obj.errors.concat(datum.errors)
    };
  }
  return Object.entries(datum.data).reduce((obj, [key, val]) => {
    return {
      ...obj,
      data: {
        ...obj.data,
        [key]: (obj.data[key] || 0) + val / data.length
      }
    };
  }, _obj);
}, {
  data: {},
  errors: []
}), [data]);
export const useOrganizeMLData = (data) => useMemo(() => {
  if (!data) {
    return void 0;
  }
  return Object.entries(data || {}).reduce((obj, [_key, val]) => {
    const {fn} = JSON.parse(_key);
    if (fn.includes("cell-value")) {
      return {
        ...obj,
        cellValue: val
      };
    }
    if (val) {
      return {
        ...obj,
        sovAttbs: {
          ...obj.sovAttbs,
          [_key]: val
        }
      };
    }
    return obj;
  }, {
    cellValue: void 0,
    sovAttbs: {}
  });
}, [data]);
export const useTableData = (data) => {
  const {cellValue, sovAttbs} = useOrganizeMLData(data);
  const avgSovAttbs = useAverage(Object.values(sovAttbs));
  const tableData = useAverage([cellValue, avgSovAttbs].filter(Boolean));
  return tableData;
};
