import React from "../../../../_snowpack/pkg/react.js";
import styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import {UploadSpreadsheet} from "./UploadSpreadsheet.js";
const Container = styled.div`
  label: UploadContainer;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
`;
export function SOVAppInvocationUpload({
  pebble,
  children,
  params,
  disabled,
  routes,
  gizmoName
}) {
  return /* @__PURE__ */ React.createElement(Container, null, children, /* @__PURE__ */ React.createElement(UploadSpreadsheet, {
    uploadButtonText: "Start Automation",
    pebble,
    gizmoName,
    disabled,
    params,
    routes
  }, /* @__PURE__ */ React.createElement("h1", null, "Upload File")));
}
;
