import React, {useState, useEffect, useMemo, useCallback, useRef} from "../../../../../../_snowpack/pkg/react.js";
import styled from "../../../../../../_snowpack/pkg/@emotion/styled.js";
import {constants, GeographicMap, Loading} from "../../../../../../dist/lib/index.js";
import {FiMaximize2, FiMinimize2, FiCompass} from "../../../../../../_snowpack/pkg/react-icons/fi.js";
import Search from "./Search.js";
import MapButton, {MapButtons, MapButtonRight} from "./MapButton.js";
const {
  BLUE,
  GREEN,
  RED
} = constants;
const MapContainer = styled.div`
  label: MapContainer;
  height: 100%;
  width: 100%;
  flex: 1;
  display: flex;
  position: relative;
  flex-direction: column;
`;
const GoogleMapContainer = styled.div`
  label: GoogleMapContainer;
  display: flex;
  flex: 1;
`;
const DEFAULT_CENTER = {
  lat: 1,
  lng: 1
};
export const DTNBMap = ({
  index,
  setExpandedRow,
  expanded,
  distanceToNearestBuilding: _distanceToNearestBuilding,
  handleChangedPin,
  address,
  latitude: _latitude,
  longitude: _longitude
}) => {
  const googleMapRef = useRef(null);
  const [show, setShow] = useState(false);
  const [maps, setMaps] = useState();
  const [map, setMap] = useState();
  const getStateLine = () => {
    let x = {
      start: DEFAULT_CENTER,
      end: DEFAULT_CENTER
    };
    if (_distanceToNearestBuilding.latitudeRulerStart && _distanceToNearestBuilding.longitudeRulerStart && _distanceToNearestBuilding.latitudeRulerEnd && _distanceToNearestBuilding.longitudeRulerEnd) {
      x.start = {
        lat: parseFloat(`${_distanceToNearestBuilding.latitudeRulerStart}`),
        lng: parseFloat(`${_distanceToNearestBuilding.longitudeRulerStart}`)
      };
      x.end = {
        lat: parseFloat(`${_distanceToNearestBuilding.latitudeRulerEnd}`),
        lng: parseFloat(`${_distanceToNearestBuilding.longitudeRulerEnd}`)
      };
    } else if (_latitude && _longitude) {
      x.start = {
        lat: _latitude,
        lng: _longitude
      };
      x.end = {
        lat: _latitude,
        lng: _longitude
      };
    }
    return x;
  };
  const [line, setLine] = useState(getStateLine());
  const [polyline, setPolyLine] = useState();
  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 1e3 + Math.random() * 1e3);
    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    if (maps && line && line.start && line.end) {
      let makeNewPolyline = function() {
        var flightPath = new maps.Polyline({
          path: [{
            lat: line.start.lat,
            lng: line.start.lng
          }, {
            lat: line.end.lat,
            lng: line.end.lng
          }],
          geodesic: true,
          strokeColor: RED.toString(),
          strokeOpacity: 1,
          strokeWeight: 5
        });
        flightPath.setMap(map);
        setPolyLine(flightPath);
      };
      if (polyline) {
        polyline.setMap(null);
      }
      makeNewPolyline();
    }
  }, [maps, line]);
  const computeDistance = (start, end) => {
    return google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(start.lat, start.lng), new google.maps.LatLng(end.lat, end.lng));
  };
  const handleMarkerDragStop = useCallback((point, index2) => {
    const ft = 3.28084;
    let meters = 0;
    if (line && line.start && line.end) {
      console.log("line!");
      meters = google.maps.geometry.spherical.computeDistanceBetween(new google.maps.LatLng(line.start.lat, line.start.lng), new google.maps.LatLng(line.end.lat, line.end.lng));
      console.log("the meters", meters);
    } else {
      console.log("no line");
    }
    let start = line?.start || DEFAULT_CENTER;
    let end = line?.end || DEFAULT_CENTER;
    if (index2 === "0") {
      start.lat = point.lat;
      start.lng = point.lng;
    } else {
      end.lat = point.lat;
      end.lng = point.lng;
    }
    const distance = meters * ft;
    console.log(meters, " meters 2");
    console.log(distance, " ft 2");
    console.log(index2, start.lat, start.lng, end.lat, end.lng, distance);
    handleChangedPin(start, end, distance);
    setLine({
      start,
      end
    });
  }, [line]);
  const isExpanded = useMemo(() => index === expanded, [index, expanded]);
  const mapOptions = useMemo(() => {
    if (!maps) {
      return {};
    }
    return {
      tilt: 0,
      disableDefaultUI: true,
      rotateControl: false,
      ...isExpanded ? {
        zoomControl: true,
        mapTypeControl: true,
        mapTypeControlOptions: {
          position: maps.ControlPosition.LEFT_BOTTOM
        }
      } : {
        mapTypeControl: false
      }
    };
  }, [isExpanded, maps]);
  const markers = useMemo(() => {
    if (!line || !line.end || !line.start) {
      return [{
        icon: "alt",
        lat: DEFAULT_CENTER.lat,
        lng: DEFAULT_CENTER.lng,
        color: BLUE.toString(),
        draggable: true
      }];
    }
    return [{
      name: "marker-start",
      icon: "alt",
      lat: line.start.lat,
      lng: line.start.lng,
      color: GREEN.toString(),
      draggable: true
    }, {
      name: "marker-end",
      icon: "alt",
      lat: line.end.lat,
      lng: line.end.lng,
      color: BLUE.toString(),
      draggable: true
    }];
  }, [line]);
  const handleGoogleApiLoaded = ({map: map2, maps: maps2}) => {
    setMap(map2);
    setMaps(maps2);
  };
  if (show) {
    const googleMapCenter = line?.start || DEFAULT_CENTER;
    return /* @__PURE__ */ React.createElement(MapContainer, null, /* @__PURE__ */ React.createElement(MapButtons, null, maps && /* @__PURE__ */ React.createElement(Search, {
      handleFocus: () => {
      },
      address: address ? [
        address.streetAddress,
        address.city,
        address.state,
        address.zip,
        address.country
      ].filter(Boolean).join(", ") : "",
      handleSubmit: (point) => {
        setLine({
          start: point,
          end: point
        });
        handleChangedPin(point, point, computeDistance(point, point));
      },
      maps
    }), /* @__PURE__ */ React.createElement(MapButtonRight, null, /* @__PURE__ */ React.createElement(MapButton, {
      handleClick: () => {
        const current = googleMapRef.current;
        if (current) {
          current.recenter(line.start);
        } else {
          console.warn("No google map ref could be found");
        }
      }
    }, /* @__PURE__ */ React.createElement(FiCompass, null)), /* @__PURE__ */ React.createElement(MapButton, {
      handleClick: () => {
        if (isExpanded) {
          setExpandedRow(void 0);
        } else {
          setExpandedRow(index);
        }
      }
    }, isExpanded ? /* @__PURE__ */ React.createElement(FiMinimize2, null) : /* @__PURE__ */ React.createElement(FiMaximize2, null)))), /* @__PURE__ */ React.createElement(GoogleMapContainer, null, /* @__PURE__ */ React.createElement(GeographicMap, {
      center: googleMapCenter,
      defaultZoom: 18,
      markers,
      handleMarkerDragStop,
      mapOptions,
      handleGoogleApiLoaded,
      ref: googleMapRef
    })));
  }
  return /* @__PURE__ */ React.createElement(MapContainer, null, /* @__PURE__ */ React.createElement(Loading, {
    flex: true,
    label: "distance-to-nearest-building map"
  }));
};
