import React, {useMemo, memo} from "../../../../_snowpack/pkg/react.js";
import {VariableSizeList} from "../../../../_snowpack/pkg/react-window.js";
import memoize from "../../../../_snowpack/pkg/memoize-one.js";
import Autosizer from "../../../../_snowpack/pkg/react-virtualized-auto-sizer.js";
const getItemSizeGetter = (itemSize) => {
  if (typeof itemSize !== "number") {
    return itemSize;
  }
  return () => itemSize;
};
export function VirtualizedList({
  itemCount,
  itemSize,
  children,
  itemData,
  onScroll
}) {
  const itemSizeGetter = useMemo(() => getItemSizeGetter(itemSize), [itemSize]);
  const getItemData = memoize((data, children2) => ({
    ...data,
    children: children2
  }));
  return /* @__PURE__ */ React.createElement(Autosizer, null, ({width, height}) => {
    return /* @__PURE__ */ React.createElement(VariableSizeList, {
      height,
      width,
      itemCount,
      itemSize: itemSizeGetter,
      itemData: getItemData(itemData, children),
      onScroll
    }, Row);
  });
}
;
const Row = memo(({data: {
  children,
  ...data
}, index, style}) => {
  return /* @__PURE__ */ React.createElement("div", {
    key: index,
    style
  }, children({index, data}));
});
