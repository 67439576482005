import React from "../../../../../_snowpack/pkg/react.js";
import {Switch, Redirect, Route, useRouteMatch} from "../../../../../_snowpack/pkg/react-router-dom.js";
import styled from "../../../../../_snowpack/pkg/@emotion/styled.js";
import {notFoundImageSrc} from "./assets/not-found.js";
const FourOhFourContainer = styled.div`
  label: FourOhFourContainer;
  padding: 40px;
  text-align: center;
`;
const FourOhFour = () => /* @__PURE__ */ React.createElement(FourOhFourContainer, null, /* @__PURE__ */ React.createElement("h1", null, "Page Not Found"));
const Routes = ({
  stories,
  render
}) => /* @__PURE__ */ React.createElement(Switch, null, /* @__PURE__ */ React.createElement(RouteRender, {
  stories,
  render
}), /* @__PURE__ */ React.createElement(Route, {
  path: "*",
  render: () => /* @__PURE__ */ React.createElement(FourOhFour, null)
}));
const isBranch = (story) => "stories" in story;
const RouteRender = ({
  stories,
  render
}) => {
  const {path} = useRouteMatch();
  const storiesWithSlugs = stories.map((story) => {
    const slug = `${path}${story.slug}/`;
    return {
      ...story,
      slug
    };
  });
  return /* @__PURE__ */ React.createElement(Switch, null, storiesWithSlugs.map((story, key) => {
    const slug = story.slug;
    if (isBranch(story)) {
      return /* @__PURE__ */ React.createElement(Route, {
        path: slug,
        key
      }, /* @__PURE__ */ React.createElement(RouteRender, {
        stories: story.stories,
        render
      }));
    }
    return /* @__PURE__ */ React.createElement(Route, {
      path: slug,
      key,
      render: (props) => render(props, story)
    });
  }), /* @__PURE__ */ React.createElement(Redirect, {
    exact: true,
    from: "/",
    to: stories[0].slug
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "*",
    render: (props) => render(props, {
      content: () => /* @__PURE__ */ React.createElement(NotFound, null)
    })
  }));
};
const NotFoundContainer = styled.div`
  label: NotFoundContainer;
  padding: 40px;
`;
const NotFound = () => /* @__PURE__ */ React.createElement(NotFoundContainer, null, /* @__PURE__ */ React.createElement("h1", null, "404"), /* @__PURE__ */ React.createElement("p", null, "URL not found"), /* @__PURE__ */ React.createElement("img", {
  src: notFoundImageSrc
}));
export default Routes;
