import {useCallback, useEffect, useRef} from "../../../../_snowpack/pkg/react.js";
export const useTimer = (dependencies) => {
  const timer = useRef();
  const set = (fn, duration) => {
    timer.current = window.setTimeout(fn, duration);
  };
  const clear = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
  }, []);
  useEffect(() => {
  }, [dependencies]);
  return [set, clear];
};
