import {useContext} from "../../../_snowpack/pkg/react.js";
import {AuthContext} from "../containers/App/Auth/Auth.js";
const errMessage = [
  "User is not defined.",
  "The useUser hook can only be instantiated within a logged-in component."
].join(" ");
export const useUser = () => {
  const {user} = useContext(AuthContext);
  if (user === void 0) {
    throw new Error(errMessage);
  }
  return [user];
};
