import React, {useEffect, useState, useRef, useMemo} from "../../../../_snowpack/pkg/react.js";
import styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import {
  constants,
  StatusDot,
  Loading,
  usePebble,
  usePoll,
  Ellipsis
} from "../../../../dist/lib/index.js";
import {SOVAppInvocationsTableWrapper} from "./SOVAppInvocationsTableWrapper/SOVAppInvocationsTableWrapper.js";
import {useDebounce} from "./useDebounce.js";
import {SOVAppInvocationsTableContainer} from "./SOVAppInvocationsTableContainer.js";
import {getDuration, getFormattedDate} from "./SOVAppInvocationsTableWrapper/utils.js";
import {Link, useRouteMatch} from "../../../../_snowpack/pkg/react-router-dom.js";
import {Highlight} from "./SOVAppInvocationsTableWrapper/Highlight.js";
const {GRAY} = constants;
export const Started = ({
  started
}) => {
  const [formattedStarted, setFormattedStarted] = useState(getFormattedDate(started));
  let timer = useRef();
  const resetFormattedStarted = () => {
    timer.current = window.setTimeout(() => {
      setFormattedStarted(getFormattedDate(started));
      resetFormattedStarted();
    }, 500);
  };
  useEffect(() => {
    resetFormattedStarted();
    return () => clearTimeout(timer.current);
  }, [started]);
  return /* @__PURE__ */ React.createElement(Ellipsis, null, formattedStarted);
};
const StatusDotContainer = styled.div`
  label: StatusDotContainer;
  width: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -3px;
`;
const StyledLink = styled(Link)`
  label: StyledLink;
  display: flex;
  width: 100%;
  justify-content: center;
`;
const Td = styled.div`
  label: Td;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const SEARCH_TIME = 400;
export function SOVAppInvocationsTable({
  params,
  update,
  gizmoName,
  columns = [],
  rowSize,
  getName,
  routes,
  useExactColumns,
  getWidth,
  filters,
  filterInvocations,
  getStringsToCheckDuringFiltering,
  data,
  showUpload,
  pollingFrequency,
  showSearch,
  searchTime = SEARCH_TIME,
  getFilteredInvocations,
  getInvocations
}) {
  const [search, setSearch, waitingToSearch] = useDebounce(params?.search, searchTime);
  const [invocations, setInvocations] = useState();
  useEffect(() => {
    if (search !== void 0) {
      update({
        search
      });
    }
  }, [search]);
  const [refreshed, setRefreshed] = useState();
  const [_, getGizmoRuns] = usePebble(gizmoName);
  const [_invocations] = usePoll(async function pollForGetGizmoRuns() {
    const resp = await getGizmoRuns({sort: "started", sortDir: "desc"});
    setRefreshed(new Date());
    return resp;
  }, [getGizmoRuns], {duration: pollingFrequency});
  useEffect(() => {
    setInvocations(_invocations?.map((invocation, i) => {
      const updateAttributes = async (attbs) => {
        try {
          await invocation.updateAttributes(attbs);
          setInvocations([
            ..._invocations.slice(0, i),
            {
              ...invocation,
              attributes: {
                ...invocation.attributes,
                ...attbs
              },
              updateAttributes
            },
            ..._invocations.slice(i + 1)
          ]);
        } catch (err) {
        }
      };
      return {
        ...invocation,
        updateAttributes
      };
    }));
  }, [_invocations]);
  useEffect(() => {
    if (useExactColumns && (columns === void 0 || columns.length === 0)) {
      console.warn("Columns is undefined or empty, meaning the table will be blank.");
    }
  }, [columns]);
  const filteredInvocations = useMemo(() => {
    return invocations?.filter((invocation, i) => {
      if (filterInvocations) {
        const shouldInclude = filterInvocations(invocation);
        if (!shouldInclude) {
          return false;
        }
      }
      const attributes = Object.values(invocation.attributes);
      const stringsToCheck = getStringsToCheckDuringFiltering ? getStringsToCheckDuringFiltering(invocation) : [
        invocation.user,
        invocation.status,
        ...attributes
      ].filter((s) => typeof s === "string");
      return !search || stringsToCheck.reduce((match, comparison) => {
        if (match === true) {
          return true;
        }
        const result = new RegExp(search, "i").test(`${comparison}`);
        return result;
      }, false);
    });
  }, [columns, JSON.stringify(invocations), search, filterInvocations]);
  useEffect(() => {
    if (getFilteredInvocations && filteredInvocations) {
      getFilteredInvocations(filteredInvocations);
    }
  }, [getFilteredInvocations, filteredInvocations?.map((i) => i.key)?.join(",")]);
  useEffect(() => {
    if (getInvocations && invocations) {
      getInvocations(invocations);
    }
  }, [getInvocations, invocations]);
  const {url} = useRouteMatch();
  const rows = useMemo(() => {
    return filteredInvocations?.map((invocation, i) => {
      const duration = getDuration(invocation);
      const processing = invocation.status === "running" || invocation.status === "created";
      const name = getName ? getName(invocation) : "";
      if (useExactColumns) {
        return columns.map(({content}) => {
          const value = content({invocation, search});
          if (typeof value === "string") {
            return /* @__PURE__ */ React.createElement(Td, null, /* @__PURE__ */ React.createElement(Highlight, {
              value,
              search
            }));
          }
          return /* @__PURE__ */ React.createElement(Td, null, value);
        });
      }
      return [
        /* @__PURE__ */ React.createElement(Td, {
          noOverflow: true
        }, /* @__PURE__ */ React.createElement(StyledLink, {
          to: `${url}/${invocation.key}`
        }, /* @__PURE__ */ React.createElement(StatusDotContainer, null, /* @__PURE__ */ React.createElement(StatusDot, {
          pulse: processing,
          color: processing ? void 0 : GRAY.lighten(0.5).toString()
        })), /* @__PURE__ */ React.createElement(Ellipsis, null, /* @__PURE__ */ React.createElement(Highlight, {
          value: name,
          search
        })))),
        /* @__PURE__ */ React.createElement(Td, null, /* @__PURE__ */ React.createElement(Ellipsis, null, /* @__PURE__ */ React.createElement(Highlight, {
          value: invocation.status,
          search
        }))),
        ...columns.map(({content}) => {
          if (typeof content === "string") {
            return /* @__PURE__ */ React.createElement(Td, null, /* @__PURE__ */ React.createElement(Ellipsis, null, /* @__PURE__ */ React.createElement(Highlight, {
              value: content,
              search
            })));
          }
          return /* @__PURE__ */ React.createElement(Td, null, /* @__PURE__ */ React.createElement(Ellipsis, null, content({invocation})));
        }),
        /* @__PURE__ */ React.createElement(Td, {
          title: `${invocation.started}`
        }, /* @__PURE__ */ React.createElement(Started, {
          started: invocation.started
        })),
        /* @__PURE__ */ React.createElement(Td, null, /* @__PURE__ */ React.createElement(Ellipsis, null, duration)),
        /* @__PURE__ */ React.createElement(Td, null, invocation.user && /* @__PURE__ */ React.createElement(Ellipsis, null, /* @__PURE__ */ React.createElement(Highlight, {
          value: invocation.user,
          search
        })))
      ];
    });
  }, [filteredInvocations, data]);
  if (filteredInvocations === void 0 || invocations === void 0) {
    return /* @__PURE__ */ React.createElement(Loading, {
      label: "Loading invocations",
      flex: true,
      fadeIn: 100
    });
  }
  return /* @__PURE__ */ React.createElement(SOVAppInvocationsTableContainer, {
    search,
    setSearch,
    waitingToSearch,
    showSearch: showSearch && invocations.length > 0,
    routes,
    filters,
    refreshed,
    showUpload
  }, /* @__PURE__ */ React.createElement(SOVAppInvocationsTableWrapper, {
    rowSize,
    routes,
    rows,
    allRows: invocations.length,
    columns,
    useExactColumns,
    getWidth
  }));
}
;
