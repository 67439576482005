import React from "../../../../../_snowpack/pkg/react.js";
import styled from "../../../../../_snowpack/pkg/@emotion/styled.js";
import {Tooltip} from "../../../../../dist/lib/index.js";
import {FiAlertCircle} from "../../../../../_snowpack/pkg/react-icons/fi.js";
const AlertContainer = styled.div`
  label: Alert;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;

  svg {
    color: rgba(255, 0, 0, 0.8);
    position: absolute;
    bottom: 5px;
    right: 5px;
  }
`;
const Alert = ({error, over}) => {
  return /* @__PURE__ */ React.createElement(AlertContainer, null, /* @__PURE__ */ React.createElement(FiAlertCircle, null), over && /* @__PURE__ */ React.createElement(Tooltip, null, /* @__PURE__ */ React.createElement("div", null, typeof error === "string" ? error : JSON.stringify(error))));
};
export default Alert;
