export const prepareFileForPut = (content, uploadOpts) => {
  if (content === void 0) {
    return void 0;
  }
  if (typeof content === "string") {
    return new File([content], uploadOpts?.name || "");
  }
  try {
    if (content instanceof File) {
      return content;
    }
  } catch (err) {
  }
  return new File([JSON.stringify(content)], uploadOpts?.name || "");
};
