import React, {useMemo, memo, useRef, useEffect, createRef, createContext, useContext} from "../../../../_snowpack/pkg/react.js";
import {VariableSizeGrid, areEqual} from "../../../../_snowpack/pkg/react-window.js";
import memoize from "../../../../_snowpack/pkg/memoize-one.js";
import Autosizer from "../../../../_snowpack/pkg/react-virtualized-auto-sizer.js";
import styled from "../../../../_snowpack/pkg/@emotion/styled.js";
const getItemSizeGetter = (itemSize) => {
  if (typeof itemSize !== "number") {
    return itemSize;
  }
  return () => itemSize;
};
const Thead = styled.div`
  label: Thead;
  width: ${({width}) => width}px;
  position: sticky;
  top: 0;
  height: 21px;
  z-index: 1;
`;
const Th = styled.div`
  label: Th;
  width: ${({width}) => width}px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: ${({left}) => left}px;
  top: 0;
  background: #f5f5f5;
  color: #999;
  border: 1px solid #ececec;
  border-right: none;
  border-bottom: none;
  text-align: center;
  height: 100%;
  transition: height ease 0.3s, top ease 0.3s, width ease 0.3s, left ease 0.3s;
  padding: 0 2px;

  label {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: bold;

  }
`;
const Tbody = styled.div`
  label: Tbody;
  position: relative;
`;
const GridContext = createContext({
  columnSizeGetter: () => 80
});
export function VirtualizedGrid({
  rowCount,
  columnCount,
  columnWidth,
  rowHeight,
  children,
  itemData,
  columns,
  getRef,
  width,
  height
}) {
  const gridRef = useRef(null);
  useEffect(() => {
    if (getRef) {
      getRef(gridRef);
    }
  }, [gridRef, getRef]);
  const columnSizeGetter = useMemo(() => {
    return getItemSizeGetter(columnWidth);
  }, [width, columnWidth]);
  const rowSizeGetter = useMemo(() => getItemSizeGetter(rowHeight), [height, rowHeight]);
  const getItemData = memoize((data, children2) => ({
    ...data,
    children: children2
  }));
  const headers = useRef((columns || []).map(() => createRef()));
  useEffect(() => {
    let left = 0;
    headers.current.map((header) => header.current).forEach((header, i) => {
      const colWidth = columnSizeGetter(i);
      if (header) {
        header.style = `width: ${colWidth}px; left: ${left}px`;
      }
      left += colWidth;
    });
    if (gridRef.current) {
      gridRef.current.resetAfterColumnIndex(0);
    }
  }, [gridRef, columnSizeGetter]);
  useEffect(() => {
    if (gridRef.current) {
      gridRef.current.resetAfterRowIndex(0);
    }
  }, [gridRef, rowSizeGetter]);
  const innerElementType = useMemo(() => ({style, children: children2}) => {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(InnerElementTypeHeader, {
      style,
      headers
    }), /* @__PURE__ */ React.createElement(Tbody, {
      style: {
        ...style
      }
    }, children2));
  }, [width]);
  const gridContextValue = {
    columns,
    columnSizeGetter
  };
  if (width !== void 0 && height !== void 0) {
    return /* @__PURE__ */ React.createElement(GridContext.Provider, {
      value: gridContextValue
    }, /* @__PURE__ */ React.createElement(VariableSizeGrid, {
      ref: gridRef,
      height,
      width,
      rowCount,
      rowHeight: rowSizeGetter,
      columnCount,
      columnWidth: columnSizeGetter,
      itemData: getItemData(itemData, children),
      innerElementType
    }, GridCell));
  }
  return /* @__PURE__ */ React.createElement(GridContext.Provider, {
    value: gridContextValue
  }, /* @__PURE__ */ React.createElement(Autosizer, null, ({width: measuredWidth, height: measuredHeight}) => {
    return /* @__PURE__ */ React.createElement(VariableSizeGrid, {
      ref: gridRef,
      height: measuredHeight,
      width: measuredWidth,
      rowCount,
      rowHeight: rowSizeGetter,
      columnCount,
      columnWidth: columnSizeGetter,
      itemData: getItemData(itemData, children),
      innerElementType
    }, GridCell);
  }));
}
;
const GridCellContainer = styled.div`
  label: GridCellContainer;
  transition: height ease 0.3s, top ease 0.3s, width ease 0.3s, left ease 0.3s;
`;
const GridCell = memo(({data: {
  children,
  lefts,
  ...data
}, columnIndex, rowIndex, style}) => {
  return /* @__PURE__ */ React.createElement(GridCellContainer, {
    key: `${rowIndex}-${columnIndex}`,
    style: {
      ...style
    }
  }, children({columnIndex, rowIndex, data}));
}, areEqual);
const InnerElementTypeHeader = ({style, headers}) => {
  const {columns, columnSizeGetter} = useContext(GridContext);
  const columnElements = [];
  if (columns) {
    let left = 0;
    for (let i = 0; i < columns?.length; i++) {
      const colWidth = columnSizeGetter(i);
      columnElements.push(/* @__PURE__ */ React.createElement(React.Fragment, {
        key: i
      }, /* @__PURE__ */ React.createElement(Th, {
        key: i,
        left,
        width: colWidth,
        ref: headers.current[i]
      }, /* @__PURE__ */ React.createElement("label", null, columns[i]))));
      left += colWidth;
    }
  }
  if (!columns) {
    return null;
  }
  return /* @__PURE__ */ React.createElement(Thead, {
    width: style.width
  }, columnElements);
};
