import React, {useMemo, useState} from "../../../../../../_snowpack/pkg/react.js";
import styled from "../../../../../../_snowpack/pkg/@emotion/styled.js";
import {format} from "../../../../../../_snowpack/pkg/date-fns.js";
import {Button} from "../../../../../../dist/lib/index.js";
import {BiDownload} from "../../../../../../_snowpack/pkg/react-icons/bi.js";
import {LinkDownload} from "./LinkDownload.js";
const Container = styled.div`
  label: MappingReviewFooter;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 20px;
  width: calc(100% + 40px);
  margin-left: -20px;
  box-shadow: 0 -2px 4px rgba(0,0,0,0.10);
  position: relative;
  background: white;
`;
const Row = styled.div`
  label: Row;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
`;
const Left = styled.div`
  label: Left;
  flex: 1;
`;
const ButtonContainer = styled.div`
  label: ButtonContainer;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
`;
export const MappingReviewFooter = ({
  canSubmitChanges,
  downloadDataFile,
  submitMappings,
  completedAt
}) => {
  const [loading, setLoading] = useState(false);
  const _completedAt = useMemo(() => {
    if (completedAt) {
      setLoading(false);
      const time = format(new Date(completedAt), "MMM do h:mm:ss a");
      return `Submitted at ${time}`;
    }
    return "";
  }, [completedAt]);
  return /* @__PURE__ */ React.createElement(Container, null, /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Left, null, /* @__PURE__ */ React.createElement(LinkDownload, {
    href: "#",
    onClick: downloadDataFile
  }, "Download Data File")), /* @__PURE__ */ React.createElement(ButtonContainer, null, /* @__PURE__ */ React.createElement(Button, {
    leftIcon: /* @__PURE__ */ React.createElement(BiDownload, null),
    isLoading: loading,
    onClick: () => {
      setLoading(true);
      submitMappings();
    },
    disabled: !canSubmitChanges
  }, "Submit Mappings"), /* @__PURE__ */ React.createElement("span", {
    style: {fontSize: 10, height: 12, display: "block"}
  }, loading === false && _completedAt))));
};
