import React, {useCallback} from "../../../_snowpack/pkg/react.js";
import styled from "../../../_snowpack/pkg/@emotion/styled.js";
import ReactToggle from "../../../_snowpack/pkg/react-toggle.js";
import "../../../_snowpack/pkg/react-toggle/style.css";
const ToggleContainer = styled.div`
  label: ToggleContainer;
  display: flex;
  flex-direction: ${({flexDirection}) => flexDirection};
  // margin: 0 10px;
  margin: 0;
  label {
    cursor: pointer;
  }

  input {
    display: none;
  }
`;
const getToggleFn = (update, value, label) => () => {
  const nextValue = value ? "off" : "on";
  update({
    [label]: nextValue
  });
};
const isValidValue = (val) => ["off", "on"].includes(val);
export const useToggleFns = ({
  params,
  update,
  label,
  defaultValue = "on"
}) => {
  const value = isValidValue(params[label]) ? params[label] : defaultValue;
  const checked = value === "on";
  const fn = useCallback(getToggleFn(update, checked, label), [checked, update]);
  return {
    value: checked,
    fn
  };
};
export const Toggle = ({
  name,
  label,
  checked,
  handleChange,
  flexDirection = "column"
}) => {
  return /* @__PURE__ */ React.createElement(ToggleContainer, {
    flexDirection
  }, /* @__PURE__ */ React.createElement("label", {
    htmlFor: name
  }, label), /* @__PURE__ */ React.createElement(ReactToggle, {
    id: name,
    name,
    checked,
    onChange: handleChange
  }));
};
