import React, {useContext, useEffect, useState} from "../../../../../_snowpack/pkg/react.js";
import styled from "../../../../../_snowpack/pkg/@emotion/styled.js";
import {formatDistanceToNow} from "../../../../../_snowpack/pkg/date-fns.js";
import {ConfigContext} from "../../../Config.js";
import {LogoutButton} from "../../App/Auth/Auth.js";
import {Stories} from "./Stories.js";
import {usePoll} from "../../../hooks/index.js";
import {useActiveUsers} from "../../../hooks/internals/useActiveUsers.js";
import {RED} from "../../../constants.js";
import {useMemo} from "../../../../../_snowpack/pkg/react.js";
export const ROW_HEIGHT = 24;
const ActiveUsers = styled.span`
  label: ActiveUsers;
  color: rgba(0,0,0,0.4);
`;
const NumberOfUsersContainer = styled.div`
  label: NumberOfUsersContainer;
  font-size: 10px;
`;
const SystemError = styled.span`
  label: SystemError;
  color: ${RED.alpha(0.6).toString()};
  text-transform: uppercase;
`;
const Container = styled.div`
  label: Sidebar;

  display: flex;
  flex-direction: column;
  height: 100%;
  width: 210px;
  position: absolute;
  overflow-y: scroll;

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }
`;
const Administration = styled.div`
  label: Administration;
  padding: 0 20px 20px 20px;
  display: flex;
`;
const Version = styled.span`
  label: Version;
  opacity: 0.4;
`;
const Built = styled.span`
  label: Built;
  font-size: 10px;
  letter-spacing: 0.1;
  color: rgba(0,0,0,0.3);
  font-weight: normal;
  padding-top: 2px;
  display: block;
`;
const SidebarTitle = styled.h1`
  label: SidebarTitle;

  && {
    font-size: 14px;
    letter-spacing: .5px;
    font-weight: 900;
    padding: 28px 20px 20px 20px;

  a {
    text-decoration: none;
    color: inherit;
    min-height: 28px;
  }
}
`;
const Left = styled.div`
  label: Left;
  display: flex;
  flex: 1;
`;
const Right = styled.div`
  label: Right;
  display: flex;
  flex: 1;
  align-items: flex-end;
  justify-content: flex-end;
  text-align: right;
`;
const isDate = (date) => typeof date !== null;
const getDate = (date) => {
  if (date && isDate(date)) {
    return formatDistanceToNow(date, {
      addSuffix: true
    });
  }
  return null;
};
export const Sidebar = ({stories}) => {
  const {
    BUILT_AT,
    VERSION,
    ENV
  } = useContext(ConfigContext);
  const [built, setBuilt] = useState(getDate(BUILT_AT));
  const env = useMemo(() => ENV.charAt(0).toUpperCase() + ENV.slice(1), [ENV]);
  let timer;
  const updateTime = () => {
    setBuilt(getDate(BUILT_AT));
    timer = window.setTimeout(updateTime, 500);
  };
  useEffect(() => {
    updateTime();
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return /* @__PURE__ */ React.createElement(Container, {
    "data-testid": "sidebar"
  }, /* @__PURE__ */ React.createElement(SidebarTitle, null, /* @__PURE__ */ React.createElement("a", {
    href: "/"
  }, "Alpha | ", env, " ", /* @__PURE__ */ React.createElement(Version, null, VERSION), /* @__PURE__ */ React.createElement("br", null), built && /* @__PURE__ */ React.createElement(Built, null, "Built ", built))), /* @__PURE__ */ React.createElement(Stories, {
    stories
  }), /* @__PURE__ */ React.createElement(Administration, null, /* @__PURE__ */ React.createElement(Left, null, /* @__PURE__ */ React.createElement(NumberOfUsersContainer, null, /* @__PURE__ */ React.createElement(NumberOfUsers, null))), /* @__PURE__ */ React.createElement(Right, null, /* @__PURE__ */ React.createElement(LogoutButton, null))));
};
const NumberOfUsers = () => {
  const [getActiveUsers] = useActiveUsers();
  const [
    result
  ] = usePoll(async () => {
    try {
      const activeUsers = await getActiveUsers({lastActiveMinutesAgo: 15});
      return {
        activeUsers,
        error: void 0
      };
    } catch (error) {
      console.error(error);
      return {
        activeUsers: void 0,
        error
      };
    }
  }, [getActiveUsers], {
    duration: 6e4
  });
  if (result?.error) {
    return /* @__PURE__ */ React.createElement(SystemError, {
      title: JSON.stringify(result.error)
    }, "System Error");
  }
  if (!result?.activeUsers) {
    return null;
  }
  const title = result.activeUsers.map((user) => {
    return [user.payload.name, formatDistanceToNow(user.lastActivity, {
      addSuffix: true
    })].join(", ");
  }).join("\n");
  return /* @__PURE__ */ React.createElement(ActiveUsers, {
    title
  }, result.activeUsers.length, " active user", result.activeUsers.length === 1 ? "" : "s");
};
