import {useCallback} from "../../../_snowpack/pkg/react.js";
import {prepareFile} from "./internals/prepareFile.js";
import {prepareFileForPut} from "./internals/prepareFileForPut.js";
import {prepareGetContent} from "./internals/prepareGetContent.js";
import {prepareKeysForServer} from "./internals/prepareKeysForServer.js";
import {uploadOrEditFile} from "./internals/uploadOrEditFile.js";
import {useBackend} from "./internals/useBackend.js";
export const useFolder = (key, {bucket} = {}) => {
  const [request] = useBackend();
  const listFiles = useCallback(async (opts = {}, requestParams = {}) => {
    if (opts.perPage !== void 0 && opts.perPage > 100) {
      console.warn("Maximum perPage value is 100.");
    }
    const params = prepareKeysForServer({
      ...opts,
      bucket
    });
    const resp = await request({
      url: `folders/${key}?${params}`
    }, requestParams);
    const data = await resp.json();
    return data.map((file) => prepareFile(file, prepareGetContent(request, file.key, file.bucket)));
  }, [key, bucket]);
  const uploadFile = useCallback(async (file, fileKey, uploadOpts) => {
    const fullKey = [key, fileKey].filter(Boolean).join("/");
    const preparedFile = prepareFileForPut(file, uploadOpts);
    const getContent = prepareGetContent(request, fullKey, bucket);
    return await uploadOrEditFile(request, preparedFile, {
      key: fullKey,
      bucket,
      getContent
    }, uploadOpts);
  }, [key, bucket]);
  return [listFiles, uploadFile];
};
