import {useCallback, useEffect, useRef, useState} from "../../../../_snowpack/pkg/react.js";
export function useDebounce(initialValue, amount) {
  const [data, setData] = useState(initialValue);
  const [waiting, setWaiting] = useState(false);
  const timer = useRef(null);
  const debouncedSetData = useCallback((nextData) => {
    setWaiting(true);
    if (timer.current !== null) {
      window.clearTimeout(timer.current);
    }
    timer.current = window.setTimeout(() => {
      setData(nextData);
      setWaiting(false);
    }, amount);
  }, []);
  useEffect(() => {
    return () => {
      if (timer.current !== null) {
        window.clearTimeout(timer.current);
      }
    };
  }, []);
  return [data, debouncedSetData, waiting];
}
