import React from "../../../../../_snowpack/pkg/react.js";
import styled from "../../../../../_snowpack/pkg/@emotion/styled.js";
const Span = styled.span`
  label: span;

  ${({highlight}) => highlight ? `
    color: red;
    text-decoration: underline;
  ` : ""}
`;
;
export const Highlight = ({value, search}) => {
  if (!search) {
    return /* @__PURE__ */ React.createElement(Span, null, value);
  }
  const r = new RegExp(`${search}(.+)?`, "i");
  const strValue = `${value}`;
  const match = r.test(strValue);
  if (!match) {
    return /* @__PURE__ */ React.createElement(Span, null, value);
  }
  const parts = strValue.split(r);
  const searchString = strValue.substring(parts[0].length, parts[0].length + search.length);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Span, {
    key: 0
  }, parts[0]), /* @__PURE__ */ React.createElement(Span, {
    key: 1,
    highlight: true
  }, searchString), parts.slice(1).map((part, key) => /* @__PURE__ */ React.createElement(Span, {
    key
  }, part)));
};
