import {VirtualizedList} from "../Virtualized/VirtualizedList.js";
import React from "../../../../_snowpack/pkg/react.js";
import TableRow from "./TableRow.js";
import styled from "../../../../_snowpack/pkg/@emotion/styled.js";
const Container = styled.div`
  label: TableV2;
  max-width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
`;
const Thead = styled.div`
  label: Thead;
  display: flex;
  border-bottom: 1px solid rgba(0,0,0,0.12);
  height: 45px;
`;
const Tbody = styled.div`
  display: Tbody;
  flex: 1;
`;
const Th = styled.div`
  label: Thead;
  font-weight: bold;
  height: 45px;
  display: flex;
  align-items: center;
  ${({width}) => width ? `width: ${width}px;` : "flex: 1;"}

  padding: 0 ${({applyPadding}) => applyPadding ? 5 : 0}px;
  &:first-of-type {
    padding-left: 20px;
  }
`;
export function Table({
  head,
  rows,
  rowSize = 45,
  widths,
  children,
  applyPadding = true,
  trStyle,
  theadStyle,
  style
}) {
  return /* @__PURE__ */ React.createElement(Container, {
    style
  }, head && /* @__PURE__ */ React.createElement(Thead, {
    style: theadStyle
  }, head.map((datum, index) => /* @__PURE__ */ React.createElement(Th, {
    applyPadding,
    key: index,
    width: widths?.[index]
  }, datum))), /* @__PURE__ */ React.createElement(Tbody, null, /* @__PURE__ */ React.createElement(VirtualizedList, {
    itemCount: rows.length,
    itemSize: rowSize,
    itemData: {
      data: rows,
      widths,
      children,
      applyPadding,
      trStyle
    }
  }, TableRow)));
}
;
