import React, {useCallback, useEffect, useMemo, useState} from "../../../../../_snowpack/pkg/react.js";
import styled from "../../../../../_snowpack/pkg/@emotion/styled.js";
import {FiArchive, FiXCircle} from "../../../../../_snowpack/pkg/react-icons/fi.js";
import {RiInboxUnarchiveLine} from "../../../../../_snowpack/pkg/react-icons/ri.js";
import {FaTrashRestore} from "../../../../../_snowpack/pkg/react-icons/fa.js";
import {format} from "../../../../../_snowpack/pkg/date-fns.js";
import {useStory, constants, useDownloadFile, useS3, usePebble, Button, Loading} from "../../../../../dist/lib/index.js";
import {MappingReview} from "./MappingReview/MappingReview.js";
import {NAMESPACE, ROUTES} from "../config.js";
import {Link, Route, Switch, useRouteMatch} from "../../../../../_snowpack/pkg/react-router-dom.js";
import {SOVAppInvocationPage} from "../../../../components/SOVAppInvocations/SOVAppInvocationPage/SOVAppInvocationPage.js";
import {BiDownload} from "../../../../../_snowpack/pkg/react-icons/bi.js";
import {Highlight} from "../../../../components/SOVAppInvocations/SOVAppInvocationsTable/SOVAppInvocationsTableWrapper/Highlight.js";
import {VerifyUploadsTable} from "../components/VerifyUploadsTable/VerifyUploadsTable.js";
const {
  BLUE
} = constants;
const ButtonContainer = styled.div`
  label: ButtonContainer;
  width: 100%;
  display: flex;
  flex: 1;

  button {
    flex: 1;
  }
`;
const TdWrapper = styled.div`
  label: TdWrapper;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({deleted}) => deleted ? `
    text-decoration: line-through;
    background: rgba(0,0,0,0.3);
  ` : ""}
`;
export const VerifySOVs = () => {
  const {params, update} = useStory();
  const {url} = useRouteMatch();
  const rootURL = `/${url.split("/").filter(Boolean).slice(0, -1).join("/")}`;
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Switch, null, /* @__PURE__ */ React.createElement(Route, {
    exact: true,
    path: `${rootURL}/${ROUTES.editor}`
  }, /* @__PURE__ */ React.createElement(VerifyUploadsTable, {
    startColumns: [
      {
        label: "Client",
        content: ({invocation, search}) => {
          return /* @__PURE__ */ React.createElement(TdWrapper, {
            deleted: invocation.attributes.deleted
          }, invocation.attributes.deleted ? /* @__PURE__ */ React.createElement(Highlight, {
            value: invocation.attributes.client,
            search
          }) : /* @__PURE__ */ React.createElement(Link, {
            to: `${url}/${invocation.key}`
          }, /* @__PURE__ */ React.createElement(Highlight, {
            value: invocation.attributes.client,
            search
          })));
        }
      }
    ],
    endColumns: [
      {
        label: "Download",
        content: ({invocation}) => {
          const {status} = invocation.attributes;
          if (invocation.attributes.deleted) {
            return "-";
          }
          if (status === "completed" || status === "downloaded") {
            return /* @__PURE__ */ React.createElement(DownloadSpreadsheet, {
              invocation
            });
          }
          return /* @__PURE__ */ React.createElement(TdWrapper, {
            deleted: invocation.attributes.deleted
          }, "Not yet submitted");
        }
      },
      {
        label: "Archive",
        content: ({invocation}) => {
          return /* @__PURE__ */ React.createElement(Icon, {
            fn: () => invocation.updateAttributes({
              archived: !invocation.attributes.archived
            }),
            invocation,
            label: "Archiving invocation",
            color: BLUE.toString()
          }, invocation.attributes.archived ? /* @__PURE__ */ React.createElement(RiInboxUnarchiveLine, {
            size: 20,
            title: "Unarchive"
          }) : /* @__PURE__ */ React.createElement(FiArchive, {
            size: 20,
            title: "Archive"
          }));
        }
      },
      {
        label: "Remove",
        content: ({invocation}) => {
          return /* @__PURE__ */ React.createElement(Icon, {
            fn: () => invocation.updateAttributes({
              deleted: !invocation.attributes.deleted
            }),
            invocation,
            label: "Removing invocation",
            color: "red"
          }, invocation.attributes.deleted ? /* @__PURE__ */ React.createElement(FaTrashRestore, {
            size: 20,
            title: "Restore"
          }) : /* @__PURE__ */ React.createElement(FiXCircle, {
            size: 20,
            title: "Delete"
          }));
        }
      }
    ]
  })), /* @__PURE__ */ React.createElement(Route, {
    path: `${rootURL}/${ROUTES.editor}/:id`,
    render: (props) => /* @__PURE__ */ React.createElement(SOVAppInvocationPage, {
      ...props,
      gizmoName: NAMESPACE
    }, ({
      invocation
    }) => {
      if (invocation) {
        return /* @__PURE__ */ React.createElement(MappingReview, {
          invocation,
          params,
          update
        });
      }
      return /* @__PURE__ */ React.createElement(Loading, {
        label: "waiting for invocation"
      });
    })
  })));
};
const DownloadSpreadsheet = ({invocation}) => {
  const [downloading, setDownloading] = useState(false);
  const [localDownloadedAt, setLocalDownloadedAt] = useState();
  const [writeSpreadsheet] = usePebble("do-verification/write_spreadsheet");
  const {name, downloadedAt, bucket, folder} = invocation.attributes;
  const originalSpreadsheet = useMemo(() => [...folder.split("/").filter(Boolean), "uploads", "sheet.xlsx"].join("/"), [folder]);
  const newSpreadsheet = useMemo(() => [...folder.split("/").filter(Boolean), "exported", "sheet.xlsx"].join("/"), [folder]);
  const [getContent] = useS3(newSpreadsheet);
  const [downloadDataFile] = useDownloadFile({getContent});
  const downloadSpreadsheet = useCallback(async () => {
    setDownloading(true);
    const _downloadedAt = new Date();
    await invocation.updateAttributes({
      status: "downloaded",
      downloadedAt: _downloadedAt.getTime()
    });
    const {result} = await writeSpreadsheet({
      bucket,
      output_folder: folder,
      original_spreadsheet: originalSpreadsheet,
      new_spreadsheet: newSpreadsheet
    });
    const {errors} = result;
    if (errors.length) {
      let error = JSON.stringify(errors);
      try {
        error = errors[0].message;
      } catch (err) {
        console.log("failed to parse errors", result);
      }
      alert(`Got error when trying to download spreadsheet: "${error}"`);
    } else {
      downloadDataFile({name: `${name.split(".").slice(0, -1).join(".")}-exported.xlsx`});
    }
    setLocalDownloadedAt(_downloadedAt);
    setDownloading(false);
  }, [invocation.updateAttributes, downloadedAt, bucket, folder, downloadDataFile, name]);
  const downloadedText = useMemo(() => {
    try {
      const d = localDownloadedAt ? localDownloadedAt : new Date(downloadedAt);
      const time = format(d, "MMM do h:mm:ss a");
      return `Downloaded ${time}`;
    } catch (err) {
    }
    return "Not yet downloaded";
  }, [localDownloadedAt, downloadedAt]);
  return /* @__PURE__ */ React.createElement(ButtonContainer, null, /* @__PURE__ */ React.createElement(Button, {
    leftIcon: /* @__PURE__ */ React.createElement(BiDownload, null),
    onClick: downloadSpreadsheet,
    isLoading: downloading,
    disabled: invocation.attributes.deleted
  }, downloading ? /* @__PURE__ */ React.createElement(Loading, {
    flex: true,
    fadeIn: 0,
    label: "Downloading spreadsheet"
  }) : /* @__PURE__ */ React.createElement("div", {
    style: {display: "flex", flexDirection: "column"}
  }, /* @__PURE__ */ React.createElement("span", null, "Download Spreadsheet"), /* @__PURE__ */ React.createElement("span", {
    style: {fontSize: 12, opacity: 0.4}
  }, downloadedText))));
};
const IconContainer = styled.div`
  label: IconContainer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  cursor: pointer;
  color: rgba(0,0,0,0.8);
  transition-duration: 0.2s;
  flex: 1;
  height: 100%;

  &:hover {
    color: ${({color}) => color};
  }
`;
const Icon = ({color, children, invocation, label, fn}) => {
  const [happening, setHappening] = useState(false);
  useEffect(() => {
    setHappening(false);
  }, [invocation.key]);
  const handleAction = useCallback(async () => {
    setHappening(true);
    await fn();
    setHappening(false);
  }, [invocation.key, invocation.attributes]);
  if (happening) {
    return /* @__PURE__ */ React.createElement(IconContainer, {
      color
    }, /* @__PURE__ */ React.createElement(Loading, {
      size: 16,
      label,
      fadeIn: 0,
      flex: true
    }));
  }
  return /* @__PURE__ */ React.createElement(IconContainer, {
    color,
    onClick: () => handleAction()
  }, children);
};
