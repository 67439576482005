import React, {
  useCallback,
  useState,
  useRef,
  useEffect
} from "../../../_snowpack/pkg/react.js";
import {
  useDropzone
} from "../../../_snowpack/pkg/react-dropzone.js";
import {Line} from "../../../_snowpack/pkg/rc-progress.js";
import styled from "../../../_snowpack/pkg/@emotion/styled.js";
import {Loading} from "./Loading.js";
import {BLUE} from "../constants.js";
const Container = styled.div`
  flex: 1;
  justify-content: center;
  position: relative;
  transition-duration: 0.2s;
`;
const DropTarget = styled.div`
  transition-duration: 0.2s;
  border: 2px dashed ${({disabled}) => disabled ? `rgba(0,0,0,0.2)` : `rgba(0,0,0,0.2)`};
  background: ${({disabled}) => disabled ? `rgba(0,0,0,0.1)` : `white`};
  padding: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
`;
const isFunction = (children) => {
  return typeof children === "function";
};
const DropTargetChildren = ({
  children,
  isDragActive,
  isDragReject,
  uploading,
  rejectedFiles,
  maxSize,
  uploadChildren,
  progress
}) => {
  if (uploading) {
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Loading, {
      label: "Uploading",
      flex: true,
      fadeIn: 0
    }, uploadChildren), /* @__PURE__ */ React.createElement(Line, {
      percent: progress,
      strokeWidth: 0.75,
      strokeColor: BLUE.toString()
    }));
  }
  if (!children) {
    const isFileTooLarge = rejectedFiles.length > 0 && maxSize !== void 0 && rejectedFiles[0].file.size > maxSize;
    if (isDragActive) {
      return /* @__PURE__ */ React.createElement("p", null, "Drop the file");
    }
    if (isFileTooLarge) {
      return /* @__PURE__ */ React.createElement("p", null, "File is too large.");
    }
    if (isDragReject) {
      return /* @__PURE__ */ React.createElement("p", null, "File type not accepted.");
    }
    return /* @__PURE__ */ React.createElement("p", null, "Drop some files, or click to select files");
  }
  if (isFunction(children)) {
    return children({
      isDragActive
    });
  }
  return /* @__PURE__ */ React.createElement(React.Fragment, null, children);
};
export const Upload = ({
  upload,
  accept,
  children,
  minSize,
  maxSize,
  uploadChildren,
  progress
}) => {
  const mounted = useRef(true);
  const [uploading, setUploading] = useState(false);
  useEffect(() => () => {
    mounted.current = false;
  }, []);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    fileRejections
  } = useDropzone({
    onDrop: useCallback(async (acceptedFiles) => {
      setUploading(true);
      try {
        await upload(acceptedFiles);
      } catch (err) {
      }
      if (mounted.current) {
        setUploading(false);
      }
    }, [upload]),
    accept,
    disabled: uploading,
    minSize,
    maxSize
  });
  useEffect(() => {
    const beforeunload = (e) => {
      if (uploading) {
        e.preventDefault();
        e.returnValue = true;
      }
    };
    window.addEventListener("beforeunload", beforeunload);
    return () => {
      window.removeEventListener("beforeunload", beforeunload);
    };
  }, []);
  return /* @__PURE__ */ React.createElement(Container, null, /* @__PURE__ */ React.createElement(DropTarget, {
    ...getRootProps(),
    disabled: uploading
  }, /* @__PURE__ */ React.createElement("input", {
    ...getInputProps(),
    disabled: uploading
  }), /* @__PURE__ */ React.createElement(DropTargetChildren, {
    uploading,
    children,
    isDragActive,
    maxSize,
    isDragReject,
    rejectedFiles: fileRejections,
    uploadChildren,
    progress
  })));
};
