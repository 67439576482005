export const DEFAULT_PEBBLE_OPTIONS = {
  "enrich-seismic-hazard": {
    label: "Seismic Hazard",
    checked: true
  },
  "enrich-quake-zone": {
    label: "Quake Zone",
    checked: true
  },
  "enrich-wild-fire-hazard": {
    label: "Wild Fire Hazard",
    checked: true
  },
  "enrich-tornado-hazard": {
    label: "Tornado Hazard",
    checked: true
  },
  "enrich-hail-hazard": {
    label: "Hail Hazard",
    checked: true
  },
  "enrich-lightning-hazard": {
    label: "Lightning Hazard",
    checked: true
  },
  "enrich-tsunami-hazard-class": {
    label: "Tsunami Hazard Class",
    checked: true
  },
  "enrich-liquefaction-susceptibility": {
    label: "Liquefaction Susceptibility",
    checked: true
  },
  "enrich-site-soil-classification": {
    label: "Site Soil Classification",
    checked: true
  },
  "enrich-distance-to-fault": {
    label: "Distance To Fault",
    checked: true
  },
  "enrich-wind-hazard": {
    label: "Wind Hazard",
    checked: true
  },
  "enrich-wind-zone": {
    label: "Wind Zone",
    checked: true
  },
  "enrich-distance-to-coast": {
    label: "Distance To Coast",
    checked: true
  },
  "geo/landslide-susceptibility": {
    label: "Landslide Susceptibility",
    checked: true
  },
  "enrich-flood-zone": {
    label: "Flood Zone Hazard & Base Flood Elevation",
    checked: true
  },
  "enrich-adjacent-grade-elevation": {
    label: "Adjacent Grade Elevation",
    checked: true
  },
  "enrich-rainfall-intensity": {
    label: "Rainfall Intensity",
    checked: true
  }
};
