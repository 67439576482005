import {useFolder, getRandomKey} from "../../../../dist/lib/index.js";
import {useMemo} from "../../../../_snowpack/pkg/react.js";
export const useUploadFile = (namespace) => {
  const name = useMemo(() => getRandomKey(), []);
  const rootFolder = `alpha/${namespace}/${name}`;
  const uploadFolder = `${rootFolder}/uploads`;
  const [_, uploadFile] = useFolder(uploadFolder);
  const propertiesJSONURL = `alpha/${namespace}/${name}/properties.json`;
  return [uploadFile, uploadFolder, propertiesJSONURL, rootFolder];
};
