import {DistanceToNearestBuildingSOVs} from "./DistanceToNearestBuildingSOVs.js";
const editorPath = "Editor";
export default {
  title: "Distance to nearest building",
  stories: [
    {
      title: "Upload",
      content: DistanceToNearestBuildingSOVs
    },
    {
      title: editorPath,
      content: DistanceToNearestBuildingSOVs
    }
  ]
};
