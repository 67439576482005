import React, {useState} from "../../../../_snowpack/pkg/react.js";
import {useStyleConfig} from "../../../../_snowpack/pkg/@chakra-ui/react.js";
import {Box} from "../../../../_snowpack/pkg/@chakra-ui/react.js";
import {BLUE} from "../../constants.js";
import styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import {useRef} from "../../../../_snowpack/pkg/react.js";
import {useEffect} from "../../../../_snowpack/pkg/react.js";
const ChildrenContainer = styled.div`
  label: ChildrenContainer;
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const getLoadingStyle = (isLoading) => isLoading ? {
  background: `
  repeating-linear-gradient(
    -45deg,
    ${BLUE.lighten(0.4).toString()},
    ${BLUE.lighten(0.4).toString()} 10px,
    ${BLUE.lighten(0.4).lighten(0.2).toString()} 10px,
    ${BLUE.lighten(0.4).lighten(0.2).toString()} 20px
  )
  `,
  backgroundSize: "200% 200%",
  color: "rgba(0,0,0,0.8)",
  textShadow: "0 1px rgba(255,255,255,0.3)",
  animationName: "slide",
  animationDuration: "10s",
  animationTimingFunction: "linear",
  animationIterationCount: "infinite"
} : {
  background: BLUE.toString(),
  color: "white"
};
export const Button = ({
  css,
  children,
  leftIcon,
  rightIcon,
  isLoading,
  spinner,
  ...props
}) => {
  const [width, setWidth] = useState(null);
  const styles = useStyleConfig("Button", {
    ...props
  });
  const buttonRef = useRef();
  useEffect(() => {
    if (buttonRef.current && isLoading) {
      const rect = buttonRef.current.getBoundingClientRect();
      setWidth(rect.width);
    } else {
      setWidth(null);
    }
  }, [isLoading, buttonRef]);
  return /* @__PURE__ */ React.createElement(Box, {
    ref: buttonRef,
    as: "button",
    sx: {
      ...styles,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width
    },
    _hover: {
      background: BLUE.lighten(0.4).toString()
    },
    ...props,
    style: getLoadingStyle(isLoading)
  }, isLoading ? void 0 : leftIcon, /* @__PURE__ */ React.createElement(ChildrenContainer, null, isLoading ? spinner : children), isLoading ? void 0 : rightIcon);
};
