import React, {useMemo} from "../../../../_snowpack/pkg/react.js";
import styled from "../../../../_snowpack/pkg/@emotion/styled.js";
import {Loading} from "../../../../dist/lib/index.js";
import {DTNBMap} from "./components/DTNBMap/DTNBMap.js";
const CellContainer = styled.div`
  label: CellContainer;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  border-right: 1px solid rgba(0,0,0,0.1);
  padding: 2px;
  flex: 1;
  height: 100%;
  position: relative;

  background-color: ${({hasError}) => hasError ? "rgba(255, 100, 100, 0.1)" : "transparent"};
`;
const getValue = (key, row, rowIndex, props) => {
  if (key === "index") {
    return rowIndex + 1;
  }
  if (key === "id") {
    return row["archipelagoId"] || row["locationId"];
  }
  const distanceToNearestBuilding = row?.userEdited;
  if (key === "distanceToNearestBuilding") {
    const distance = distanceToNearestBuilding?.distanceToNearestBuilding;
    if (distance !== void 0) {
      return `${Math.round(distance)}ft`;
    }
    return "-";
  }
  if (key === "map") {
    const {
      latitude,
      longitude
    } = row;
    const {
      setExpandedRow,
      expandedRow,
      handleChangedPin
    } = props;
    return /* @__PURE__ */ React.createElement(DTNBMap, {
      index: rowIndex,
      setExpandedRow,
      expanded: expandedRow,
      handleChangedPin: (start, end, distance) => {
        handleChangedPin(rowIndex, start, end, distance);
      },
      distanceToNearestBuilding,
      address: row,
      latitude,
      longitude
    });
  }
  return row[key] || "-";
};
export const Cell = ({
  columnIndex,
  rowIndex,
  data: cellData
}) => {
  const {
    data,
    columns,
    itemData
  } = cellData;
  const column = columns[columnIndex];
  const row = data[rowIndex];
  const key = column.key;
  if (!itemData) {
    return /* @__PURE__ */ React.createElement(Loading, {
      size: 12,
      label: "loading item data",
      flex: true
    });
  }
  const {
    value,
    hasError
  } = useMemo(() => {
    const value2 = getValue(key, row, rowIndex, itemData);
    return {
      hasError: false,
      value: value2
    };
  }, [itemData, row, key]);
  return /* @__PURE__ */ React.createElement(CellContainer, {
    lastColumn: columnIndex === columns.length - 1,
    lastRow: rowIndex === data.length - 1,
    hasError
  }, value);
};
