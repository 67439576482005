const isUnpersisted = (invocation) => {
  return !("key" in invocation);
};
const isImmediatePersisted = (invocation) => {
  return !isUnpersisted(invocation) && !("result" in invocation);
};
const getResult = (invocation) => {
  if (isImmediatePersisted(invocation)) {
    return void 0;
  }
  try {
    return JSON.parse(invocation.result);
  } catch (err) {
    return invocation.result;
  }
};
export function prepareInvocation(invocation, getUpdateAttributes) {
  if (isUnpersisted(invocation)) {
    return {
      ...invocation,
      result: getResult(invocation),
      started: new Date(invocation.started * 1e3),
      ended: invocation.ended ? new Date(invocation.ended * 1e3) : void 0
    };
  }
  if (!getUpdateAttributes) {
    throw new Error("For a persisted invocation prepare action, you must provide an updateAttributes function.");
  }
  return {
    ...invocation,
    result: getResult(invocation),
    started: new Date(invocation.started * 1e3),
    ended: invocation.ended ? new Date(invocation.ended * 1e3) : void 0,
    updateAttributes: getUpdateAttributes(invocation.key)
  };
}
