import React, {useMemo} from "../../../../_snowpack/pkg/react.js";
import {BrowserRouter as Router} from "../../../../_snowpack/pkg/react-router-dom.js";
import {ChakraProvider} from "../../../../_snowpack/pkg/@chakra-ui/react.js";
import {parseStories} from "./utils.js";
import Manager from "../Manager/Manager.js";
import Auth from "./Auth/Auth.js";
import Background from "./Background.js";
import {theme} from "./theme.js";
import "./styles/reset.css";
import "./styles/style.css";
const App = ({stories}) => {
  const parsedStories = useMemo(() => parseStories(stories), [stories]);
  return /* @__PURE__ */ React.createElement(Background, null, /* @__PURE__ */ React.createElement(ChakraProvider, {
    theme
  }, /* @__PURE__ */ React.createElement(Router, null, /* @__PURE__ */ React.createElement(Auth, null, /* @__PURE__ */ React.createElement(Manager, {
    stories: parsedStories
  })))));
};
export default App;
