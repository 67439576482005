import React, {useCallback, useEffect, useMemo} from "../../../../../_snowpack/pkg/react.js";
import {SOVAppInvocations} from "../../../../components/SOVAppInvocations/SOVAppInvocations.js";
import {usePoll, useS3, Loading} from "../../../../../dist/lib/index.js";
import SOVPropertiesTable from "../../../../components/SOVPropertiesTable/SOVPropertiesTable.js";
import UploadHazardsOptions from "./UploadHazardsOptions.js";
import {DEFAULT_PEBBLE_OPTIONS} from "../availablePebbleHazards.js";
import {COLUMN_DEFINITIONS, rowKeyForColumnKey} from "../hazards_constants.js";
import {Cell} from "./Cell.js";
import {getValueAndProvenanceFromSuccessfulResponse} from "./getValueAndProvenanceFromSuccessfulResponse.js";
const getColumns = (lambdas = []) => {
  return [
    COLUMN_DEFINITIONS.id,
    COLUMN_DEFINITIONS.streetAddress,
    COLUMN_DEFINITIONS.city,
    COLUMN_DEFINITIONS.state,
    COLUMN_DEFINITIONS.county,
    COLUMN_DEFINITIONS.country,
    COLUMN_DEFINITIONS.latitude,
    COLUMN_DEFINITIONS.longitude,
    ...[
      COLUMN_DEFINITIONS.seismicHazardClass,
      COLUMN_DEFINITIONS.quakeZone,
      COLUMN_DEFINITIONS.liquefactionSusceptibility,
      COLUMN_DEFINITIONS.wildFireHazard,
      COLUMN_DEFINITIONS.tornadoHazard,
      COLUMN_DEFINITIONS.hailHazardClass,
      COLUMN_DEFINITIONS.lightningHazard,
      COLUMN_DEFINITIONS.tsunamiHazardClass,
      COLUMN_DEFINITIONS.landslideSusceptibility,
      COLUMN_DEFINITIONS.siteSoilClassification,
      COLUMN_DEFINITIONS.distanceToFault,
      COLUMN_DEFINITIONS.nearestFaultName,
      COLUMN_DEFINITIONS.windHazard,
      COLUMN_DEFINITIONS.windZone,
      COLUMN_DEFINITIONS.distanceToCoast,
      COLUMN_DEFINITIONS.floodZone,
      COLUMN_DEFINITIONS.baseFloodElevation,
      COLUMN_DEFINITIONS.adjacentGradeElevation,
      COLUMN_DEFINITIONS.rainfallIntensity
    ].filter((col) => {
      const pebbleName = rowKeyForColumnKey[col.key];
      return lambdas.includes(pebbleName);
    }).filter(Boolean)
  ];
};
const getAllPropertiesAreCompleted = (d) => d.reduce((completed, property) => {
  if (completed === false) {
    return false;
  }
  return property.completed;
}, true);
function useInvocationPropertiesData(attributes) {
  const key = attributes.propertiesJSONURL;
  const isCompleted = attributes.progress === "complete";
  const [getContent] = useS3(key);
  const pollForGetContent = useCallback(async function pollForGetContent2() {
    if (!key) {
      return void 0;
    }
    const d = await getContent();
    return d;
  }, [key, getContent]);
  const [originalData] = usePoll(pollForGetContent, [pollForGetContent], {
    debug: false,
    until: (d) => {
      const shouldStopPolling = !!d && isCompleted && getAllPropertiesAreCompleted(d);
      return shouldStopPolling;
    },
    duration: 2e3,
    timeout: 2e3,
    shouldReset: true
  });
  useEffect(() => {
    console.log("originalData", originalData);
  }, [originalData]);
  return originalData;
}
;
export const HazardsSOVs = ({
  params,
  update
}) => {
  const lambdas = useMemo(() => params.lambdas?.split(",").filter((p) => p !== "") || Object.keys(DEFAULT_PEBBLE_OPTIONS), [params]);
  const data = useInvocationPropertiesData;
  return /* @__PURE__ */ React.createElement(SOVAppInvocations, {
    pebble: "alpha-hazards/run",
    gizmoName: "hazards",
    params,
    update,
    getName: (invocation) => invocation.attributes.name,
    showSearch: true,
    uploadChildrenHeader: /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("h1", null, "Hazards Tool"), /* @__PURE__ */ React.createElement("p", null, "Use this tool to run hazards automation for a spreadsheet of properties."), /* @__PURE__ */ React.createElement(UploadHazardsOptions, {
      pebbles: lambdas,
      setPebble: (pebble, checked) => {
        if (Array.isArray(pebble)) {
          if (checked === false) {
            update({
              lambdas: []
            });
          } else {
            update({
              lambdas: pebble.join(",")
            });
          }
        } else {
          const _nextHazardsToRun = lambdas.reduce((obj, key) => {
            if (checked === false && key === pebble) {
              return obj;
            }
            return {
              ...obj,
              [key]: key
            };
          }, {});
          if (checked === true) {
            _nextHazardsToRun[pebble] = pebble;
          }
          const nextHazardsToRun = Object.keys(_nextHazardsToRun);
          update({
            lambdas: nextHazardsToRun
          });
        }
      }
    })),
    uploadParams: {
      lambdas
    }
  }, ({
    invocation
  }) => {
    let lambdas2 = invocation?.attributes?.lambdas;
    if (!lambdas2 || lambdas2?.length === 0) {
      lambdas2 = invocation?.attributes?.specifiedLambdas;
    }
    const columns = useMemo(() => getColumns(lambdas2?.reduce((arr, lambda) => arr.concat(lambda.split("|")), [])), [lambdas2]);
    const getDownloadData = useCallback((data2) => {
      const total = data2?.length || 0;
      return [
        columns.map(({label}) => label),
        ...Array(total).fill("").map((_, i) => {
          const row = data2[i];
          if (!row) {
            return columns.map(() => "---still processing---");
          }
          return columns.map(({key}) => {
            const pebbleName = rowKeyForColumnKey[key];
            const data3 = row[pebbleName];
            if (Array.isArray(pebbleName)) {
              for (let i2 = 0; i2 < pebbleName.length; i2++) {
                const value2 = row[pebbleName[i2]];
                if (value2 !== void 0 && value2 !== null) {
                  return value2;
                }
              }
              return "";
            }
            if (!data3) {
              return "";
            }
            if (typeof data3 === "string" || typeof data3 === "number") {
              return data3;
            }
            if (data3.errorMessage) {
              return "";
            }
            if (data3.errors?.length) {
              return "";
            }
            const {value} = getValueAndProvenanceFromSuccessfulResponse(key, data3);
            return value;
          });
        })
      ];
    }, [columns]);
    if (!invocation) {
      return /* @__PURE__ */ React.createElement(Loading, {
        title: "Loading invocation",
        flex: true
      });
    }
    return /* @__PURE__ */ React.createElement(SOVPropertiesTable, {
      stretch: true,
      invocation,
      columns,
      parseRows: (rows) => rows,
      getDownloadData,
      data
    }, Cell);
  });
};
