import {parseBody} from "./parseBody.js";
export const request = async ({url, params = {}}, {endpoint, idToken, getAbortFn}) => {
  const controller = new AbortController();
  const {signal} = controller;
  getAbortFn(() => {
    controller.abort();
  });
  const body = parseBody(params.body);
  const preparedURL = `${endpoint}/${url}`;
  const resp = await fetch(preparedURL, {
    ...params,
    body,
    headers: {
      ...getContentType(body),
      ...params.headers,
      Authorization: `Bearer ${idToken}`
    },
    signal
  });
  if (resp.status >= 400) {
    throw resp;
  }
  return resp;
};
const getContentType = (body) => {
  if (body instanceof FormData) {
    return {};
  }
  return {
    "Content-Type": "application/json"
  };
};
