import React from "../../../../../_snowpack/pkg/react.js";
import styled from "../../../../../_snowpack/pkg/@emotion/styled.js";
import FullscreenIcon from "../../../../../_snowpack/pkg/@material-ui/icons/Fullscreen.js";
import FullscreenExitIcon from "../../../../../_snowpack/pkg/@material-ui/icons/FullscreenExit.js";
import FileCopyIcon from "../../../../../_snowpack/pkg/@material-ui/icons/FileCopy.js";
import {IconButton} from "../../../../../_snowpack/pkg/@chakra-ui/react.js";
const Container = styled.div`
  label: Menu;
  height: 40px;
  box-shadow: rgba(0,0,0,.1) 0 -1px 0 0 inset;
  display: flex;
  align-items: center;
  padding: 0 15px;
`;
const Right = styled.div`
  label: MenuRight;
  display: flex;
  flex-direction: row;
  overflow: hidden;
`;
const Left = styled.div`
  label: MenuLeft;
  display: flex;
  height: 100%;
  flex: 1;
  flex-direction: row;
`;
const PreviewMenu = ({
  copyURL,
  makeFullscreen,
  isFullscreen
}) => {
  return /* @__PURE__ */ React.createElement(Container, null, /* @__PURE__ */ React.createElement(Left, null), /* @__PURE__ */ React.createElement(Right, null, [
    {
      key: "fullscreen",
      action: makeFullscreen,
      icon: isFullscreen ? /* @__PURE__ */ React.createElement(FullscreenExitIcon, null) : /* @__PURE__ */ React.createElement(FullscreenIcon, null),
      label: isFullscreen ? "Exit full screen" : "Make full screen"
    },
    {
      key: "copy",
      action: copyURL,
      icon: /* @__PURE__ */ React.createElement(FileCopyIcon, {
        fontSize: "small"
      }),
      label: "Copy page URL"
    }
  ].map(({key, action, label, icon}, i) => /* @__PURE__ */ React.createElement(IconButton, {
    key,
    mr: i === 1 ? 0 : 1,
    onClick: action,
    colorScheme: "gray",
    variant: "ghost",
    icon,
    "aria-label": label,
    size: "sm"
  }))));
};
export default PreviewMenu;
