import React, {useEffect, useRef, useState} from "../../../../../../_snowpack/pkg/react.js";
import styled from "../../../../../../_snowpack/pkg/@emotion/styled.js";
import {Loading} from "../../../../../../dist/lib/index.js";
import {format} from "../../../../../../_snowpack/pkg/date-fns.js";
import {LinkDownload} from "./LinkDownload.js";
const Container = styled.div`
  label: Container;
  display: flex;
  flex-direction: column;
  margin: 10px 0 20px 0;
`;
const Row = styled.div`
  label: Row;
  display: flex;
`;
const Title = styled.h1`
  label: Title;
  flex: 1;
`;
const UpdatingContainer = styled.div`
  label: UpdatingContainer;
  width: 160px;
  opacity: ${({visible}) => visible ? 0.8 : 0.2};
  margin-right: 20px;
  display: flex;
  align-items: center;
  height: 24px;
  transition-duration: ${({visible}) => visible ? 0.1 : 0.8}s;

  span {
    margin-right: 10px;
  }
`;
const Version = styled.p`
  label: Version;
  font-size: 12px;
  color: rgba(0,0,0,0.6);
  text-align: right;
  padding-left: 10px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Updating = ({updating}) => {
  const [updated, setUpdated] = useState();
  const [prevUpdating, setPrevUpdating] = useState();
  const [visible, setVisible] = useState(false);
  const timer = useRef();
  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    if (prevUpdating && updating === false) {
      setUpdated(new Date());
      setVisible(true);
      timer.current = window.setTimeout(() => {
        console.log("set visible to false");
        setVisible(false);
      }, 2e3);
    }
    setPrevUpdating(updating);
  }, [updating]);
  if (updating) {
    return /* @__PURE__ */ React.createElement(UpdatingContainer, {
      visible: true
    }, /* @__PURE__ */ React.createElement("span", null, "Updating"), /* @__PURE__ */ React.createElement(Loading, {
      fadeIn: 0,
      size: 12,
      label: "Updating mapping review"
    }));
  }
  if (updated) {
    const formattedUpdated = format(updated, "hh:mm:ss");
    return /* @__PURE__ */ React.createElement(UpdatingContainer, {
      visible
    }, "Updated at ", formattedUpdated);
  }
  return /* @__PURE__ */ React.createElement(UpdatingContainer, null);
};
export const MappingReviewHeader = ({
  version,
  downloadOriginal,
  client,
  industry,
  businessType,
  updating
}) => {
  return /* @__PURE__ */ React.createElement(Container, null, /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Title, null, client, " | ", industry, " | ", businessType), /* @__PURE__ */ React.createElement(Updating, {
    updating
  }), /* @__PURE__ */ React.createElement(LinkDownload, {
    href: "#",
    onClick: downloadOriginal
  }, "Download original I&T Spreadsheet"), /* @__PURE__ */ React.createElement(Version, null, "Version: ", version)));
};
