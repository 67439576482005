export const COLUMN_DEFINITIONS = {
  id: {
    key: "id",
    label: "ID",
    width: 80
  },
  streetAddress: {
    key: "streetAddress",
    label: "Street",
    width: 140
  },
  city: {
    key: "city",
    label: "City",
    width: 140
  },
  state: {
    key: "state",
    label: "State",
    width: 140
  },
  county: {
    key: "county",
    label: "County",
    width: 140
  },
  country: {
    key: "country",
    label: "Country",
    width: 140
  },
  latitude: {
    key: "latitude",
    label: "Latitude",
    width: 140
  },
  longitude: {
    key: "longitude",
    label: "Longitude",
    width: 140
  },
  seismicHazardClass: {
    key: "seismicHazardClass",
    label: "Seismic Hazard"
  },
  quakeZone: {
    key: "quakeZone",
    label: "Seismic Zone"
  },
  liquefactionSusceptibility: {
    key: "liquefactionSusceptibility",
    label: "Liquefaction Susceptibility"
  },
  wildFireHazard: {
    key: "wildFireHazard",
    label: "Wild Fire"
  },
  tornadoHazard: {
    key: "tornadoHazard",
    label: "Tornado Hazard"
  },
  hailHazardClass: {
    key: "hailHazardClass",
    label: "Hail Hazard"
  },
  lightningHazard: {
    key: "lightningHazard",
    label: "Lightning Hazard"
  },
  tsunamiHazardClass: {
    key: "tsunamiHazardClass",
    label: "Tsunami Hazard"
  },
  landslideSusceptibility: {
    key: "landslideSusceptibility",
    label: "Landslide Susceptibility"
  },
  siteSoilClassification: {
    key: "siteSoilClassification",
    label: "Site Soil Classification"
  },
  distanceToFault: {
    key: "distanceToFault",
    label: "Distance to Nearest Fault"
  },
  nearestFaultName: {
    key: "nearestFaultName",
    label: "Nearest Fault Name"
  },
  windHazard: {
    key: "windHazard",
    label: "Wind Hazard"
  },
  windZone: {
    key: "windZone",
    label: "Wind Zone"
  },
  distanceToCoast: {
    key: "distanceToCoast",
    label: "Distance to Coast"
  },
  floodZone: {
    key: "floodZone",
    label: "Flood Zone"
  },
  baseFloodElevation: {
    key: "baseFloodElevation",
    label: "Base Flood Elevation"
  },
  adjacentGradeElevation: {
    key: "adjacentGradeElevation",
    label: "Adjacent Grade Elevation"
  },
  rainfallIntensity: {
    key: "rainfallIntensity",
    label: "Rainfall Intensity"
  }
};
export const rowKeyForColumnKey = {
  seismicHazardClass: "enrich-seismic-hazard",
  quakeZone: "enrich-quake-zone",
  liquefactionSusceptibility: "enrich-liquefaction-susceptibility",
  landslideSusceptibility: "geo/landslide-susceptibility",
  wildFireHazard: "enrich-wild-fire-hazard",
  tornadoHazard: "enrich-tornado-hazard",
  hailHazardClass: "enrich-hail-hazard",
  lightningHazard: "enrich-lightning-hazard",
  tsunamiHazardClass: "enrich-tsunami-hazard-class",
  siteSoilClassification: "enrich-site-soil-classification",
  distanceToFault: "enrich-distance-to-fault",
  nearestFaultName: "enrich-distance-to-fault",
  windHazard: "enrich-wind-hazard",
  windZone: "enrich-wind-zone",
  distanceToCoast: "enrich-distance-to-coast",
  floodZone: "enrich-flood-zone",
  baseFloodElevation: "enrich-flood-zone",
  rainfallIntensity: "enrich-rainfall-intensity",
  adjacentGradeElevation: "enrich-adjacent-grade-elevation",
  id: ["archipelagoId", "locationId"],
  streetAddress: "streetAddress",
  city: "city",
  state: "state",
  county: "county",
  country: "country",
  zip: "zip",
  latitude: "latitude",
  longitude: "longitude"
};
export const frontendKeyToPebble = Object.entries(rowKeyForColumnKey).reduce((_obj, [key, val]) => {
  return [].concat(val).reduce((obj, v) => ({
    ...obj,
    [v]: key
  }), _obj);
}, {});
export const ARRAY_OF_COLUMNS = [
  COLUMN_DEFINITIONS.id,
  COLUMN_DEFINITIONS.streetAddress,
  COLUMN_DEFINITIONS.city,
  COLUMN_DEFINITIONS.state,
  COLUMN_DEFINITIONS.county,
  COLUMN_DEFINITIONS.country,
  COLUMN_DEFINITIONS.latitude,
  COLUMN_DEFINITIONS.longitude,
  COLUMN_DEFINITIONS.seismicHazardClass,
  COLUMN_DEFINITIONS.quakeZone,
  COLUMN_DEFINITIONS.liquefactionSusceptibility,
  COLUMN_DEFINITIONS.wildFireHazard,
  COLUMN_DEFINITIONS.tornadoHazard,
  COLUMN_DEFINITIONS.hailHazardClass,
  COLUMN_DEFINITIONS.lightningHazard,
  COLUMN_DEFINITIONS.tsunamiHazardClass,
  COLUMN_DEFINITIONS.landslideSusceptibility,
  COLUMN_DEFINITIONS.siteSoilClassification,
  COLUMN_DEFINITIONS.distanceToFault,
  COLUMN_DEFINITIONS.nearestFaultName,
  COLUMN_DEFINITIONS.windHazard,
  COLUMN_DEFINITIONS.windZone,
  COLUMN_DEFINITIONS.distanceToCoast,
  COLUMN_DEFINITIONS.floodZone,
  COLUMN_DEFINITIONS.baseFloodElevation,
  COLUMN_DEFINITIONS.adjacentGradeElevation,
  COLUMN_DEFINITIONS.rainfallIntensity
];
export const gizmoNameSpace = "hazards";
