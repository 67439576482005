import React from "../../../_snowpack/pkg/react.js";
import Color from "../../../_snowpack/pkg/color.js";
import styled from "../../../_snowpack/pkg/@emotion/styled.js";
import {css, keyframes} from "../../../_snowpack/pkg/@emotion/core.js";
import {
  BLUE
} from "../constants.js";
const DARKEN_AMOUNT = 0.2;
const SHADOW_RADIUS = 4;
const Container = styled.div`
  label: StatusDot;
  border: 1px solid rgba(0,0,0,0.15);
  box-shadow: 0 0 4px transparent;
  ${({size}) => `
    height: ${size}px;
    width: ${size}px;
    border-radius: ${size}px;
  `}
  margin-right: 10px;
  
  ${({pulse, color}) => pulse ? css`
    animation: ${keyframes`
        0% {
          background-color: ${color.darken(DARKEN_AMOUNT).toString()};
          box-shadow: 0 0 ${SHADOW_RADIUS}px ${color.darken(DARKEN_AMOUNT).alpha(0).toString()};
        }
        50% {
          background-color: ${color.lighten(DARKEN_AMOUNT).toString()};
          box-shadow: 0 0 ${SHADOW_RADIUS}px ${color.darken(DARKEN_AMOUNT).alpha(0.75).toString()};
        }
        100% {
          background-color: ${color.darken(DARKEN_AMOUNT).toString()};
          box-shadow: 0 0 ${SHADOW_RADIUS}px ${color.darken(DARKEN_AMOUNT).alpha(0).toString()};
        }
      `} 2s linear infinite;
  ` : `
    background-color: ${color.toString()}
  `}

`;
export const StatusDot = ({
  size = 12,
  pulse,
  color = BLUE.lighten(0.25).toString()
}) => /* @__PURE__ */ React.createElement(Container, {
  size,
  pulse,
  color: new Color(color)
});
